import React, { forwardRef, useEffect } from "react";
import {
  extend,
  Overwrite,
  ReactThreeFiber,
  useFrame,
  useThree,
} from "@react-three/fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Coords3D } from "../Domain";
import { Annotations } from "../Domain/Annotations";

extend({ OrbitControls });

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      orbitControls: Overwrite<
        ReactThreeFiber.Object3DNode<OrbitControls, typeof OrbitControls>,
        // Overwrite target prop with more permissive Vector3 type from @react-three/fiber
        { target: ReactThreeFiber.Vector3 }
      >;
    }
  }
}

export type ControlProps = {
  autoRotate: boolean;
  enablePan: boolean;
  enableZoom: boolean;
  position?: Coords3D;
  target?: Coords3D;
  packagingSize?: number;
  selectedAnnotation: Annotations | undefined;
  angleMode: boolean;
};

export const Controls = forwardRef<OrbitControls, ControlProps>(
  (
    {
      autoRotate,
      enableZoom,
      position,
      target,
      packagingSize,
      selectedAnnotation,
      angleMode,
    }: ControlProps,
    ref
  ) => {
    const controlsRef = ref as React.MutableRefObject<OrbitControls>;
    const { camera, gl } = useThree();

    useFrame(() => {
      controlsRef.current && controlsRef?.current.update();
    });

    // Deactivate autorotate when user manipulates camera
    useEffect(() => {
      controlsRef.current.addEventListener("start", () => {
        controlsRef.current.autoRotate = false;
      });
    });

    useEffect(() => {
      if (selectedAnnotation) {
        controlsRef.current.autoRotate = false;
      }
    }, [selectedAnnotation]);

    // Update camera position
    useEffect(() => {
      if (position) {
        camera.position.fromArray(position);
      }
      if (packagingSize) {
        controlsRef.current.maxDistance = packagingSize * 2;
        controlsRef.current.minDistance = packagingSize / 5;
        camera.near = packagingSize / 100;
        camera.far = packagingSize * 1000;
      }
      camera.updateProjectionMatrix();
      controlsRef.current.update();
    }, [camera, position, packagingSize]);

    return (
      <orbitControls
        ref={ref}
        args={[camera, gl.domElement]}
        autoRotate={!angleMode && autoRotate}
        enableRotate={!angleMode}
        autoRotateSpeed={2}
        enablePan={angleMode || true}
        enableZoom={enableZoom}
        enableDamping
        target={target ?? [0, 0, 0]}
        maxAzimuthAngle={angleMode ? 0 : Infinity}
        minAzimuthAngle={angleMode ? 0 : -Infinity}
        maxPolarAngle={angleMode ? Math.PI / 2 : Math.PI}
        minPolarAngle={angleMode ? Math.PI / 2 : 0}
        screenSpacePanning={true}
      />
    );
  }
);

Controls.defaultProps = {
  autoRotate: true,
  enablePan: false,
  enableZoom: false,
};

//TODO : Calculate center of face to get correct dynamical coordinate
