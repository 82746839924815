import React, { Dispatch, useEffect, useMemo, useRef } from "react";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import {
  Box3,
  Color,
  DoubleSide,
  EquirectangularReflectionMapping,
  Group,
  Matrix4,
  Mesh,
  ShaderMaterial,
  Texture,
  Vector3,
  Vector4,
  WebGLRenderTarget,
} from "three";
import { DDSLoader } from "three/examples/jsm/loaders/DDSLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { Coords3D, LayerView } from "../Domain";
import { BottleObject, BOTTLE_NAMES } from "../Domain/Bottle";
import { FoilMapping } from "../Domain/Foil";
import { BottleFoilLayer } from "./BottleFoilLayer";
import { BottleLayer } from "./BottleLayer";
import { computeFaceUVs, PackagingTextureCache } from "./PackagingTextureCache";
import { CameraProps, CreationState, PaperMaterial } from "./PreviewScene";
import { bottleFragmentShader } from "./Shaders/bottleFragmentShader";
import { bottleMaterialVertexShader } from "./Shaders/bottleMaterialVertexShader";
import { bottleVertexShader } from "./Shaders/bottleVertexShader";
import { colorFragmentShader } from "./Shaders/colorFragmentShader";
import { paperFragmentShader } from "./Shaders/paperFragmentShader";
import { varnishFragmentShader } from "./Shaders/varnishFragmentShader";

export function Bottle({
  textureCache,
  layersView,
  creationState,
  activePaper,
  updateCamera,
  foilMapping,
  selectedBottle,
  envMap,
  realDimension,
  labelPosition,
  normalMaps,
}: {
  textureCache: PackagingTextureCache | undefined;
  layersView: LayerView[];
  creationState: CreationState;
  activePaper: PaperMaterial;
  updateCamera: Dispatch<CameraProps>;
  foilMapping: FoilMapping;
  selectedBottle: BottleObject;
  envMap?: Texture;
  realDimension?: {
    width: number;
    height: number;
  };
  labelPosition?: number;
  normalMaps?: { recto?: WebGLRenderTarget; verso?: WebGLRenderTarget };
}): JSX.Element {
  const parallaxScale = 0.0025;

  const parallaxMinLayers = 32.0;
  const parallaxMaxLayers = 64.0;

  const mask =
    textureCache?.globalTextures.mask ?? textureCache?.globalTextures.white;

  const faceUVs = useMemo(() => {
    return computeFaceUVs(creationState.packaging, "front");
  }, [creationState.packaging]);

  const repeat = faceUVs.repeat.clone();
  repeat.multiplyScalar(8);

  const groupRef = useRef<Group>();

  useEffect(() => {
    if (groupRef.current) {
      // Compute bounding box using Box3
      // Inspired by setContent() method in three-gltf-viewer:
      //   https://github.com/donmccurdy/three-gltf-viewer/blob/master/src/viewer.js
      const box = new Box3().setFromObject(groupRef.current);

      // Compute camera target and position
      const center = box.getCenter(new Vector3());
      const size = groupRef.current
        .localToWorld(box.getSize(new Vector3()))
        .length();
      const position: Coords3D = [
        center.x + size / 2.0,
        center.y + size * 0.2,
        center.z + size * 0.6,
      ];

      updateCamera({
        position: position,
        packagingSize: size,
        target: center.toArray() as Coords3D,
      });
    }
  }, [creationState.packaging, updateCamera]);

  const bottleOuterObjects = useLoader<Group, string[]>(
    OBJLoader,
    BOTTLE_NAMES.map(
      (bottleName) =>
        `${process.env.PUBLIC_URL}/Bottles/${bottleName}_Outer.obj`
    )
  );

  const bottleOuter = useMemo(() => {
    const currentBottleName = selectedBottle?.name ?? "Bordeaux";
    return bottleOuterObjects[BOTTLE_NAMES.indexOf(currentBottleName)]
      .children[0] as Mesh;
  }, [selectedBottle.name, bottleOuterObjects]);

  const circonference = useMemo(() => {
    const geometry = bottleOuter.geometry;
    geometry.computeBoundingBox();
    const boundingBox = geometry.boundingBox as Box3;
    const bottleWidth =
      ((boundingBox.max.x - boundingBox.min.x) * selectedBottle.size) / 2;
    return bottleWidth * Math.PI;
  }, [selectedBottle.name]);

  const bottleRadius = useMemo(() => {
    const geometry = bottleOuter.geometry;
    geometry.computeBoundingBox();
    const boundingBox = geometry.boundingBox as Box3;
    const bottleWidth =
      ((boundingBox.max.x - boundingBox.min.x) * selectedBottle.size) / 4;
    return bottleWidth;
  }, [selectedBottle.name]);

  // Use default dimensions for labels that don't have any
  const dimensionsInMillimeters = realDimension ?? { width: 100, height: 100 };

  const width = dimensionsInMillimeters.width / 10.0 / circonference;
  const height = dimensionsInMillimeters.height / 10.0 / selectedBottle.size;

  const yStart =
    (labelPosition ?? selectedBottle.defaultLabelPosition) /
    selectedBottle.size;

  const uniforms = useMemo(() => {
    return {
      diffuse: { value: new Color(1, 1, 1) },
      opacity: { value: 1 },
      map: { value: textureCache?.recto.color },
      uvTransform: {
        value: [
          faceUVs.repeat.x,
          -0,
          0,
          0,
          faceUVs.repeat.y,
          0,
          faceUVs.offset.x,
          faceUVs.offset.y,
          1,
        ],
      },
      uv2Transform: { value: [1, 0, 0, 0, 1, 0, 0, 0, 1] },
      alphaMap: { value: mask },
      envMap: { value: null },
      flipEnvMap: { value: -1 },
      reflectivity: { value: 1 },
      refractionRatio: { value: 0.98 },
      ambientLightColor: {
        value: [1, 1, 1],
        needsUpdate: true,
      },
      envMapIntensity: { value: 1 },
      transparency: { value: 1 },
      transparent: { value: true },
      labelHeight: { value: height },
      labelWidth: { value: width },
      flip: { value: new Vector4(0, 1, 0, 1) },
      labelYPosition: { value: yStart },
    };
  }, [textureCache, selectedBottle, labelPosition]);

  const paperUniformsRecto = useMemo(() => {
    return {
      diffuse: {
        value: activePaper.diffuse
          ? new Color(activePaper.diffuse)
          : new Color(1, 1, 1),
      },
      opacity: { value: 1 },
      map: { value: textureCache?.globalTextures.paper },
      repeatPaper: {
        value: 8.0,
      },
      uvTransformFace: {
        value: [
          faceUVs.repeat.x,
          -0,
          0,
          0,
          faceUVs.repeat.y,
          0,
          faceUVs.offset.x,
          faceUVs.offset.y,
          1,
        ],
      },
      alphaMap: { value: mask },
      envMap: { value: envMap },
      flipEnvMap: { value: -1 },
      reflectivity: { value: 1 },
      refractionRatio: { value: 0.98 },
      normalMap: { value: textureCache?.globalTextures.normalNoise },
      normalScale: { value: { x: 1, y: 1 } },
      ambientLightColor: {
        value: [0.9, 0.9, 0.9],
        needsUpdate: true,
      },
      emissive: {
        value: new Color(0, 0, 0),
      },
      roughness: { value: activePaper.roughness },
      metalness: { value: activePaper.metalness ?? 0 },
      envMapIntensity: { value: activePaper.envMapIntensity },
      transparency: { value: 1 },
      sheen: { value: new Color(0, 0, 0) },
      sampNoise: { value: textureCache?.globalTextures.sampNoise },
      normalScaleFactor: { value: activePaper.normalScaleFactor },
      vecXNormalScale: { value: activePaper.vecNormalScale },
      vecYNormalScale: { value: activePaper.vecNormalScale },
      parallaxScale: { value: 0 },
      parallaxMinLayers: { value: 0 },
      parallaxMaxLayers: { value: 0 },
      flip: { value: new Vector4(0, 1, 0, 1) },
      labelYPosition: { value: yStart },
      labelHeight: { value: height },
      labelWidth: { value: width },
    };
  }, [textureCache, activePaper, selectedBottle, labelPosition]);

  if (envMap) envMap.mapping = EquirectangularReflectionMapping;

  const basicfoilUniform = useMemo(() => {
    return {
      opacity: { value: 1 },
      map: { value: textureCache?.recto.foils?.goldFoil },
      uvTransform: {
        value: [
          faceUVs?.repeat.x,
          -0,
          0,
          0,
          faceUVs?.repeat.y,
          0,
          faceUVs?.offset.x,
          faceUVs?.offset.y,
          1,
        ],
      },
      uv2Transform: { value: [1, 0, 0, 0, 1, 0, 0, 0, 1] },
      alphaMap: { value: mask },
      envMap: { value: envMap },
      flipEnvMap: { value: -1 },
      refractionRatio: { value: 0.98 },
      normalMap: { value: normalMaps?.recto?.texture },
      normalScale: { value: { x: 5, y: 5 } },
      ambientLightColor: {
        value: [0.7278431372549019, 0.7278431372549019, 0.7278431372549019],
        needsUpdate: true,
      },
      transparency: { value: 1 },
      parallaxScale: { value: parallaxScale },
      parallaxMinLayers: { value: parallaxMinLayers },
      parallaxMaxLayers: { value: parallaxMaxLayers },
      normalNoise: { value: textureCache?.globalTextures.foilNormalNoise },
      flip: { value: new Vector4(0, 1, 0, 1) },
      labelHeight: { value: height },
      labelWidth: { value: width },
      labelYPosition: { value: yStart },
    };
  }, [textureCache, foilMapping, selectedBottle, labelPosition, normalMaps]);

  const varnishUniformsRecto = useMemo(() => {
    return {
      diffuse: {
        value: new Color(1, 1, 1),
      },
      opacity: { value: 1 },
      map: { value: textureCache?.recto.varnish },
      uvTransform: {
        value: [
          faceUVs.repeat.x,
          -0,
          0,
          0,
          faceUVs.repeat.y,
          0,
          faceUVs.offset.x,
          faceUVs.offset.y,
          1,
        ],
      },
      uv2Transform: { value: [1, 0, 0, 0, 1, 0, 0, 0, 1] },
      alphaMap: { value: mask },
      envMap: { value: envMap },
      flipEnvMap: { value: -1 },
      reflectivity: { value: 1 },
      refractionRatio: { value: 0.98 },
      normalMap: { value: normalMaps?.recto?.texture },
      normalScale: { value: { x: 5, y: 5 } },
      ambientLightColor: {
        value: [0.7278431372549019, 0.7278431372549019, 0.7278431372549019],
        needsUpdate: true,
      },
      emissive: {
        value: new Color(1, 1, 1),
      },
      roughness: { value: 0.1 },
      metalness: { value: 0.01 },
      envMapIntensity: { value: 3 },
      transparency: { value: 1 },
      parallaxScale: { value: parallaxScale },
      parallaxMinLayers: { value: parallaxMinLayers },
      parallaxMaxLayers: { value: parallaxMaxLayers },
      normalPaper: { value: textureCache?.globalTextures.normalNoise },
      normalScaleFactor: { value: 0.02 },
      vecXNormalScale: { value: 25.0 },
      vecYNormalScale: { value: 50.0 },
      flip: { value: new Vector4(0, 1, 0, 1) },
      labelHeight: { value: height },
      labelWidth: { value: width },
      labelYPosition: { value: yStart },
    };
  }, [textureCache, selectedBottle, labelPosition, normalMaps]);

  const bottleRef = useRef<Mesh>();

  const { gl, camera } = useThree();
  gl.getContext().getExtension("OES_texture_float");

  const ddsTexture = useLoader(
    DDSLoader,
    selectedBottle.ddsTexture ??
      `${process.env.PUBLIC_URL}/BottleDistanceNormal_Bordeaux.dds`
  );

  const bottleMatrixWorld = useRef<Matrix4>(new Matrix4());

  // Set initial values on bottleMatrixWorls and vCameraPosRelInv
  useEffect(() => {
    if (!bottleRef.current?.matrixWorld) {
      return;
    }
    bottleMatrixWorld.current = bottleRef.current?.matrixWorld;
    if (bottleMatrixWorld.current) {
      vCameraPosRelInv.current = new Vector4(
        camera.position.x,
        camera.position.y,
        camera.position.z,
        1
      ).applyMatrix4(bottleMatrixWorld.current.invert());
    }
  }, []);

  const fDistanceMax = 0.25 * bottleRadius;

  const vCameraPosRelInv = useRef<Vector4>(new Vector4());

  const matWorldViewProjTemp = useRef<Matrix4>(new Matrix4());

  useFrame(() => {
    if (!bottleMatrixWorld.current || !vCameraPosRelInv.current) {
      return;
    }

    matWorldViewProjTemp.current.multiplyMatrices(
      camera.projectionMatrix,
      camera.matrixWorldInverse
    );
    matWorldViewProjTemp.current.multiplyMatrices(
      matWorldViewProjTemp.current,
      bottleMatrixWorld.current
    );

    vCameraPosRelInv.current.x = camera.position.x;
    vCameraPosRelInv.current.y = camera.position.y;
    vCameraPosRelInv.current.z = camera.position.z;
    vCameraPosRelInv.current.applyMatrix4(bottleMatrixWorld.current.invert());
  });

  const bottleUniforms = useMemo(() => {
    return {
      samplerEnvMap: {
        value: textureCache?.globalTextures.envMap ?? null,
      },
      samplerDistance: { value: ddsTexture },
      fBottleHeight: { value: selectedBottle.size },
      fBottleRadius: { value: bottleRadius },
      fBottleThickness: { value: 0.3 },
      fBottleHeightScale: { value: 1.0 / selectedBottle.size },
      fBottleRadiusScale: { value: 1.0 / bottleRadius },
      fDistanceMax: { value: fDistanceMax },
      fDistanceMaxInv: { value: 1.0 / fDistanceMax },
      matWorld: { value: bottleMatrixWorld.current },
      matWorldViewProj: {
        value: matWorldViewProjTemp.current,
      },
      matWorldInv: {
        value: bottleMatrixWorld.current.invert(),
      },
      normalMatrix2: {
        value: bottleMatrixWorld.current,
      },
      vCameraPosRel: {
        value: vCameraPosRelInv.current,
        needsUpdate: true,
      },
      labelHeight: { value: height },
      labelWidth: { value: width },
      labelPosition: { value: yStart },
      vLabelMap: { value: textureCache?.globalTextures.paper },
      mask: { value: mask },
      uv2Transform: {
        value: [
          faceUVs.repeat.x,
          -0,
          0,
          0,
          faceUVs.repeat.y,
          0,
          faceUVs.offset.x,
          faceUVs.offset.y,
          1,
        ],
      },
      flip: { value: new Vector4(1, -1, 1, -1) },
    };
  }, [
    selectedBottle.name,
    bottleMatrixWorld.current,
    matWorldViewProjTemp.current,
    vCameraPosRelInv.current,
    yStart,
    textureCache,
    activePaper.name,
    labelPosition,
    mask,
  ]);

  const bottleMaterial = useMemo(() => {
    return new ShaderMaterial({
      uniforms: bottleUniforms,
      vertexShader: bottleMaterialVertexShader,
      fragmentShader: bottleFragmentShader,
      side: DoubleSide,
    });
  }, [bottleUniforms]);

  return (
    <>
      <group ref={groupRef as React.Ref<Group> | undefined}>
        <BottleLayer
          bottleOuter={bottleOuter}
          layersView={layersView}
          material={bottleMaterial}
          objectRef={bottleRef}
          layerName="bottle"
        />

        {textureCache?.globalTextures.paper && (
          <BottleLayer
            bottleOuter={bottleOuter}
            layersView={layersView}
            material={
              new ShaderMaterial({
                uniforms: paperUniformsRecto,
                vertexShader: "#define PAPER_BOTTLE" + bottleVertexShader,
                fragmentShader:
                  "#define BOTTLE_SHADER \n" + paperFragmentShader,
                transparent: true,
              })
            }
            layerName={"paper"}
          />
        )}
        {textureCache?.recto.color && (
          <BottleLayer
            bottleOuter={bottleOuter}
            layersView={layersView}
            material={
              new ShaderMaterial({
                alphaTest: 0.01,
                transparent: true,
                uniforms: uniforms,
                vertexShader: bottleVertexShader,
                fragmentShader:
                  "#define BOTTLE_SHADER \n" + colorFragmentShader,
              })
            }
            layerName={"color"}
          />
        )}
        {textureCache?.recto.foils &&
          Object.keys(textureCache?.recto.foils).map((foilName) => {
            if (textureCache?.recto.foils?.[foilName]) {
              return (
                <BottleFoilLayer
                  bottleOuter={bottleOuter}
                  layersView={layersView}
                  layerName={foilName}
                  basicfoilUniform={basicfoilUniform}
                  foilMaterial={foilMapping[foilName]}
                  map={textureCache?.recto.foils?.[foilName]}
                />
              );
            } else return undefined;
          })}
        {textureCache?.recto.varnish && (
          <BottleLayer
            bottleOuter={bottleOuter}
            layersView={layersView}
            material={
              new ShaderMaterial({
                uniforms: varnishUniformsRecto,
                vertexShader: bottleVertexShader,
                fragmentShader:
                  "#define BOTTLE_SHADER \n" + varnishFragmentShader,
                transparent: true,
              })
            }
            layerName={"varnish"}
          />
        )}
      </group>
    </>
  );
}
