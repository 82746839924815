import React, { useEffect, useMemo, useRef } from "react";
import { SpringValue } from "@react-spring/three";
import {
  BufferGeometry,
  Color,
  DoubleSide,
  ShaderMaterial,
  Texture,
  Vector2,
  Vector4,
} from "three";
import { FaceGeometry, layerIsEnabled, LayerView } from "../../Domain";
import { PaperMaterial } from "../PreviewScene";
import { paperFragmentShader } from "../Shaders/paperFragmentShader";
import { paperVertexShader } from "../Shaders/paperVertexShader";

type PaperProps = {
  geometry: BufferGeometry;
  faceGeometry: FaceGeometry;
  paper: Texture | undefined;
  sampNoise?: Texture;
  animatedOpacity: SpringValue<number>;
  faceName: string;
  layersView: LayerView[];
  faceUVs: { repeat: Vector2; offset: Vector2 };
  envMap?: Texture;
  activePaper: PaperMaterial;
  normalNoise?: Texture;
  metallicNormalNoise?: Texture;
  mask?: Texture;
  normalMap: Texture | undefined;
  parallaxScale: number;
  metalness: number;
  roughness: number;
  envMapIntensity: number;
  metalPaperColor: string;
  angleMode?: boolean;
};

const Paper = ({
  geometry,
  faceGeometry,
  paper,
  sampNoise,
  animatedOpacity,
  faceName,
  layersView,
  faceUVs,
  envMap,
  activePaper,
  normalNoise,
  metallicNormalNoise,
  mask,
  normalMap,
  parallaxScale,
  angleMode,
}: //metalness,
//roughness,
//envMapIntensity,
//metalPaperColor,
PaperProps): JSX.Element => {
  const repeatFace = faceUVs.repeat.clone();

  const offset = faceUVs.offset.clone();

  const materialRef = useRef<ShaderMaterial>(null!);

  if (materialRef.current) {
    materialRef.current.uniformsNeedUpdate = true;
  }

  const paperUniformsRecto = useMemo(() => {
    return {
      diffuse: {
        value: activePaper.diffuse
          ? new Color(activePaper.diffuse)
          : new Color(1, 1, 1),
      },
      opacity: { value: animatedOpacity },
      map: { value: paper },
      repeatPaper: {
        value: activePaper.name.includes("Metallic") ? 10.0 : 8.0,
      },
      uvTransformFace: {
        value: [repeatFace.x, -0, 0, 0, repeatFace.y, 0, offset.x, offset.y, 1],
      },
      alphaMap: { value: mask },
      envMap: { value: envMap },
      flipEnvMap: { value: -1 },
      reflectivity: { value: 1 },
      refractionRatio: { value: 0.98 },
      maxMipLevel: { value: 0 },
      aoMap: { value: null },
      aoMapIntensity: { value: 1 },
      lightMap: { value: null },
      lightMapIntensity: { value: 1 },
      emissiveMap: { value: null },
      bumpMap: { value: null },
      bumpScale: { value: 1 },
      normalMap: { value: normalMap },
      normalNoise: {
        value: activePaper.name.includes("Metallic")
          ? metallicNormalNoise
          : normalNoise,
      },
      normalScale: { value: { x: 1, y: 1 } },
      roughnessMap: { value: null },
      metalnessMap: { value: null },
      ambientLightColor: {
        value: [0.9, 0.9, 0.9],
        needsUpdate: true,
      },
      emissive: {
        value: new Color(0, 0, 0),
      },
      roughness: { value: activePaper.roughness },
      metalness: { value: activePaper.metalness ?? 0 },
      envMapIntensity: { value: activePaper.envMapIntensity },
      transparency: { value: 1 },
      clearcoat: { value: 0 },
      clearcoatRoughness: { value: 1 },
      sheen: { value: new Color(0, 0, 0) },
      clearcoatNormalScale: { value: { x: 1, y: 1 } },
      clearcoatNormalMap: { value: null },
      clippingPlanes: { value: null, needsUpdate: false },
      sampNoise: { value: sampNoise },
      normalScaleFactor: { value: activePaper.normalScaleFactor },
      vecXNormalScale: { value: activePaper.vecNormalScale },
      vecYNormalScale: { value: activePaper.vecNormalScale },
      parallaxScale: { value: parallaxScale },
      parallaxMinLayers: { value: 0 },
      parallaxMaxLayers: { value: 0 },
      flip: { value: new Vector4(0, 1, 0, 1) },
      colorElevation: {
        value: layerIsEnabled("varnish", layersView) ? 0.85 : 0.0,
      },
      angleMode: { value: angleMode },
    };
  }, [
    activePaper.diffuse,
    activePaper.envMapIntensity,
    activePaper.metalness,
    activePaper.name,
    activePaper.normalScaleFactor,
    activePaper.roughness,
    activePaper.vecNormalScale,
    angleMode,
    animatedOpacity,
    envMap,
    layersView,
    mask,
    metallicNormalNoise,
    normalMap,
    normalNoise,
    offset.x,
    offset.y,
    paper,
    parallaxScale,
    repeatFace.x,
    repeatFace.y,
    sampNoise,
  ]);

  useEffect(() => {
    if (materialRef.current) {
      materialRef.current.uniforms["parallaxScale"].value = parallaxScale;
      materialRef.current.uniforms["colorElevation"].value = layerIsEnabled(
        "varnish",
        layersView
      )
        ? 0.85
        : 0.0;
      materialRef.current.uniforms["normalMap"].value = normalMap;
      materialRef.current.uniforms["angleMode"].value = angleMode;
      //materialRef.current.uniforms["metalness"].value = metalness;
      //materialRef.current.uniforms["roughness"].value = roughness;
      //materialRef.current.uniforms["envMapIntensity"].value = envMapIntensity;
      //materialRef.current.uniforms["diffuse"].value = new Color(
      //  metalPaperColor
      //);
    }
  }, [
    parallaxScale,
    normalMap,
    layersView["varnish"],
    angleMode,
    //metalness,
    //roughness,
    //metalPaperColor,
    //envMapIntensity,
  ]);

  return (
    <>
      <mesh
        name={faceName}
        geometry={geometry}
        position={[...faceGeometry.position, 0]}
        visible={layerIsEnabled("paper", layersView)}
        rotation={[0, 0, 0]}
        renderOrder={55}
      >
        <shaderMaterial
          attach="material"
          uniforms={paperUniformsRecto}
          vertexShader={paperVertexShader}
          fragmentShader={"#define SILHOUETTE_MAPPING" + paperFragmentShader}
          ref={materialRef as React.Ref<ShaderMaterial> | undefined}
          side={DoubleSide}
          //depthFunc={AlwaysDepth}
          //depthWrite={false}
          //tranparent
          //opacity={0.5}
          //alphaTest={1.0}
        />
      </mesh>
    </>
  );
};
export default Paper;
