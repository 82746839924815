import { Color } from "three";

export const FOIL_COLORS_NAMES = [
  "goldFoil",
  "silverFoil",
  "copperFoil",
  "redFoil",
  "greenFoil",
  "blueFoil",
  "pinkFoil",
  "orangeFoil",
  "blackFoil",
] as const;

export type FoilColorName = typeof FOIL_COLORS_NAMES[number];

export type FoilMaterial = {
  name: FoilColorName;
  diffuse: Color;
  emissive: Color;
  roughness: number;
  metalness: number;
  reflectivity: number;
  envMapIntensity: number;
  normalScaleFactor: number;
  vecXNormalScale: number;
  vecYNormalScale: number;
};

// Map foil layers to specific foil materials
// Example:
//   Show a gold foil material for the silver foil layer like this
//   <PreviewScene foilMapping={{'silverFoil': goldFoilMaterial }} ... /> */
//

const normalScaleFactor = 0.08;

export type FoilMapping = {
  [Name in FoilColorName]: FoilMaterial;
};

export const goldFoilMaterial: FoilMaterial = {
  name: "goldFoil",
  diffuse: new Color(1.1, 0.8, 0.5), //gold
  emissive: new Color(0, 0, 0),
  roughness: 0.25,
  metalness: 0.55,
  reflectivity: 1.0,
  envMapIntensity: 2.0,
  normalScaleFactor: normalScaleFactor,
  vecXNormalScale: 6.0,
  vecYNormalScale: 6.0,
};

export const silverFoilMaterial: FoilMaterial = {
  name: "silverFoil",
  diffuse: new Color(0.8, 0.8, 0.8), //silver
  emissive: new Color(0, 0, 0),
  roughness: 0.25,
  metalness: 0.55,
  reflectivity: 1.0,
  envMapIntensity: 2.0,
  normalScaleFactor: 0.15,
  vecXNormalScale: 6.0,
  vecYNormalScale: 6.0,
};

export const copperFoilMaterial: FoilMaterial = {
  name: "copperFoil",
  diffuse: new Color(0.68, 0.38, 0.33), //copper,
  emissive: new Color(0, 0, 0),
  roughness: 0.25,
  metalness: 0.55,
  reflectivity: 1.0,
  envMapIntensity: 2.0,
  normalScaleFactor: normalScaleFactor,
  vecXNormalScale: 6.0,
  vecYNormalScale: 6.0,
};

export const redFoilMaterial: FoilMaterial = {
  name: "redFoil",
  diffuse: new Color(1, 0.05, 0.05), //red
  emissive: new Color(0, 0, 0),
  roughness: 0.25,
  metalness: 0.55,
  reflectivity: 1.0,
  envMapIntensity: 2.0,
  normalScaleFactor: normalScaleFactor,
  vecXNormalScale: 6.0,
  vecYNormalScale: 6.0,
};

export const greenFoilMaterial: FoilMaterial = {
  name: "greenFoil",
  diffuse: new Color(0, 0.7, 0.2), //green
  emissive: new Color(0, 0, 0),
  roughness: 0.25,
  metalness: 0.55,
  reflectivity: 1.0,
  envMapIntensity: 2.0,
  normalScaleFactor: normalScaleFactor,
  vecXNormalScale: 6.0,
  vecYNormalScale: 6.0,
};

export const blueFoilMaterial: FoilMaterial = {
  name: "blueFoil",
  diffuse: new Color(0.1, 0.2, 1), //blue
  emissive: new Color(0, 0, 0),
  roughness: 0.25,
  metalness: 0.55,
  reflectivity: 1.0,
  envMapIntensity: 2.0,
  normalScaleFactor: normalScaleFactor,
  vecXNormalScale: 6.0,
  vecYNormalScale: 6.0,
};
export const pinkFoilMaterial: FoilMaterial = {
  name: "pinkFoil",
  diffuse: new Color(1, 0.2, 0.9), //pink
  emissive: new Color(0, 0, 0),
  roughness: 0.25,
  metalness: 0.55,
  reflectivity: 1.0,
  envMapIntensity: 2.0,
  normalScaleFactor: normalScaleFactor,
  vecXNormalScale: 6.0,
  vecYNormalScale: 6.0,
};
export const orangeFoilMaterial: FoilMaterial = {
  name: "orangeFoil",
  diffuse: new Color(1, 0.4, 0.05), //orange
  emissive: new Color(0, 0, 0),
  roughness: 0.25,
  metalness: 0.55,
  reflectivity: 1.0,
  envMapIntensity: 2.0,
  normalScaleFactor: normalScaleFactor,
  vecXNormalScale: 6.0,
  vecYNormalScale: 6.0,
};
export const blackFoilMaterial: FoilMaterial = {
  name: "blackFoil",
  diffuse: new Color(0.05, 0.05, 0.05), //black
  emissive: new Color(0, 0, 0),
  roughness: 0.25,
  metalness: 0.55,
  reflectivity: 1.0,
  envMapIntensity: 2.0,
  normalScaleFactor: normalScaleFactor,
  vecXNormalScale: 6.0,
  vecYNormalScale: 6.0,
};

export const DEFAULT_FOIL_MAPPING: FoilMapping = {
  goldFoil: goldFoilMaterial,
  silverFoil: silverFoilMaterial,
  copperFoil: copperFoilMaterial,
  redFoil: redFoilMaterial,
  greenFoil: greenFoilMaterial,
  blueFoil: blueFoilMaterial,
  pinkFoil: pinkFoilMaterial,
  orangeFoil: orangeFoilMaterial,
  blackFoil: blackFoilMaterial,
} as const;

export function mapFoilNameToFoilMaterial(name: FoilColorName): FoilMaterial {
  switch (name) {
    case "goldFoil":
      return goldFoilMaterial;
    case "silverFoil":
      return silverFoilMaterial;
    case "copperFoil":
      return copperFoilMaterial;
    case "redFoil":
      return redFoilMaterial;
    case "greenFoil":
      return greenFoilMaterial;
    case "blueFoil":
      return blueFoilMaterial;
    case "pinkFoil":
      return pinkFoilMaterial;
    case "orangeFoil":
      return orangeFoilMaterial;
    case "blackFoil":
      return blackFoilMaterial;
    default:
      return goldFoilMaterial;
  }
}
