export const BOTTLE_NAMES = [
  "Aucune",
  "Bordeaux",
  "Alsace",
  "Bourgogne",
  "Champagne",
] as const;
export type BottleName = typeof BOTTLE_NAMES[number];
export type BottleObject = {
  name: BottleName;
  size: number;
  defaultLabelPosition: number;
  bottleLabelMaxPosition: number;
  ddsTexture?: string;
};

export const bordeauxBottle: BottleObject = {
  name: "Bordeaux",
  size: 30.0,
  defaultLabelPosition: 7.0,
  bottleLabelMaxPosition: 19.0,
  ddsTexture: `${process.env.PUBLIC_URL}/Bottles/BottleDistanceNormal_Bordeaux.dds`,
};

export const bourgogneBottle: BottleObject = {
  name: "Bourgogne",
  size: 30.0,
  defaultLabelPosition: 2.0,
  bottleLabelMaxPosition: 15.0,
  ddsTexture: `${process.env.PUBLIC_URL}/Bottles/BottleDistanceNormal_Bourgogne.dds`,
};

export const alsaceBottle: BottleObject = {
  name: "Alsace",
  size: 32.0,
  defaultLabelPosition: 2.0,
  bottleLabelMaxPosition: 13.0,
  ddsTexture: `${process.env.PUBLIC_URL}/Bottles/BottleDistanceNormal_Alsace.dds`,
};

export const champagneBottle: BottleObject = {
  name: "Champagne",
  size: 30.0,
  defaultLabelPosition: 2.0,
  bottleLabelMaxPosition: 13.0,
  ddsTexture: `${process.env.PUBLIC_URL}/Bottles/BottleDistanceNormal_Champagne.dds`,
};

export function mapBottleNameToBottleObject(
  bottleName: string
): BottleObject | undefined {
  switch (bottleName) {
    case "Alsace":
      return alsaceBottle;
    case "Bordeaux":
      return bordeauxBottle;
    case "Bourgogne":
      return bourgogneBottle;
    case "Champagne":
      return champagneBottle;
    case "Aucune":
      return undefined;
    default:
      return undefined;
  }
}
