import React, { useEffect, useMemo, useRef } from "react";
import { SpringValue } from "@react-spring/three";
import {
  BufferGeometry,
  Color,
  Mesh,
  ShaderMaterial,
  Texture,
  Vector2,
  Vector4,
} from "three";
import { FaceGeometry, layerIsEnabled, LayerView } from "../../Domain";
import { Annotations, DEFAULT_ANNOTATION_SIZE } from "../../Domain/Annotations";
import { PaperMaterial } from "../PreviewScene";
import { colorFragmentShader } from "../Shaders/colorFragmentShader";
import { vertexShader } from "../Shaders/vertexShader";

type ColorLayerProps = {
  geometry: BufferGeometry;
  faceGeometry: FaceGeometry;
  map: Texture | undefined;
  normalMap: Texture | undefined;
  animatedOpacity: SpringValue<number>;
  faceName: string;
  onNewAnnotation: React.Dispatch<Annotations> | undefined;
  parallaxScale: number;
  envMap?: Texture;
  parallaxMinLayers: number;
  parallaxMaxLayers: number;
  activePaper: PaperMaterial;
  metallicNormalNoise?: Texture;
  layersView: LayerView[];
  faceUVs: { repeat: Vector2; offset: Vector2 };
  verso: boolean;
  mask?: Texture;
  normalScale: Vector2;
  varnishLevel: number;
  anglePreview3D?: boolean;
  angleMode?: boolean;
  isPackaging: boolean;
};

const ColorLayer = ({
  geometry,
  faceGeometry,
  map,
  normalMap,
  faceName,
  envMap,
  onNewAnnotation,
  parallaxScale,
  parallaxMinLayers,
  parallaxMaxLayers,
  activePaper,
  metallicNormalNoise,
  layersView,
  faceUVs,
  verso,
  mask,
  normalScale,
  varnishLevel,
  anglePreview3D,
  angleMode,
  isPackaging,
}: ColorLayerProps): JSX.Element => {
  const materialRef = useRef<ShaderMaterial>(null!);

  if (materialRef.current) {
    materialRef.current.uniformsNeedUpdate = true;
  }

  const colorLayerUniformsRecto = useMemo(() => {
    return {
      diffuse: {
        value: new Color(1, 1, 1),
      },
      opacity: { value: 1.0 },
      map: { value: map },
      uvTransform: {
        value: [
          faceUVs?.repeat.x,
          -0,
          0,
          0,
          faceUVs?.repeat.y,
          0,
          faceUVs?.offset.x,
          faceUVs?.offset.y,
          1,
        ],
      },
      uv2Transform: { value: [1, 0, 0, 0, 1, 0, 0, 0, 1] },
      alphaMap: { value: mask },
      envMap: { value: envMap },
      flipEnvMap: { value: -1 },
      reflectivity: { value: 1 },
      refractionRatio: { value: 0.98 },
      maxMipLevel: { value: 0 },
      aoMap: { value: null },
      aoMapIntensity: { value: 1 },
      lightMap: { value: null },
      lightMapIntensity: { value: 1 },
      emissiveMap: { value: null },
      bumpMap: { value: null },
      bumpScale: { value: 1 },
      normalMap: { value: normalMap },
      normalNoise: {
        value: activePaper.name.includes("Metallic")
          ? metallicNormalNoise
          : null,
      },
      normalScale: { value: normalScale },
      displacementMap: { value: null },
      displacementScale: { value: 1 },
      displacementBias: { value: 0 },
      roughnessMap: { value: null },
      metalnessMap: { value: null },
      ambientLightColor: {
        value: [1, 1, 1],
        needsUpdate: true,
      },
      directionalLights: {
        value: [
          {
            direction: {
              x: -0.6569329722189172,
              y: -0.17460088517151215,
              z: 0.7334532029440893,
            },
            color: new Color(0.5, 0.5, 0.5),
          },
          {
            direction: {
              x: 0.7478311872357977,
              y: 0.14336756698608208,
              z: -0.6482239243764369,
            },
            color: new Color(0.5, 0.5, 0.5),
          },
        ],
        needsUpdate: true,
      },
      emissive: {
        value: new Color(0, 0, 0),
      },
      roughness: { value: activePaper.name.includes("Metallic") ? 0.6 : 1.0 },
      metalness: { value: activePaper.name.includes("Metallic") ? 0.3 : 0.0 },
      envMapIntensity: {
        value: activePaper.name.includes("Metallic") ? 1.25 : 1.0,
      },
      transparency: { value: 1 },
      clearcoat: { value: 0 },
      clearcoatRoughness: { value: 0 },
      sheen: { value: new Color(0, 0, 0) },
      clearcoatNormalScale: { value: { x: 1, y: 1 } },
      clearcoatNormalMap: { value: null },
      clippingPlanes: { value: null, needsUpdate: false },
      parallaxScale: { value: parallaxScale },
      parallaxMinLayers: { value: parallaxMinLayers },
      parallaxMaxLayers: { value: parallaxMaxLayers },
      flip: { value: new Vector4(0, 1, 0, 1) },
      colorElevation: {
        value: layerIsEnabled("varnish", layersView) ? 0.85 : 0.0,
      },
      verso: { value: verso ? 1.0 : 0.0 },
      isPackaging: { value: isPackaging ? 1.0 : 0.0 },
      anglePreview3D: { value: anglePreview3D },
      angleMode: { value: angleMode },
    };
  }, [activePaper.name]);

  useEffect(() => {
    if (materialRef.current) {
      materialRef.current.uniforms["normalScale"].value = normalScale;
      materialRef.current.uniforms["parallaxScale"].value = parallaxScale;
      materialRef.current.uniforms["colorElevation"].value = layerIsEnabled(
        "varnish",
        layersView
      )
        ? 0.85
        : 0.0;
      materialRef.current.uniforms["normalMap"].value = normalMap;

      materialRef.current.uniforms["anglePreview3D"].value = anglePreview3D;

      materialRef.current.uniforms["angleMode"].value = angleMode;
    }
  }, [
    normalScale,
    parallaxScale,
    layersView["varnish"],
    varnishLevel,
    normalMap,
    anglePreview3D,
    angleMode,
  ]);

  const meshRef = useRef<Mesh>();
  return (
    <mesh
      name={faceName}
      geometry={geometry}
      position={[...faceGeometry.position, verso ? -0.1 : 0]}
      rotation={verso ? [0, Math.PI, 0] : [0, 0, 0]}
      ref={meshRef as React.Ref<Mesh> | undefined}
      visible={layerIsEnabled("color", layersView)}
      onClick={(event) => {
        const point = event.point.clone();
        meshRef.current?.parent?.worldToLocal(point);

        if (onNewAnnotation) {
          onNewAnnotation({
            x: point.x,
            y: point.y,
            z: verso ? point.z - 2 : point.z + 1,
            face: faceName,
            isVerso: verso,
            size: DEFAULT_ANNOTATION_SIZE,
          });
        }

        console.log("faceName", faceName);
        console.log("face coordinates: ", faceGeometry.position);
      }}
      renderOrder={verso ? 105 : 100}
    >
      <shaderMaterial
        attach="material"
        uniforms={colorLayerUniformsRecto}
        //depthFunc={AlwaysDepth}
        depthWrite={false}
        vertexShader={vertexShader}
        fragmentShader={
          activePaper.name.includes("BrushedMetallic")
            ? "#define METALLIC" + colorFragmentShader
            : colorFragmentShader
        }
        transparent
        ref={materialRef as React.Ref<ShaderMaterial> | undefined}
      />
    </mesh>
  );
};
export default ColorLayer;
