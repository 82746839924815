import React, { useMemo } from "react";
import { useSpring } from "@react-spring/three";
import {
  BufferGeometry,
  Float32BufferAttribute,
  PlaneBufferGeometry,
  Texture,
  Vector2,
  WebGLRenderTarget,
} from "three";
//import { BufferGeometryUtils } from "../3dFunctions/BufferGeometryUtils";
import { FaceGeometry, FoldingTree, Product, FaceGeometryMap } from "../Domain";
import { Annotations } from "../Domain/Annotations";
import { FoilMapping } from "../Domain/Foil";
import ColorLayer from "./Layers/ColorLayer";
import Foil from "./Layers/Foil";
import HoloFoil from "./Layers/HoloFoil";
import Paper from "./Layers/Paper";
import Varnish, { VarnishMaterialProps } from "./Layers/Varnish";
import LineSegments from "./LineSegments";
import { computeFaceUVs, PackagingTextureCache } from "./PackagingTextureCache";
import { CreationState, PaperMaterial } from "./PreviewScene";
import { BoxSilhouetteGeometry } from "./Shaders/boxSilhouetteGeometry";
import { FoldingFace } from "../Domain/FoldingTree/auto-fold";
import { initRenderTargetMask } from "./NormalMapping/maskRenderTarget";
import { maskShader } from "./Shaders/maskShader";
import { useThree } from "@react-three/fiber";
//import { BufferGeometryUtils } from "../3dFunctions/BufferGeometryUtils";

export type DesignFaceProps = {
  node: FoldingTree;
  creationState: CreationState;
  opacity: number;
  textureCache?: PackagingTextureCache;
  onNewAnnotation?: React.Dispatch<Annotations>;
  envMap?: Texture;
  varnishMaterialProps: VarnishMaterialProps;
  displayedMap: string;
  activePaperName: PaperMaterial;
  faceGeometry: FaceGeometry;
  faceGeometries: FaceGeometryMap;
  packaging: Product;
  verso: boolean;
  foilMapping: FoilMapping;
  foilDiffuseColor: string;
  normalScaleV: number;
  parallaxScale: number;
  normalMap?: WebGLRenderTarget;
  silhouetteMap?: WebGLRenderTarget;
  varnishLevel: number;
  silhouetteMappingMode: boolean;
  metalness: number;
  roughness: number;
  envMapIntensity: number;
  metalPaperColor: string;
  packagingSize: number;
  angleMode: boolean;
  anglePreview3D?: boolean;
  fold:
    | {
        Face: FoldingFace;
      }[]
    | undefined;
  selectedFold?: number[];
  hovered?: number;
};

const DesignFace = ({
  node,
  fold,
  creationState,
  opacity,
  textureCache,
  onNewAnnotation,
  envMap,
  varnishMaterialProps,
  displayedMap,
  activePaperName,
  faceGeometry,
  faceGeometries,
  packaging,
  verso,
  foilDiffuseColor,
  normalScaleV,
  parallaxScale,
  normalMap,
  silhouetteMap,
  varnishLevel,
  silhouetteMappingMode,
  metalness,
  roughness,
  metalPaperColor,
  envMapIntensity,
  packagingSize,
  foilMapping,
  angleMode,
  anglePreview3D,
  selectedFold,
  hovered,
}: DesignFaceProps): JSX.Element => {
  const pageMap = verso ? textureCache?.verso : textureCache?.recto;

  const paper = textureCache?.globalTextures.paper;

  const mask =
    textureCache?.globalTextures.mask ?? textureCache?.globalTextures.white;

  const { opacity: animatedOpacity } = useSpring({ opacity });

  const faceUVs = useMemo(() => {
    return computeFaceUVs(packaging, node.faceName);
  }, [packaging, node.faceName]);

  const geometry = useMemo(() => {
    const geom = new PlaneBufferGeometry(
      faceGeometry.width,
      faceGeometry.height,
      1,
      1
    );
    geom && geom.computeTangents();
    return geom;
  }, [faceGeometry]);

  const bufferGeometry = useMemo(() => {
    if (faceGeometry.vertices && faceGeometry.vertices?.length > 0) {
      const geom = new BufferGeometry();

      const verticies: number[] = [];
      faceGeometry.vertices?.forEach((v) => {
        verticies.push(v.Vertex.X, v.Vertex.Y, v.Vertex.Z);
      });

      const indicies: number[] = [];
      faceGeometry.indices?.forEach((v) => indicies.push(v.Index));

      geom.setIndex(indicies);
      geom.setAttribute("position", new Float32BufferAttribute(verticies, 3));
      geom.setAttribute("normal", geometry.getAttribute("normal"));

      geom && geom.computeTangents();
      return geom;
    } else return undefined;
  }, [faceGeometry.indices, faceGeometry.vertices, geometry]);

  const { gl } = useThree();

  const maskTexture = useMemo(
    () =>
      bufferGeometry
        ? initRenderTargetMask(
            gl,
            verso ? textureCache?.verso?.color : textureCache?.recto.color,
            {
              width: packaging.dieline.dimensions.width,
              height: packaging.dieline.dimensions.height,
            },
            maskShader,
            bufferGeometry
          )?.texture
        : mask,
    [
      bufferGeometry,
      gl,
      mask,
      packaging.dieline.dimensions.height,
      packaging.dieline.dimensions.width,
      textureCache?.recto.color,
      textureCache?.verso?.color,
      verso,
    ]
  );

  // const parallaxScale = 0.015;
  const normalScale = new Vector2(normalScaleV, normalScaleV);

  const parallaxMinLayers = 32.0;
  const parallaxMaxLayers = 64.0;

  const sampNoise = textureCache?.globalTextures.sampNoise;

  const depth = useMemo(
    () => packagingSize * 0.005,
    [packagingSize, parallaxScale]
  );

  const boxBufferGeometry = useMemo(() => {
    const boxGeometry = new BoxSilhouetteGeometry(
      geometry.parameters.width,
      //100,
      geometry.parameters.height,
      //100,
      depth
    );
    boxGeometry && boxGeometry.computeTangents();
    return boxGeometry;
  }, [depth, geometry.parameters.height, geometry.parameters.width]);

  const lineSegments = useMemo(
    () =>
      bufferGeometry && (
        <LineSegments
          node={node}
          geometry={bufferGeometry}
          faceGeometry={faceGeometry}
          anglePreview3D={anglePreview3D}
          fold={fold}
          faceGeometries={faceGeometries}
          selectedFold={selectedFold}
          size={packagingSize * 0.0375}
          hovered={hovered}
        />
      ),
    [
      anglePreview3D,
      faceGeometries,
      faceGeometry,
      fold,
      bufferGeometry,
      hovered,
      node,
      packagingSize,
      selectedFold,
    ]
  );

  const isPackaging = creationState.packaging.dieline.name === "custom";
  return (
    <>
      {/* Add invisible mesh to scene on first render (when there is no paper yet)
      The invisible mesh is used as the camera target on first render
      PS: We could use color too */}

      {!paper && (
        <mesh
          name={node.faceName}
          geometry={geometry}
          position={[...faceGeometry.position, 0]}
        >
          <meshBasicMaterial attach="material" opacity={0} transparent={true} />
        </mesh>
      )}
      {(angleMode || anglePreview3D) && pageMap?.color && lineSegments}

      {paper && (
        <Paper
          geometry={geometry}
          faceGeometry={faceGeometry}
          paper={paper}
          sampNoise={sampNoise}
          animatedOpacity={animatedOpacity}
          faceName={node.faceName}
          layersView={creationState.layersView}
          faceUVs={faceUVs}
          envMap={envMap}
          activePaper={activePaperName}
          normalNoise={textureCache?.globalTextures.normalNoise}
          metallicNormalNoise={textureCache?.globalTextures.metallicNormalNoise}
          mask={maskTexture}
          normalMap={normalMap?.texture}
          parallaxScale={parallaxScale}
          metalness={metalness}
          roughness={roughness}
          envMapIntensity={envMapIntensity}
          metalPaperColor={metalPaperColor}
          angleMode={angleMode || anglePreview3D}
        />
      )}
      {pageMap?.color && (
        <ColorLayer
          geometry={geometry}
          faceGeometry={faceGeometry}
          map={pageMap?.color}
          normalMap={normalMap?.texture}
          animatedOpacity={animatedOpacity}
          faceName={node.faceName}
          onNewAnnotation={onNewAnnotation}
          parallaxScale={parallaxScale}
          envMap={envMap}
          activePaper={activePaperName}
          metallicNormalNoise={textureCache?.globalTextures.metallicNormalNoise}
          parallaxMinLayers={parallaxMinLayers}
          parallaxMaxLayers={parallaxMaxLayers}
          layersView={creationState.layersView}
          faceUVs={faceUVs}
          verso={verso}
          mask={maskTexture}
          normalScale={normalScale}
          varnishLevel={varnishLevel}
          anglePreview3D={anglePreview3D}
          angleMode={angleMode}
          isPackaging={isPackaging}
        />
      )}
      {!angleMode && pageMap?.holoFoil && (
        <HoloFoil
          geometry={geometry}
          faceGeometry={faceGeometry}
          holoFoil={pageMap.holoFoil}
          animatedOpacity={animatedOpacity}
          faceName={node.faceName}
          faceUVs={faceUVs}
          verso={verso}
        />
      )}

      {!angleMode &&
        !anglePreview3D &&
        pageMap?.foils &&
        Object.keys(pageMap?.foils).map((foilName) => {
          if (pageMap.foils?.[foilName]) {
            return (
              <Foil
                layerName={foilName}
                foilNormalNoise={textureCache?.globalTextures.foilNormalNoise}
                geometry={geometry}
                faceGeometry={faceGeometry}
                normalMap={normalMap?.texture}
                silhouetteMap={silhouetteMap?.texture}
                foil={pageMap.foils?.[foilName]}
                animatedOpacity={animatedOpacity}
                faceName={node.faceName}
                envMap={envMap}
                parallaxScale={parallaxScale}
                normalScale={normalScale}
                parallaxMinLayers={parallaxMinLayers}
                parallaxMaxLayers={parallaxMaxLayers}
                layersView={creationState.layersView}
                faceUVs={faceUVs}
                verso={verso}
                mask={maskTexture}
                foilMaterial={foilMapping[foilName]}
                foilDiffuseColor={foilDiffuseColor}
                silhouetteMappingMode={silhouetteMappingMode}
                boxBufferGeometry={boxBufferGeometry}
                depth={depth}
              />
            );
          } else {
            return null;
          }
        })}

      {!angleMode && !anglePreview3D && pageMap?.varnish && (
        <Varnish
          geometry={geometry}
          faceGeometry={faceGeometry}
          normalMap={normalMap?.texture}
          silhouetteMap={silhouetteMap?.texture}
          normalNoise={textureCache?.globalTextures.normalNoise}
          varnish={pageMap.varnish}
          animatedOpacity={animatedOpacity}
          faceName={node.faceName}
          envMap={envMap}
          normalScale={normalScale}
          parallaxScale={parallaxScale}
          parallaxMinLayers={parallaxMinLayers}
          parallaxMaxLayers={parallaxMaxLayers}
          layersView={creationState.layersView}
          faceUVs={faceUVs}
          metalness={varnishMaterialProps.metalness}
          roughness={varnishMaterialProps.roughness}
          reflectivity={varnishMaterialProps.reflectivity}
          envMapIntensity={varnishMaterialProps.envMapIntensity}
          normalScaleFactor={varnishMaterialProps.normalScaleFactor}
          vecXNormalScale={varnishMaterialProps.vecXNormalScale}
          vecYNormalScale={varnishMaterialProps.vecYNormalScale}
          displayedMap={displayedMap}
          verso={verso}
          mask={maskTexture}
          silhouetteMappingMode={silhouetteMappingMode}
          varnishLevel={varnishLevel}
          boxBufferGeometry={boxBufferGeometry}
          depth={depth}
        />
      )}
    </>
  );
};

export default DesignFace;
