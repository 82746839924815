import {
  Mesh,
  NoBlending,
  OrthographicCamera,
  PlaneBufferGeometry,
  RGBAFormat,
  Scene,
  ShaderMaterial,
  Texture,
  WebGLRenderer,
  WebGLRenderTarget,
} from "three";
import { layerIsEnabled, LayerView } from "../../Domain";

export function initRenderTargetTexture(
  gl: WebGLRenderer,
  textures: Texture[] | undefined,
  fragmentShader: string,
  plane?: PlaneBufferGeometry,
  texture?: Texture,
  layersView?: LayerView[],
  varnishLevel?: number
): WebGLRenderTarget | undefined {
  const [rtTexture, quadScene] = textures
    ? initRenderTarget(
        textures,
        fragmentShader,
        layersView,
        varnishLevel,
        plane,
        texture
      )
    : [undefined, undefined];

  if (rtTexture && textures && quadScene && texture) {
    const image = texture.image as HTMLImageElement;

    rtTexture.texture.offset = texture.offset;
    rtTexture.texture.repeat = texture.repeat;

    const cameraRTT = new OrthographicCamera(
      -image.width / 2,
      image.width / 2,
      image.height / 2,
      -image.height / 2,
      -10000,
      10000
    );
    gl.setRenderTarget(rtTexture);
    gl.clear();
    gl.render(quadScene, cameraRTT);

    gl.setRenderTarget(null);
  }

  return rtTexture;
}

const rtVertexShader = `
      varying vec2 vUv;

			void main() {

				vUv = uv;
				gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

      }`;

function initRenderTarget(
  textures: Texture[],
  fragmentShader: string,
  layersView?: LayerView[],
  varnishLevel?: number,
  plane?: PlaneBufferGeometry,
  texture?: Texture
): [WebGLRenderTarget | undefined, Scene | undefined] {
  const image = texture?.image as HTMLImageElement | undefined;
  if (!image) {
    return [undefined, undefined];
  }

  const rtTexture = new WebGLRenderTarget(image.width, image.height, {
    format: RGBAFormat,
  });

  const uniforms = textures
    .map((texture, index) => ({
      [`texture${index}`]: { value: texture },
      vSizeTexture: {
        value: [image.width, image.height, 1 / image.width, 1 / image.height],
      },

      coeffs: {
        //value: [0.598256767, 0.194225565, 0.0066460357, 0],
        //flou gaussien rayon 3
        //[0.399050295, 0.242036238, 0.0540055856, 0.00443304842],
        value: [0.17118068, 0.156160265, 0.118554488, 0.0749026313],
      },
      coeffs2: {
        //value: [0.598256767, 0.194225565, 0.0066460357, 0],
        //flou gaussien rayon 3
        value: [0.0393829122, 0.0172325764, 0.00627515092, 0.00190164649],
      },
      level: {
        value: varnishLevel && varnishLevel / 21.0,
      },
    }))
    .reduce((acc, newUniform) => ({ ...acc, ...newUniform }), {});

  const factors = layersView
    ?.map((layer) => ({
      [`factor${layer.name}`]: {
        value: layerIsEnabled(layer.name, layersView) ? 1.0 : 0.0,
      },
    }))
    .reduce((acc, newUniform) => ({ ...acc, ...newUniform }), {});

  Object.assign(uniforms, factors);

  const material = new ShaderMaterial({
    vertexShader: rtVertexShader,
    fragmentShader,
    uniforms,
    blending: NoBlending,
  });
  const quad = new Mesh(plane, material);
  const scene = new Scene();
  scene.add(quad);

  return [rtTexture, scene];
}
