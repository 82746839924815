import {
  BufferGeometry,
  Color,
  DoubleSide,
  Mesh,
  NoBlending,
  OrthographicCamera,
  RGBAFormat,
  Scene,
  ShaderMaterial,
  Texture,
  WebGLRenderer,
  WebGLRenderTarget,
} from "three";

export function initRenderTargetMask(
  gl: WebGLRenderer,
  texture: Texture | undefined,
  dimensions: { width: number; height: number },
  fragmentShader: string,
  plane?: BufferGeometry
): WebGLRenderTarget | undefined {
  const [rtTexture, quadScene] = texture
    ? initRenderTarget(texture, fragmentShader, plane)
    : [undefined, undefined];

  if (rtTexture && texture && quadScene) {
    const cameraRTT = new OrthographicCamera(
      0,
      dimensions.width,
      0,
      dimensions.height,
      -10000,
      10000
    );
    const oldColor = gl.getClearColor(new Color(1, 1, 1)).clone();
    gl.setRenderTarget(rtTexture);
    gl.setClearColor(new Color(0, 0, 0), 1.0);
    gl.clear();
    gl.setClearColor(oldColor, 0.0);
    gl.render(quadScene, cameraRTT);

    gl.setRenderTarget(null);
  }

  return rtTexture;
}

const rtVertexShader = `
      varying vec2 vUv;

			void main() {

				vUv = uv;
				gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

      }`;

function initRenderTarget(
  textures: Texture,
  fragmentShader: string,
  geom?: BufferGeometry
): [WebGLRenderTarget | undefined, Scene | undefined] {
  const image = textures.image as HTMLImageElement | undefined;
  if (!image) {
    return [undefined, undefined];
  }

  const rtTexture = new WebGLRenderTarget(image.width, image.height, {
    format: RGBAFormat,
  });

  const uniforms = {};

  const material = new ShaderMaterial({
    vertexShader: rtVertexShader,
    fragmentShader,
    uniforms,
    blending: NoBlending,
    side: DoubleSide,
  });
  const quad = new Mesh(geom, material);

  const scene = new Scene();
  scene.add(quad);

  return [rtTexture, scene];
}
