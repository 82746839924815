import React, { useMemo } from "react";
import { BoxBufferGeometry, Color, MeshBasicMaterial } from "three";
import { Dieline } from "..";

export const Box = ({ dieline }: { dieline: Dieline }): JSX.Element => {
  const boxGeo = useMemo(() => {
    const boxGeo = new BoxBufferGeometry(
      dieline.dimensions.width,
      dieline.dimensions.height,
      0
    );

    return boxGeo;
  }, [dieline.dimensions.height, dieline.dimensions.width]);

  const boxMaterial = useMemo(
    () =>
      new MeshBasicMaterial({
        color: new Color(0xff0000),
        transparent: true,

        visible: false,
      }),
    []
  );

  return (
    <mesh
      position={[
        dieline.dimensions.width / 2,
        -dieline.dimensions.height / 2,
        0,
      ]}
      geometry={boxGeo}
      material={boxMaterial}
    />
  );
};
