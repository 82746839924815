import { FoilColorName, FOIL_COLORS_NAMES } from "../Foil";
import { LayerEntity } from "../LayerEntity";

export interface ProductDesign {
  recto: DesignPage;
  verso?: DesignPage;
  mask?: LayerEntity;
}

export type DesignPage = {
  [Name in FoilColorName]?: { layer: LayerEntity };
} & {
  color: {
    layer: LayerEntity;
  };

  varnish?: {
    layer: LayerEntity;
  };
  holoFoil?: {
    layer: LayerEntity;
  };
  normalMap?: {
    layer: LayerEntity;
  };
  displacementMap?: {
    layer: LayerEntity;
  };
  bumpMap?: {
    layer: LayerEntity;
  };
  mask?: {
    layer: LayerEntity;
  };
};

const listLayers: string[] = [
  "paper",
  "color",
  "varnish",
  ...FOIL_COLORS_NAMES,
  "holoFoil",
  "normalMap",
  "displacementMap",
];

type LayerTuple = [string, { layer: LayerEntity }];

export function parseProductDesignFromLayers(
  response: LayerEntity[]
): ProductDesign | Error {
  const recto = parseDesignPage(
    response,
    (page) => page === 1 || page === undefined
  );
  const verso = parseDesignPage(response, (page) => page === 2);

  const mask = pickMaskLayer(response);
  if (recto instanceof Error) {
    return new Error("missing color layer");
  } else {
    if (verso instanceof Error) {
      return { recto, mask };
    } else {
      return { recto, verso, mask };
    }
  }
}

function parseDesignPage(
  response: LayerEntity[],
  pagePredicate: (number: number | undefined) => boolean
): DesignPage | Error {
  const allLayers: LayerTuple[] = listLayers
    .map((layerName) => pickLayer(layerName, response, pagePredicate))
    .filter(layerTupleIsDefined);

  // Check presence of mandatory color layer
  const colorLayerTuple = pickLayer("color", response, pagePredicate);
  if (colorLayerTuple === undefined) {
    return new Error("missing color layer");
  }
  return {
    color: colorLayerTuple[1],
    ...Object.fromEntries(allLayers),
  };
}

function layerTupleIsDefined(
  layerTuple: LayerTuple | undefined
): layerTuple is LayerTuple {
  return layerTuple !== undefined;
}

function pickLayer(
  layerName: string,
  json: LayerEntity[],
  pagePredicate: (number: number | undefined) => boolean
): LayerTuple | undefined {
  const layerEntity = json.find(
    (layerEntity) =>
      layerEntity.name === layerName && pagePredicate(layerEntity.page)
  );
  return layerEntity ? [layerName, { layer: layerEntity }] : undefined;
}

function pickMaskLayer(json: LayerEntity[]): LayerEntity | undefined {
  const mask = pickLayer("mask", json, () => true);
  return mask?.[1]?.layer;
}
