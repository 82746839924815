export const bottleFragmentShader = `



varying vec3 vNormalAbs ;
varying vec3 vPositionInter;
varying vec4 vPositionRel;
varying vec3 vNormalRel;

uniform mat3 uv2Transform ;
uniform vec4 flip;

vec4 rast_COLOR0;
uniform sampler2D samplerEnvMap;
uniform sampler2D vLabelMap;
uniform sampler2D mask;
uniform sampler2D samplerDistance;
uniform vec4 vCameraPosRel;
uniform mat4 matWorld;
uniform mat4 matWorldInv;
uniform float labelWidth;
uniform float labelHeight;
uniform float labelPosition;


    vec2 equirectUv ( in vec3 dir ) {
        float u = atan ( dir . z , dir . x ) * 0.15915494309189535 + 0.5 ;
        float v = asin ( clamp ( dir . y , - 1.0 , 1.0 ) ) * 0.3183098861837907 + 0.5 ;
        return vec2 ( u , v ) ;
        }


        vec2 ComputeRadialCoordinates(vec3 vNormal) {
            vec2 vSphere;
            ((vSphere).x = atan((vNormal).z, (vNormal).x));
            ((vSphere).y = acos((vNormal).y));
            (vSphere *= vec2 ((1.0 / 3.14159)));
            ((vSphere).x = ((0.5 * (vSphere).x) + 0.5));
            ((vSphere).y = (1.0 - (vSphere).y));
            return vSphere;
        }

const float fMinStep = 0.2;
const float Air = 1.0;
const float Glass = 1.51714;
const float Content = 1.0;
const float EtaAirToGlass = (Air / Glass);
const float EtaGlassToAir = (Glass / Air);
const float EtaContentToGlass = (Content / Glass);
const float EtaGlassToContent = (Glass / Content);
const float R0 = (((Air - Glass) * (Air - Glass)) / ((Air + Glass) * (Air + Glass)));
uniform float fBottleHeight;
uniform float fBottleRadius;
uniform float fBottleThickness;
uniform float fBottleHeightScale;
uniform float fBottleRadiusScale;
uniform float fDistanceMax;
uniform float fDistanceMaxInv;
vec4 GetDistanceNormalInner(vec3 vPosition, vec2 vDerivX2, vec2 vDerivY2) {
    vec2 vTexture;
    ((vTexture).x = (((-fBottleThickness) + (((vPosition).z * (fBottleHeight + (2.0 * fBottleThickness))) / fBottleHeight)) * fBottleHeightScale));
    ((vTexture).y = ((length((vPosition).xy) + fBottleThickness) * fBottleRadiusScale));
    vec4 cDistance = texture2D(samplerDistance, vTexture);
    ((cDistance).a = (fDistanceMax * ((2.0 * (cDistance).a) - 1.0)));
    ((cDistance).rgb = normalize(((2.0 * (cDistance).rgb) - vec3(1.0, 1.0, 1.0))));
    ((cDistance).rg = ((sign((cDistance).r) * length((cDistance).rg)) * normalize((vPosition).xy)));
    return cDistance;
}
vec4 GetDistanceNormalOuter(vec3 vPosition, vec2 vDerivX2, vec2 vDerivY2) {
    vec2 vTexture;
    ((vTexture).x = ((vPosition).z * fBottleHeightScale));
    ((vTexture).y = (length((vPosition).xy) * fBottleRadiusScale));
    vec4 cDistance = texture2D(samplerDistance, vTexture);
    ((cDistance).a = (fDistanceMax * ((2.0 * (cDistance).a) - 1.0)));
    ((cDistance).rgb = normalize(((2.0 * (cDistance).rgb) - vec3(1.0, 1.0, 1.0))));
    ((cDistance).rg = ((sign((cDistance).r) * length((cDistance).rg)) * normalize((vPosition).xy)));
    return cDistance;
}

float mod2(float x, float y) {
    return x - y * floor(x/y);
}

void main() {
    vec4 cTintGlass = vec4(0.3, 0.6, 0.3, 1.0);
    vec4 cTintReflection = vec4(0.8, 1.0, 0.8, 1.0);
    vec4 cTintContent = vec4(0.7, 0.8, 0.2, 1.0);
    vec4 cContent = vec4(1.0, 1.0, 1.0, 1.0);
    vec3 vNormal = normalize((vNormalRel).xyz);
    vec4 vPositionRelTemp = vPositionRel;
    vPositionRelTemp.xy *= 15.0;    
    vPositionRelTemp.z = (vPositionRel.z + 1.0) * 15.0;
    vec2 vDerivX = dFdx(vec2(((vPositionRelTemp).z * fBottleHeightScale), 0));
    vec2 vDerivY = dFdy(vec2(((vPositionRelTemp).z * fBottleHeightScale), 0));
    vec2 vDerivX2 = dFdx(vec2(((vPositionRelTemp).z * fBottleHeightScale), (length((vPositionRelTemp).xy) * fBottleRadiusScale)));
    vec2 vDerivY2 = dFdy(vec2(((vPositionRelTemp).z * fBottleHeightScale), (length((vPositionRelTemp).xy) * fBottleRadiusScale)));
    vec3 vPosition = (vPositionRelTemp).xyz;
    vec4 cDistance = GetDistanceNormalOuter(vPosition, vDerivX2, vDerivY2);
    (vNormal = (cDistance).rgb);
    vec3 vCameraDir = normalize(((vCameraPosRel).xyz - (vPositionRel).xyz));
    vec3 vRay = (-normalize(vCameraDir));
    float fFresnel = clamp((R0 + ((1.0 - R0) * pow((1.0 + dot(vRay, vNormal)), 2.0))), 0.0, 1.0);
    vec3 vReflection = normalize(reflect(vRay, vNormal));
    (vRay = normalize(refract(vRay, normalize(vNormal), EtaAirToGlass)));
    (vPosition -= (vRay * 0.5));
    (cDistance = GetDistanceNormalInner(vPosition, vDerivX2, vDerivY2));
    float fTint = 0.0;
    if (((((vRay).x == 0.0) && ((vRay).y == 0.0)) && ((vRay).z == 0.0))) {
        (fTint = 1.0);
    }
    vec3 vStart = vPosition;
    vec3 vPositionOld;
    vec3 vNormalOld;
    float fDistanceOld;
    for (int i = 0; (i < 64); (i++)) {
        (fDistanceOld = (cDistance).a);
        (vPositionOld = vPosition);
        (vNormalOld = (cDistance).rgb);
        (vPosition += (max(fMinStep, abs((cDistance).a)) * vRay));
        (cDistance = GetDistanceNormalInner(vPosition, vDerivX2, vDerivY2));
        if (((cDistance).a <= 0.0)) {
            float fTemp = ((-(cDistance).a) / fDistanceOld);
            float fRatio = (fTemp / (1.0 + fTemp));
            (vPosition = vec3 (mix(vec3 (vPosition), vec3 (vPositionOld), vec3 (fRatio))));
            (vStart = vPosition);
            (vNormal = vec3 (normalize(mix(vec3 ((cDistance).rgb), vec3 (vNormalOld), vec3 (fRatio)))));
            (vRay = normalize(refract(vRay, vNormal, EtaGlassToContent)));
            if (((((vRay).x == 0.0) && ((vRay).y == 0.0)) && ((vRay).z == 0.0))) {
                (fTint = 1.0);
            }
            break;
        }
    }
    if (((cDistance).a < 0.0)) {
        (cContent = cTintContent);
        (vPosition = vStart);
        for (int i = 0; (i < 64); (i++)) {
            (fDistanceOld = (cDistance).a);
            (vPositionOld = vPosition);
            (vNormalOld = (cDistance).rgb);
            (vPosition += (max(fMinStep, abs((cDistance).a)) * vRay));
            (cDistance = GetDistanceNormalInner(vPosition, vDerivX2, vDerivY2));
            if (((cDistance).a >= 0.0)) {
                float fTemp = ((cDistance).a / (-fDistanceOld));
                float fRatio = (fTemp / (1.0 + fTemp));
                (vPosition = vec3 (mix(vec3 (vPosition), vec3 (vPositionOld), vec3 (fRatio))));
                (vStart = vPosition);
                (vNormal = vec3 (normalize(mix(vec3 ((cDistance).rgb), vec3 (vNormalOld), vec3 (fRatio)))));
                (vRay = normalize(refract(vRay, (-vNormal), EtaContentToGlass)));
                if (((((vRay).x == 0.0) && ((vRay).y == 0.0)) && ((vRay).z == 0.0))) {
                    (fTint = 1.0);
                }
                break;
            }
        }
    }
    (vPosition = vStart);
    for (int i = 0; (i < 64); (i++)) {
        (fDistanceOld = (cDistance).a);
        (vPositionOld = vPosition);
        (vNormalOld = (cDistance).rgb);
        (vPosition += (max(fMinStep, abs((cDistance).a)) * vRay));
        (cDistance = GetDistanceNormalOuter(vPosition, vDerivX2, vDerivY2));
        if (((cDistance).a >= 0.0)) {
            float fTemp = ((cDistance).a / (-fDistanceOld));
            float fRatio = (fTemp / (1.0 + fTemp));
            (vNormal = vec3 (normalize(mix(vec3 ((cDistance).rgb), vec3 (vNormalOld), vec3 (fRatio)))));
            (vRay = normalize(refract(vRay, (-vNormal), EtaGlassToAir)));
            if (((((vRay).x == 0.0) && ((vRay).y == 0.0)) && ((vRay).z == 0.0))) {
                (fTint = 1.0);
            }
            break;
        }
        (fDistanceOld = (cDistance).a);
    }



    vec2 vSizeLabel = vec2( labelWidth, labelHeight );
	vSizeLabel *= 0.5;
	vec2 vCenter = vec2( vSizeLabel.x , 1.0 - (labelPosition + vSizeLabel.y));
	vec4 cRefraction;
	vec3 vOut = normalize( vPosition );
	float fTextureXOut = mod2( 2.0 * 3.14159 - atan( vOut.y, vOut.x ), 2.0 * 3.14159 ) / ( 2.0 * 3.14159 );
	if( fTextureXOut > vCenter.x - vSizeLabel.x && fTextureXOut < vCenter.x + vSizeLabel.x
		&& 1.0 - vPosition.z / fBottleHeight > vCenter.y - vSizeLabel.y && 1.0 - vPosition.z / fBottleHeight < vCenter.y + vSizeLabel.y )
	{

     vec2 uvLabel = vec2((fTextureXOut - vCenter.x + vSizeLabel.x ) / (2.0 * vSizeLabel.x), (1.0 - vPosition.z / fBottleHeight - vCenter.y + vSizeLabel.y ) / (2.0 * vSizeLabel.y));

    vec2 uvTemp = vec2(flip.x + flip.y * uvLabel.x, flip.z + flip.w * uvLabel.y);
    vec2 vUv2 = ( uv2Transform * vec3 ( uvTemp , 1 ) ) . xy ;
    

        float fMask = texture2D( mask, vUv2 ) . r;
        if (fMask == 0.0){
            vec3 vRefractionAbs = (normalize((((matWorld) * vec4(vRay, 0.0)) ))).xyz;
            cRefraction = texture2D(samplerEnvMap, equirectUv(normalize(vRefractionAbs)));
        }
        else {
            cRefraction = texture2D(vLabelMap, vUv2);
        }
    }
    
	else
	{
        vec3 vRefractionAbs = (normalize((((matWorld) * vec4(vRay, 0.0)) ))).xyz;
        cRefraction = texture2D(samplerEnvMap, equirectUv(normalize(vRefractionAbs)));
    }   

    vec3 vReflection1 = (matWorld * vec4(reflect(normalize(vPositionRel.xyz - (vCameraPosRel ).xyz), normalize(vNormalRel)), 0.0)  ).xyz;
    vec4 cReflection = texture2D(samplerEnvMap, equirectUv(normalize(vReflection1)));
    (((gl_FragColor)).rgb = vec3 ((mix(vec4 ((cTintGlass * mix(vec4 ((cContent * cRefraction)), vec4 ((0.2 * cTintGlass)), vec4 (step(0.5, fTint))))), vec4 ((cTintReflection * cReflection)), vec4 (fFresnel))).rgb));    
    (((gl_FragColor)).a = 1.0);
    
}




`;
