export const generatedSilhouetteMappingTexture = `



uniform sampler2D samplerColor ;
uniform sampler2D mask ;
uniform float fHeight;
uniform vec4 vSizeTexture;
varying vec2 vUv ;
float Sign( float v )
{
	return v < 0.0 ? -1.0 : 1.0;
}

void main ( ) {
	
	
	vec2	vTexture = vUv + 0.5 * vSizeTexture.zw;
	
	vec4	cColor = texture2D( samplerColor, vTexture );
	vec3	vPosition = vec3( vSizeTexture.x * vTexture.x, vSizeTexture.y * vTexture.y, fHeight * cColor.a );
	
	float	fHeightMax = 1.01 * fHeight;
	float	fHeightMin = -0.01 * fHeight;
	
	vec4	vReferenceHeight = vec4(fHeightMin, fHeightMin, fHeightMin, fHeightMin) + ( vec4(fHeightMax, fHeightMax, fHeightMax, fHeightMax) - vec4(fHeightMin, fHeightMin, fHeightMin, fHeightMin) ) * vec4( 0.0, 1.0 / 3.0, 2.0 / 3.0, 1.0 );
	
	vec4	vDistancesMin = vec4(fHeight, fHeight, fHeight, fHeight);//100.0 * fHeight.xxxx;//* vec4( fHeight, fHeight, fHeight, fHeight );
	
	vec4	vSigns;
	vSigns.x = Sign( vReferenceHeight.x - vPosition.z );
	vSigns.y = Sign( vReferenceHeight.y - vPosition.z );
	vSigns.z = Sign( vReferenceHeight.z - vPosition.z );
	vSigns.w = Sign( vReferenceHeight.w - vPosition.z );
	//vec2	vPixel;
	//vPixel.x = 0;
	for( float v = 0.0; v < 100.0; v++ )
	{
		if (v >= 2.0 * fHeight + 1.0){break;}
		 float v2 = v - fHeight;
	
		for( float w = 0.0; w < 100.0; w++ )
		//	[fastopt] for( vPixel.x = 0.5f * vSizeTexture.z; vPixel.x < 1.0; vPixel.x += vSizeTexture.z )
		{
			if (w >= 2.0 * fHeight + 1.0){break;}
			float w2 = w - fHeight;
			//vec3	vPositionPixel = vec3( vSizeTexture.x * vPixel.x, vSizeTexture.y * vPixel.y, fHeight * texture2D( samplerColor, vec4( vPixel, 0, 0 ) ).r );
			
			float	fElevation = texture2D( samplerColor, vTexture ).a * texture2D( mask, vTexture ).r;
			
			vec3	vPositionPixel = vec3( vPosition.x + w2, vPosition.y + v2, fHeight * fElevation );
			vec4	vDistances;
			vDistances.x = length( vec3( vPosition.x, vPosition.y, vReferenceHeight.x ) - vPositionPixel );
			vDistances.y = length( vec3( vPosition.x, vPosition.y, vReferenceHeight.y ) - vPositionPixel );
			vDistances.z = length( vec3( vPosition.x, vPosition.y, vReferenceHeight.z ) - vPositionPixel );
			vDistances.w = length( vec3( vPosition.x, vPosition.y, vReferenceHeight.w ) - vPositionPixel );
			vDistancesMin = min( vDistancesMin, vDistances );
		}
	}
	gl_FragColor = 0.5 * ( vSigns * saturate( vDistancesMin / fHeight ) + vec4(1.0,1.0,1.0,1.0) );
}
`;
