import {} from "@react-three/fiber";
import React, { useEffect, useMemo, useRef } from "react";
import { Color, Group, PointLight } from "three";
import { LedAnimationType } from "../Domain";

// A keyframe contains the indices of the leds that are turned on
type Keyframe = number[];

export type LedDisplayProps = Pick<
  LedProps,
  | "ledIntensity"
  | "sphereSize"
  | "distance"
  | "decay"
  | "sphereColor"
  | "lightColor"
>;

export type LedProps = {
  ledIntensity: number;
  sphereSize: number;
  distance: number;
  decay: number;
  sphereColor: number;
  lightColor: number;
  ledPositions: Array<[number, number] | undefined>;
  ledType: LedAnimationType;
};
export const Leds = ({
  ledIntensity: maxIntensity,
  sphereSize,
  distance,
  decay,
  sphereColor,
  lightColor,
  ledPositions,
  ledType,
}: LedProps) => {
  const ledCount = ledPositions.length;

  const keyframes: Keyframe[] = useMemo(
    () => generateKeyframes(ledCount, ledType),
    [ledCount, ledType]
  );

  const groupRef = useRef<Group>(null!);

  useEffect(() => {
    let keyFrameIndex = 0;
    const intervalId = setInterval(() => {
      // Update all leds
      for (const child of groupRef.current.children) {
        const led = child as PointLight;
        // Show led if it is included in the keyframe led indices
        const visible = keyframes[keyFrameIndex].includes(led.userData.index);
        if (visible) {
          led.intensity = maxIntensity;
          led.children[0].visible = true;
        } else {
          led.intensity = 0;
          led.children[0].visible = false;
        }
      }

      // Bump frame index
      keyFrameIndex = (keyFrameIndex + 1) % keyframes.length;
    }, 75);

    return () => {
      clearInterval(intervalId);
    };
  });

  return (
    <group ref={groupRef}>
      {ledPositions.map(
        (position, index) =>
          typeof position !== "undefined" && (
            <pointLight
              // Since the LEDs never change, using the array index as key is ok
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              userData={{ index }}
              intensity={0}
              color={new Color(lightColor)}
              distance={distance}
              position={[position[0], -position[1], 6.5]}
              decay={decay}
            >
              <mesh attach="mesh" visible>
                <sphereBufferGeometry
                  attach="geometry"
                  args={[sphereSize, 16, 16]}
                />
                <meshBasicMaterial
                  attach="material"
                  color={new Color(sphereColor)}
                />
              </mesh>
            </pointLight>
          )
      )}
    </group>
  );
};

export const defaultLedProps: Omit<LedProps, "ledPositions"> = {
  ledIntensity: 3250,
  sphereSize: 0.5,
  distance: 75,
  decay: 55,
  sphereColor: 0xff0000,
  lightColor: 0xff00000,
  ledType: "chaser",
};

Leds.defaultProps = defaultLedProps;

function generateKeyframes(ledCount: number, ledType: LedAnimationType) {
  if (ledType === "chaser") {
    // Returns 0,1,..,n,n-1,..,1
    return Array.from(Array(ledCount * 2 - 2), (_, index: number) => [
      index < ledCount ? index : ledCount * 2 - index - 2,
    ]);
  }
  if (ledType === "blink") {
    const allLedIndices: Keyframe = Array.from(
      Array(ledCount),
      (_, index) => index
    );
    // Blink once every 5 frames
    return [allLedIndices, [], [], [], []];
  }
  if (ledType === "dual-chaser") {
    // Chaser-like animation, but on two sequences at the same time
    const halfLedCount = ledCount / 2;
    const firstSequence = Array.from(
      Array(halfLedCount * 2 - 2),
      (_, index: number) =>
        index < halfLedCount ? index : halfLedCount * 2 - index - 2
    );
    return firstSequence.map((ledIndex) => [ledIndex, ledIndex + halfLedCount]);
  }
  return [];
}
