import React, { useEffect, useMemo, useRef } from "react";
import { SpringValue } from "@react-spring/three";
import { useFrame, useThree } from "@react-three/fiber";
import {
  BufferGeometry,
  Color,
  Material,
  Matrix4,
  Mesh,
  ShaderMaterial,
  Texture,
  Vector2,
  Vector3,
  Vector4,
} from "three";
import { FaceGeometry, layerIsEnabled, LayerView } from "../../Domain";
import { FoilMaterial } from "../../Domain/Foil";
import { BoxSilhouetteGeometry } from "../Shaders/boxSilhouetteGeometry";
import { foilFragmentShader } from "../Shaders/foilFragmentShader";
import { foilSilhouetteMappingShader } from "../Shaders/foilSilhouetteMappingShader";
import { silhouetteVertexShader } from "../Shaders/silhouetteVertexShader";
import { vertexShader } from "../Shaders/vertexShader";

type FoilProps = {
  foilNormalNoise?: Texture;
  geometry: BufferGeometry;
  faceGeometry: FaceGeometry;
  normalMap: Texture | undefined;
  silhouetteMap: Texture | undefined;
  foil: Texture | undefined;
  animatedOpacity: SpringValue<number>;
  faceName: string;
  envMap?: Texture;
  parallaxScale: number;
  normalScale: Vector2;
  parallaxMinLayers: number;
  parallaxMaxLayers: number;
  faceUVs: { repeat: Vector2; offset: Vector2 };
  verso?: boolean;
  mask?: Texture;

  layersView: LayerView[];
  layerName: string;
  foilMaterial: FoilMaterial;
  foilDiffuseColor: string;
  silhouetteMappingMode: boolean;
  boxBufferGeometry: BoxSilhouetteGeometry;
  depth: number;
};

function Foil({
  foilNormalNoise,
  geometry,
  faceGeometry,
  normalMap,
  foil,
  animatedOpacity,
  faceName,
  envMap,
  normalScale,
  parallaxScale,
  parallaxMinLayers,
  parallaxMaxLayers,
  faceUVs,
  verso,
  mask,
  layersView,
  layerName,
  foilMaterial,
  silhouetteMappingMode,
  silhouetteMap,
  boxBufferGeometry,
  depth,
}: FoilProps): JSX.Element {
  const materialRef = useRef<ShaderMaterial>(null!);

  const objectRef = useRef<Mesh>();

  const { camera } = useThree();

  const boxMatrixWorld = useRef<Matrix4>(new Matrix4());

  const vCameraPosRelInv = useRef<Vector4>(new Vector4());

  const matrix = useMemo(() => new Matrix4(), []);

  useEffect(() => {
    if (objectRef.current) {
      objectRef.current.matrixWorldNeedsUpdate = true;
      boxMatrixWorld.current = objectRef.current.matrixWorld;
      vCameraPosRelInv.current = new Vector4(
        camera.position.x,
        camera.position.y,
        camera.position.z,
        1
      ).applyMatrix4(boxMatrixWorld.current.invert());
    }
  }, [parallaxScale, camera.position, matrix]);

  useFrame(() => {
    vCameraPosRelInv.current.x = camera.position.x;
    vCameraPosRelInv.current.y = camera.position.y;
    vCameraPosRelInv.current.z = camera.position.z;
    vCameraPosRelInv.current.applyMatrix4(boxMatrixWorld.current.invert());
  });

  const foilUniformsRecto = useMemo(() => {
    return {
      diffuse: { value: foilMaterial.diffuse },
      opacity: { value: animatedOpacity },
      map: { value: foil },
      uvTransform: {
        value: [
          faceUVs.repeat.x,
          -0,
          0,
          0,
          faceUVs.repeat.y,
          0,
          faceUVs.offset.x,
          faceUVs.offset.y,
          1,
        ],
      },
      uv2Transform: { value: [1, 0, 0, 0, 1, 0, 0, 0, 1] },
      alphaMap: { value: mask },
      envMap: { value: envMap },
      flipEnvMap: { value: -1 },
      reflectivity: { value: foilMaterial.reflectivity },
      refractionRatio: { value: 0.98 },
      maxMipLevel: { value: 0 },
      aoMap: { value: null },
      aoMapIntensity: { value: 1 },
      lightMap: { value: null },
      lightMapIntensity: { value: 1 },
      emissiveMap: { value: null },
      normalMap: { value: normalMap },
      silhouetteMap: { value: silhouetteMap },
      normalScale: { value: normalScale },
      roughnessMap: { value: null },
      metalnessMap: { value: null },
      ambientLightColor: {
        value: [0.7278431372549019, 0.7278431372549019, 0.7278431372549019],
        needsUpdate: true,
      },
      emissive: {
        value: foilMaterial.emissive,
      },
      roughness: { value: foilMaterial.roughness },
      metalness: { value: foilMaterial.metalness },
      envMapIntensity: { value: foilMaterial.envMapIntensity },
      transparency: { value: 1 },
      clearcoat: { value: 0 },
      clearcoatRoughness: { value: 0 },
      sheen: { value: new Color(0, 0, 0) },
      clearcoatNormalScale: { value: { x: 1, y: 1 } },
      clearcoatNormalMap: { value: null },
      clippingPlanes: { value: null, needsUpdate: false },
      parallaxScale: { value: parallaxScale },
      parallaxMinLayers: { value: parallaxMinLayers },
      parallaxMaxLayers: { value: parallaxMaxLayers },
      normalNoise: { value: foilNormalNoise },
      normalScaleFactor: { value: foilMaterial.normalScaleFactor },
      vecXNormalScale: { value: foilMaterial.vecXNormalScale },
      vecYNormalScale: { value: foilMaterial.vecYNormalScale },
      flip: { value: new Vector4(0, 1, 0, 1) },
      verso: { value: verso ? 0.0 : 0.0 },
      colorElevation: {
        value: layerIsEnabled("varnish", layersView) ? 1.0 : 0.1,
      },
      boxSize: {
        value: new Vector3(
          boxBufferGeometry.parameters.width,
          boxBufferGeometry.parameters.height,
          boxBufferGeometry.parameters.depth
        ),
      },
      boxPosition: {
        value: new Vector3(
          faceGeometry.position[0],
          faceGeometry.position[1],
          verso ? -depth / 2 : depth / 2
        ),
      },
      cameraPosRel: {
        value: vCameraPosRelInv.current,
      },

      boxUv: {
        value: new Vector4(
          faceUVs.offset.x,
          faceUVs.offset.y,
          faceUVs.repeat.x,
          faceUVs.repeat.y
        ),
      },
      textureSize: {
        value: new Vector2(foil?.image.height, foil?.image.width),
      },
    };
  }, [
    foilMaterial.diffuse,
    foilMaterial.reflectivity,
    foilMaterial.emissive,
    foilMaterial.roughness,
    foilMaterial.metalness,
    foilMaterial.envMapIntensity,
    foilMaterial.normalScaleFactor,
    foilMaterial.vecXNormalScale,
    foilMaterial.vecYNormalScale,
    animatedOpacity,
    foil,
    faceUVs.repeat.x,
    faceUVs.repeat.y,
    faceUVs.offset.x,
    faceUVs.offset.y,
    mask,
    envMap,
    normalMap,
    silhouetteMap,
    normalScale,
    parallaxScale,
    parallaxMinLayers,
    parallaxMaxLayers,
    foilNormalNoise,
    verso,
    layersView,
    boxBufferGeometry.parameters.width,
    boxBufferGeometry.parameters.height,
    boxBufferGeometry.parameters.depth,
    faceGeometry.position,
    depth,
  ]);

  useEffect(() => {
    foilUniformsRecto.cameraPosRel.value = vCameraPosRelInv.current;
  }, [foilUniformsRecto.cameraPosRel]);

  const foilMaterialMesh = useMemo(() => {
    return new ShaderMaterial({
      uniforms: foilUniformsRecto,
      vertexShader: silhouetteVertexShader,
      fragmentShader: foilSilhouetteMappingShader,
      transparent: true,
      alphaTest: 0.01,

      depthWrite: false,
    });
  }, [foilUniformsRecto]);

  return !silhouetteMappingMode ? (
    <mesh
      name={faceName}
      geometry={geometry}
      position={[...faceGeometry.position, verso ? -0.1 : 0]}
      visible={layerIsEnabled(layerName, layersView) ?? true}
      rotation={verso ? [0, Math.PI, 0] : [0, 0, 0]}
      renderOrder={verso ? 505 : 500}
    >
      <shaderMaterial
        ref={materialRef as React.Ref<ShaderMaterial> | undefined}
        onUpdate={(self: Material) => (self.needsUpdate = true)}
        needsUpdate={true}
        uniformsNeedUpdate={true}
        attach="material"
        uniforms={foilUniformsRecto}
        vertexShader={vertexShader}
        fragmentShader={foilFragmentShader}
        transparent
        alphaTest={0.01}
      />
    </mesh>
  ) : (
    <mesh
      name={faceName}
      position={[...faceGeometry.position, verso ? -depth / 2 : depth / 2]}
      visible={layerIsEnabled(layerName, layersView)}
      rotation={verso ? [0, Math.PI, 0] : [0, 0, 0]}
      renderOrder={verso ? 405 : 400}
      ref={objectRef as React.Ref<Mesh> | undefined}
      material={foilMaterialMesh}
      geometry={boxBufferGeometry}
    ></mesh>
  );
}
export default Foil;
