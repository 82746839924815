export const simpleNormalMapShader = `
varying vec2 vUv;
uniform sampler2D texture0; // heightMap
uniform sampler2D texture1; // blurredHeightMap
uniform vec4 vSizeTexture;

void main() {
    vec2	vUv = vUv; // + 0.5 * vSizeTexture.zw;

    float s0 = texture2D( texture0, vUv.xy ).r;
    float s1 = texture2D( texture0, vec2( vUv.x - vSizeTexture.z, vUv.y ) ).r;
    float s2 = texture2D( texture0, vec2( vUv.x, vUv.y - vSizeTexture.w ) ).r;
    float s3 = texture2D( texture0, vec2( vUv.x + vSizeTexture.z, vUv.y ) ).r;
    float s4 = texture2D( texture0, vec2( vUv.x, vUv.y + vSizeTexture.w ) ).r;

    vec3 v1 = vec3( -1.0, 0, s1 - s0 );
    vec3 v2 = vec3( 0, -1.0, s2 - s0 );
    vec3 v3 = vec3( 1.0, 0, s3 - s0 );
    vec3 v4 = vec3( 0, 1.0, s4 - s0 );
    vec3 n1 = normalize(cross( v1, v2 ));
    vec3 n2 = normalize(cross( v2, v3 ));
    vec3 n3 = normalize(cross( v3, v4 ));
    vec3 n4 = normalize(cross( v4, v1 ));
    vec3 n = n1 + n2 + n3 + n4;
    n.xy *= 0.5;
    n = normalize(n);
    gl_FragColor = vec4( ( -n.x + 1.0 ) / 2.0, ( -n.y + 1.0 ) / 2.0, ( n.z + 1.0 ) / 2.0, texture2D( texture1, vUv.xy ).r );
}`;

export const normalMapShader = `
varying vec2 vUv;
uniform sampler2D texture0; // heightMap
uniform sampler2D texture1; // blurredHeightMap
uniform vec4 vSizeTexture;

void main() {
    
    vec2	vUv = vUv; // + 0.5 * vSizeTexture.zw;   
       
        
        float x = vSizeTexture.z;
        float y = vSizeTexture.w;
        
        // |-1  0  1|
        // |-2  0  2| 
        // |-1  0  1|
        
        float gX = 0.0;
        gX += -1.0 * texture2D(texture1, vUv + vec2(-x, -y)).r;
        gX += -2.0 * texture2D(texture1, vUv + vec2(-x,  0)).r;
        gX += -1.0 * texture2D(texture1, vUv + vec2(-x, +y)).r;
        gX += +1.0 * texture2D(texture1, vUv + vec2(+x, -y)).r;
        gX += +2.0 * texture2D(texture1, vUv + vec2(+x,  0)).r;
        gX += +1.0 * texture2D(texture1, vUv + vec2(+x, +y)).r;
        
        // |-1 -2 -1|
        // | 0  0  0| 
        // | 1  2  1|
        
        float gY = 0.0;
        gY += -1.0 * texture2D(texture1, vUv + vec2(-x, -y)).r;
        gY += -2.0 * texture2D(texture1, vUv + vec2( 0, -y)).r;
        gY += -1.0 * texture2D(texture1, vUv + vec2(+x, -y)).r;
        gY += +1.0 * texture2D(texture1, vUv + vec2(-x, +y)).r;
        gY += +2.0 * texture2D(texture1, vUv + vec2( 0, +y)).r;
        gY += +1.0 * texture2D(texture1, vUv + vec2(+x, +y)).r;
        
        vec3 n = normalize(vec3(-gX, gY, 1.0));
 
    gl_FragColor = vec4( ( -n.x + 1.0 ) / 2.0, ( -n.y + 1.0 ) / 2.0, ( n.z + 1.0 ) / 2.0, texture2D( texture1, vUv.xy ).r );
}`;
