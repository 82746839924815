import { isMobile } from "react-device-detect";
import {
  CubeReflectionMapping,
  LinearFilter,
  LinearMipMapLinearFilter,
  PlaneBufferGeometry,
  RepeatWrapping,
  Texture,
  TextureLoader,
  Vector2,
  WebGLRenderer,
  WebGLRenderTarget,
} from "three";
import {
  DesignPage,
  FaceGeometry,
  FoilColorName,
  FOIL_COLORS_NAMES,
  isCustomizedPackaging,
  LayerView,
  Product,
  resolvePackagingImageURL,
} from "../Domain";
import { blurShaderHorizontal, blurShaderVertical } from "./blurShader";
import { heightMapCombineShader } from "./NormalMapping/heightMapCombineShader";
import { initRenderTargetTexture } from "./NormalMapping/initRenderTargetTexture";
import { initRenderTargetTextureSilhouette } from "./NormalMapping/initRenderTargetTextureSilhouette";
import { normalMapShader } from "./normalMapShader";
import { generatedSilhouetteMappingTexture } from "./Shaders/generateSilhouetteMappingTexture";

// TODO: rename to plain texture cache
export type PackagingTextureCache = {
  globalTextures: GlobalTextureMap;
  recto: PageMap;
  verso?: PageMap;
};

export type GlobalTextureMap = {
  black: Texture;
  white: Texture;
  paper?: Texture;
  sampNoise: Texture;
  normalNoise: Texture;
  metallicNormalNoise: Texture;
  foilNormalNoise: Texture;
  envMap?: Texture;
  envMapBlured?: Texture;
  mask?: Texture;
};

export type PageMap = {
  color?: Texture;
  foils?: { [foilName in FoilColorName]: Texture | undefined };
  holoFoil?: Texture;
  varnish?: Texture;
};

/**
 * Loads an image into a three.js Texture object
 * @param loader Function that loads the texture image file from a URL or a file path
 *
 * TODO: rename to initTextureCache
 */
export async function initPackagingTextureCache(
  product: Product,
  active360Background: string | undefined,
  loader = loadTexture
): Promise<PackagingTextureCache | undefined> {
  if (isCustomizedPackaging(product)) {
    const [
      black,
      white,
      paper,
      sampNoise,
      normalNoise,
      metallicNormalNoise,
      foilNormalNoise,
      mask,
    ] = await Promise.all([
      loadBlackTexture(loader),
      loadWhiteTexture(loader),
      loadPaperTexture(loader, product.paper?.layer.url, true),
      loader(`${process.env.PUBLIC_URL}/papers/sampNoise2.png`),
      loader(`${process.env.PUBLIC_URL}/testNormal.jpg`),
      loader(`${process.env.PUBLIC_URL}/papers/brushedMetallicNormal.png`),
      loader(`${process.env.PUBLIC_URL}/foilNormalNoise.jpg`),
      // Disable mask for packagings (not ready yet)
      product.useMask
        ? loadPackagingTexture(loader, product, product.design.mask?.url, true)
        : undefined,
    ]);
    const envMap = await loadEnvMapTexture(loader, active360Background, false);
    const envMapBlured = await loadEnvMapTexture(
      loader,
      active360Background,
      true
    );

    const rectoTextures = await loadPageTextures(
      loader,
      product,
      product.design.recto
    );
    const versoTexutres =
      product.design.verso &&
      (await loadPageTextures(loader, product, product.design.verso));

    if (!black) {
      throw new Error("could not load black texture");
    }
    if (!white) {
      throw new Error("could not load white texture");
    }
    if (!sampNoise) {
      throw new Error("could not load sampNoise texture");
    }
    if (!normalNoise) {
      throw new Error("could not load normalNoise texture");
    }
    if (!metallicNormalNoise) {
      throw new Error("could not load metallicNormalNoise texture");
    }
    if (!foilNormalNoise) {
      throw new Error("could not load foilNormalNoise texture");
    }

    sampNoise.wrapS = RepeatWrapping;
    sampNoise.wrapT = RepeatWrapping;

    normalNoise.wrapS = RepeatWrapping;
    normalNoise.wrapT = RepeatWrapping;
    normalNoise.generateMipmaps = true;

    metallicNormalNoise.wrapS = RepeatWrapping;
    metallicNormalNoise.wrapT = RepeatWrapping;
    metallicNormalNoise.generateMipmaps = true;

    foilNormalNoise.wrapS = RepeatWrapping;
    foilNormalNoise.wrapT = RepeatWrapping;
    foilNormalNoise.generateMipmaps = true;

    const globalLayers: GlobalTextureMap = {
      black,
      white,
      paper,
      sampNoise,
      normalNoise,
      metallicNormalNoise,
      foilNormalNoise,
      envMap,
      envMapBlured,
      mask,
    };

    if (globalLayers.paper) {
      globalLayers.paper.wrapS = RepeatWrapping;
      globalLayers.paper.wrapT = RepeatWrapping;
    }

    if (envMap) {
      envMap.mapping = CubeReflectionMapping;
    }

    if (envMapBlured) {
      envMapBlured.mapping = CubeReflectionMapping;
    }

    return {
      globalTextures: globalLayers,
      recto: rectoTextures,
      verso: versoTexutres,
    };
  } else {
    return undefined;
  }
}

async function loadPageTextures(
  loader: (url: string) => Promise<Texture | undefined>,
  product: Product,
  designPage: DesignPage
): Promise<PageMap> {
  const [
    color,
    goldFoil,
    silverFoil,
    copperFoil,
    redFoil,
    greenFoil,
    blueFoil,
    pinkFoil,
    orangeFoil,
    blackFoil,
    holoFoil,
    varnish,
  ] = await Promise.all(
    ["color", ...FOIL_COLORS_NAMES, "holoFoil", "varnish"].map((layerName) =>
      loadPackagingTexture(
        loader,
        product,
        isMobile
          ? designPage[layerName]?.layer.url_SD
          : designPage[layerName]?.layer.url
      )
    )
  );
  return {
    color,
    foils: {
      goldFoil,
      silverFoil,
      copperFoil,
      redFoil,
      greenFoil,
      blueFoil,
      pinkFoil,
      orangeFoil,
      blackFoil,
    },
    holoFoil,
    varnish,
  };
}

export function computeNormalMapDesignMap(
  gl: WebGLRenderer,
  textureCache: PackagingTextureCache,
  pageMap: PageMap,
  useBluredHeightMap: boolean,
  layersView?: LayerView[],
  varnishLevel?: number,
  mask?: Texture
): {
  normalMap: WebGLRenderTarget | undefined;
  silhouetteMap: WebGLRenderTarget | undefined;
} {
  const { black } = textureCache.globalTextures;
  const foilTextures =
    pageMap.foils && Object.values(pageMap.foils).map((foil) => foil ?? black);

  const textures = [pageMap.varnish ?? black];

  const image = textureCache.recto.color?.image as HTMLImageElement | undefined;

  const plane = new PlaneBufferGeometry(image?.width, image?.height);

  const heightMap =
    textures &&
    initRenderTargetTexture(
      gl,
      foilTextures && textures.concat(foilTextures),
      heightMapCombineShader,
      plane,
      textureCache.recto.color,
      layersView,
      varnishLevel
    );

  const heightMapBluredHorizontal = useBluredHeightMap
    ? initRenderTargetTexture(
        gl,
        heightMap ? [heightMap?.texture] : undefined,
        blurShaderHorizontal,
        plane,
        textureCache.recto.color
      )
    : heightMap;

  const heightMapBluredVertical = useBluredHeightMap
    ? initRenderTargetTexture(
        gl,
        heightMapBluredHorizontal
          ? [heightMapBluredHorizontal?.texture]
          : undefined,
        blurShaderVertical,
        plane,
        textureCache.recto.color
      )
    : heightMapBluredHorizontal;

  const normalMap = initRenderTargetTexture(
    gl,
    heightMapBluredVertical && heightMap
      ? [heightMap.texture, heightMapBluredVertical.texture]
      : undefined,
    normalMapShader,
    plane,
    textureCache.recto.color
  );

  const silhouetteMap = initRenderTargetTextureSilhouette(
    gl,
    normalMap?.texture ?? undefined,
    generatedSilhouetteMappingTexture,
    plane,
    mask
  );

  plane.dispose();
  heightMap?.dispose();
  heightMapBluredVertical?.dispose();
  heightMapBluredHorizontal?.dispose();

  return { normalMap: normalMap, silhouetteMap: silhouetteMap };
}

export type TexturePromiseLoader = (
  url: string,
  nomipmap?: boolean
) => Promise<Texture | undefined>;

function loadTexture(
  url: string,
  nomipmap?: boolean
): Promise<Texture | undefined> {
  const loader = new TextureLoader();
  return new Promise<Texture | undefined>((resolve, reject) => {
    loader.load(
      url.toString(),
      (texture) => {
        if (nomipmap) texture.minFilter = LinearFilter;
        else texture.minFilter = LinearMipMapLinearFilter;
        resolve(texture);
      },
      () => {
        // No-op
      },
      reject
    );
  });
}

function loadBlackTexture(loader: TexturePromiseLoader) {
  return loader(`${process.env.PUBLIC_URL}/black.png`);
}
function loadWhiteTexture(loader: TexturePromiseLoader) {
  return loader(
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAIAAAAmkwkpAAAC6npUWHRSYXcgcHJvZmlsZSB0eXBlIGV4aWYAAHja7ZdtkiMnDIb/c4ocAUkIiePQfFTlBjl+Xuhujz0zSXZr90+q3FQDFiAJPQLbYfz15wx/4KGSOSQ1zyXniCeVVLii4/F8yq4ppl2fT7paepWHxwBDJGjl/Gj1ml8h148Ftw06XuXBrxH2S9Ft+VIoyzKj05+dhJxPOV2OhDLOTi5uz64efLbt9tg/3tLPfdCldX0Oz4JkiFJXGBLmISRx1+n0QM634i2oIcc8Qll9CXvg3hIC8rK9u43xOUCvwb964XP0H71Pwed6yeVTLPNNLX8/QPp98HeInwzLwyN+HTC5VX0N8pzd5xzn7mrKiGi+MiqGOzprDSYeCLnsZRnF8Cr6tktB8VhjA/IeWzxQGhViUJmBEnWqNGnstlGDi4kHG1rmxrJlLsaFmyxOaRWabKDXxcGy8QgiEPPDF9p2y7bXyGG5E6YyQRlhyT+W8G+DP1PCnG2FiKI/YgW/eOU13FjkVo1ZAELz4qY7wHe58Men/EGqgqDuMDs2WONxqjiUPnJLNmfBPEV7HiEK1i8FCBFsK5xB2ieKmUQpUzRmI0IcHYAqPGecjQMESJU7nOQkgvvI2HnZxhqjPZeVMy8x7iaAUMliYIPzBVgpKfLHkiOHqoomVc1q6kGL1iw5Zc05W16XXDWxZGrZzNyKVRdPrp7d3L14LVwEd6CWXKx4KaVWDhWGKnRVzK+QHHzIkQ498mGHH+WoDenTUtOWmzVvpdXOXTquiZ67de+l10Fh4KYYaejIw4aPMupErk2ZaerM06bPMuuD2kX1S/kJanRR401qzbMHNUiD2a2C1nWiixmIcSIQt0UACc2LWXRKiRe5xSwWxqFQhpO62IROixgQpkGskx7sPsj9ELeg/kPc+L/IhYXud5ALQPeV2zfU+vqea5vYeQpXTKPg9GFOZQ94Y0T1q+1b0VvRW9Fb0VvRW9Fb0f9fkUz8eMC/y/A3m0aduIzTtC0AAAGFaUNDUElDQyBwcm9maWxlAAB4nH2RPUjDUBSFT1OlKlURO4g4ZKhOFkRFHKWKRbBQ2gqtOpi89A+aNCQpLo6Ca8HBn8Wqg4uzrg6ugiD4A+Lk6KToIiXelxRaxPjg8j7Oe+dw332AUC8z1eyYAFTNMpKxqJjJroqBV3SjjyqAAYmZejy1mIbn+rqHj+93EZ7lfe/P1avkTAb4ROI5phsW8QbxzKalc94nDrGipBCfE48b1CDxI9dll984FxwWeGbISCfniUPEYqGN5TZmRUMlniYOK6pG+ULGZYXzFme1XGXNPvkLgzltJcV1qhHEsIQ4EhAho4oSyrAQoV0jxUSSzqMe/mHHnyCXTK4SGDkWUIEKyfGD/8Hv2Zr5qUk3KRgFOl9s+2MUCOwCjZptfx/bduME8D8DV1rLX6kDs5+k11pa+Ajo3wYurluavAdc7gBDT7pkSI7kpxLyeeD9jL4pCwzeAj1r7tya5zh9ANI0q+Ub4OAQGCtQ9rrHu7va5/bvneb8fgAWVnKCwNKpwgAAAAlwSFlzAAASdAAAEnQB3mYfeAAAAAd0SU1FB+QJDgkNA4+48g4AAAAUSURBVAjXY/z//z8DDDAxIAHcHACWbgMF2hiSxQAAAABJRU5ErkJggg=="
  );
}

// TODO: rename to loadProductTexture()
async function loadPackagingTexture(
  loader: TexturePromiseLoader,
  packaging: Product,
  imagePathOrURL: string | undefined,
  nomipmap?: boolean
) {
  if (imagePathOrURL === undefined) {
    return undefined;
  }
  const imageURL = resolvePackagingImageURL(packaging.name, imagePathOrURL);
  return loader(imageURL, nomipmap);
}

function loadPaperTexture(
  loader: TexturePromiseLoader,
  paperName: string | undefined,
  nomipmap?: boolean
) {
  if (paperName === undefined) {
    return undefined;
  }
  const paperURL = `${process.env.PUBLIC_URL}/papers/${paperName}`;
  return loader(paperURL, nomipmap);
}

function loadEnvMapTexture(
  loader: TexturePromiseLoader,
  active360Background: string | undefined,
  blured: boolean
) {
  if (active360Background === undefined) {
    return blured
      ? loader(`${process.env.PUBLIC_URL}/envMap/defaultEnvMap_blured.png`)
      : loader(`${process.env.PUBLIC_URL}/envMap/defaultEnvMap.png`);
  }
  const envMapURL = blured
    ? `${process.env.PUBLIC_URL}/envMap/${active360Background}_blured.jpg`
    : `${process.env.PUBLIC_URL}/envMap/${active360Background}.jpg`;
  return loader(envMapURL);
}

export function computeFaceUVs(
  product: Product,
  faceName: string
): { repeat: Vector2; offset: Vector2 } {
  const { width: packWidth, height: packHeight } = product.dieline.dimensions;
  const faceGeometry: FaceGeometry = product.dieline.faceGeometries[faceName];
  const offset = new Vector2(
    faceGeometry.x / packWidth,
    (packHeight - faceGeometry.y - faceGeometry.height) / packHeight
  );
  const repeat = new Vector2(
    faceGeometry.width / packWidth,
    faceGeometry.height / packHeight
  );
  return { repeat, offset };
}
