import { AutoFold } from "..";
import { Dieline } from "../Dieline";
import { LayerEntity } from "../LayerEntity";
import { BlankProduct, CustomizedProduct, Product } from "../Product";
import SmartLabel from "../SmartLabel";
import { DesignPage, ProductDesign } from "./ProductDesign";

export type SmartPackaging = {
  packaging: Packaging;
  smartlabel?: SmartLabel[];
};

export class Packaging implements Product {
  public readonly rotation = [Math.PI / 2, 0, 0] as [number, number, number];
  useMask = false;

  name?: string;
  dieline: Dieline;
  fold?: AutoFold;
  paper?: { layer: LayerEntity };
  design?: ProductDesign;
  error?: string;
  version?: number;

  constructor(props: {
    name?: string;
    paper?: { layer: LayerEntity };
    dieline: Dieline;
    fold?: AutoFold;
    sceneBackgroundColor?: string;
    design?: ProductDesign | DesignPage;
    initial3dRotation?: [number, number, number];
    useMask?: boolean;
    error?: string;
    version?: number;
  }) {
    this.name = props.name;
    this.dieline = props.dieline;
    this.fold = props.fold;
    this.paper = props.paper;
    if (props.design) {
      this.design =
        "recto" in props.design ? props.design : { recto: props.design };
    }
    if (props.initial3dRotation) {
      this.rotation = props.initial3dRotation;
    }
    this.useMask = props.useMask ?? this.useMask;
    this.error = props.error;
    this.version = props.version;
  }
}

export class BlankPackaging extends Packaging implements BlankProduct {
  design = undefined;

  constructor({
    dieline,
    paper,
    initial3dRotation,
    useMask,
  }: {
    dieline: Dieline;
    paper?: { layer: LayerEntity };
    initial3dRotation?: [number, number, number];
    useMask?: boolean;
  }) {
    super({ dieline, paper, design: undefined, initial3dRotation, useMask });
  }
}

export class CustomizedPackaging
  extends Packaging
  implements CustomizedProduct {
  design: ProductDesign;

  constructor(props: {
    name?: string;
    paper?: { layer: LayerEntity };
    dieline: Dieline;
    sceneBackgroundColor?: string;
    design: ProductDesign | DesignPage;
    initial3dRotation?: [number, number, number];
  }) {
    super(props);
    this.design =
      "recto" in props.design ? props.design : { recto: props.design };
  }
}
