import React, {
  Dispatch,
  forwardRef,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useThree } from "@react-three/fiber";
import {
  BackSide,
  Box3,
  CubeReflectionMapping,
  Group,
  MeshBasicMaterial,
  SphereBufferGeometry,
  Texture,
  TextureLoader,
  Vector3,
  WebGLRenderTarget,
} from "three";
import {
  Coords3D,
  Dieline,
  findIntersections,
  SmartLabelIntersection,
} from "../Domain";
import { Annotations } from "../Domain/Annotations";
import { BottleObject } from "../Domain/Bottle";
import { FoilMapping } from "../Domain/Foil";
import { Bottle } from "./Bottle";
import { VarnishMaterialProps } from "./Layers/Varnish";
import { LedDisplayProps } from "./Leds";
import { PackagingFace3D } from "./PackagingFace3D";
import {
  computeNormalMapDesignMap,
  initPackagingTextureCache,
  PackagingTextureCache,
} from "./PackagingTextureCache";
import { CameraProps, CreationState, PaperMaterial } from "./PreviewScene";
import { FoldingFace } from "../Domain/FoldingTree/auto-fold";
import { Box } from "./Box";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

const AXES_HELPER_SIZE = 300;

type FoldingPackagingProps = {
  creationState: CreationState;
  dieline: Dieline;
  fold:
    | {
        Face: FoldingFace;
      }[]
    | undefined;
  enableAxesHelper: boolean;
  rotationFactor: number;
  opacity: number;
  ledProps: LedDisplayProps;
  folded: boolean;
  updateCamera: Dispatch<CameraProps>;
  onNewAnnotation?: React.Dispatch<Annotations>;
  cancelNewAnnotation?: React.Dispatch<void>;
  varnishMaterialProps: VarnishMaterialProps;
  useBluredHeightMap: boolean;
  displayedMap: string;
  activePaper: PaperMaterial;
  annotationMode: "display" | "pendingPlacement" | "pendingValidation";
  handleDeleteEvent?: React.Dispatch<Annotations>;
  onSelect3DAnnotation?: React.Dispatch<Annotations>;
  active360Background: string | undefined;
  foilMapping: FoilMapping;
  foilDiffuseColor: string;
  showOnBottle: boolean;
  silhouetteMappingMode: boolean;
  selectedBottle?: BottleObject;
  labelPosition?: number;
  normalScale: number;
  parallaxScale: number;
  varnishLevel: number;
  updateControlsTarget: () => void;
  metalness: number;
  roughness: number;
  envMapIntensity: number;
  metalPaperColor: string;
  angleMode: boolean;
  anglePreview3D?: boolean;
  selectedFold: number[] | undefined;
  newRotation?: {
    faceName: string;
    rotation: number[];
  }[];
  hovered?: number;
  autoRotate?: boolean;
  setAutoRotate?: React.Dispatch<boolean>;
  controls: React.RefObject<OrbitControls>;
};
export const Packaging3D = forwardRef<Group | undefined, FoldingPackagingProps>(
  (
    {
      creationState,
      dieline: { foldingTree },
      fold,
      enableAxesHelper,
      rotationFactor,
      opacity,
      ledProps,
      folded,
      updateCamera,
      onNewAnnotation,
      cancelNewAnnotation,
      varnishMaterialProps,
      useBluredHeightMap,
      displayedMap,
      activePaper,
      annotationMode,
      handleDeleteEvent,
      onSelect3DAnnotation,
      active360Background,
      foilMapping,
      foilDiffuseColor,
      showOnBottle,
      silhouetteMappingMode,
      selectedBottle,
      labelPosition,
      normalScale,
      parallaxScale,
      varnishLevel,
      updateControlsTarget,
      metalness,
      roughness,
      metalPaperColor,
      envMapIntensity,
      angleMode,
      anglePreview3D,
      selectedFold,
      newRotation,
      hovered,
      autoRotate,
      setAutoRotate,
      controls,
    }: FoldingPackagingProps,
    ref
  ) => {
    const smartLabelIntersections: SmartLabelIntersection[] = useMemo(
      () =>
        (creationState.smartLabels ?? []).flatMap((smartLabel) =>
          findIntersections(smartLabel, creationState.packaging.dieline)
        ),
      [creationState.packaging.dieline, creationState.smartLabels]
    );

    // Load design image into a shared packaging texture

    const [textureCache, setTextureCache] = useState<PackagingTextureCache>();
    const [normalMaps, setNormalMaps] = useState<
      { recto?: WebGLRenderTarget; verso?: WebGLRenderTarget } | undefined
    >();
    const [silhouetteMaps, setSilhouetteMaps] = useState<
      { recto?: WebGLRenderTarget; verso?: WebGLRenderTarget } | undefined
    >();

    const { gl } = useThree();

    const groupsRef = ref as React.MutableRefObject<Group>;

    const cameraHasBeenPositionned = useRef<boolean>(false);

    const resetCamera = useCallback((resetPosition = false) => {
      if (groupsRef.current) {
        // Compute bounding box using Box3
        // Inspired by setContent() method in three-gltf-viewer:
        //   https://github.com/donmccurdy/three-gltf-viewer/blob/master/src/viewer.js
        const box = new Box3().setFromObject(groupsRef.current);

        // Compute camera target and position
        const center = box.getCenter(new Vector3());

        const size = groupsRef.current
          .localToWorld(box.getSize(new Vector3()))
          .length();
        const position: Coords3D = [
          center.x + size / 2.0,
          center.y + size * 0.2,
          center.z + size * 0.6,
        ];

        updateCamera({
          position:
            !cameraHasBeenPositionned.current || resetPosition
              ? position
              : undefined,
          packagingSize: size,
          target: center.toArray() as Coords3D,
        });
        cameraHasBeenPositionned.current = true;
      }
    }, []);

    useEffect(() => {
      resetCamera(true);
    }, [showOnBottle]);

    useEffect(() => {
      if (autoRotate) {
        resetCamera(true);
        setAutoRotate && setAutoRotate(false);
        if (controls.current) controls.current.autoRotate = true;
      }
    }, [autoRotate]);

    const packagingSize = useMemo(() => {
      if (groupsRef.current) {
        const box = new Box3().setFromObject(groupsRef.current);
        return groupsRef.current
          .localToWorld(box.getSize(new Vector3()))
          .length();
      } else return 1;
    }, [groupsRef.current]);

    // Maybe extract this effect into a custom hook for texture cache loading/disposing
    useEffect(() => {
      async function loadAndCropLayers() {
        // Unload previous textures as soon as we switch to a different packaging
        // (while the next packaging textures are being loaded)
        if (creationState.packaging.paper) {
          creationState.packaging.paper.layer.url = activePaper.name + ".png";
        }
        setTextureCache(undefined);
        const newTextureCache = await initPackagingTextureCache(
          creationState.packaging,
          active360Background
        );

        if (newTextureCache && !angleMode) {
          const newNormalAndSilhouette = {
            recto: computeNormalMapDesignMap(
              gl,
              newTextureCache,
              newTextureCache.recto,
              useBluredHeightMap,
              creationState.layersView,
              varnishLevel,
              newTextureCache.globalTextures.mask ??
                newTextureCache.globalTextures.white
            ),
            verso:
              newTextureCache.verso &&
              computeNormalMapDesignMap(
                gl,
                newTextureCache,
                newTextureCache.verso,
                useBluredHeightMap,
                creationState.layersView,
                varnishLevel,
                newTextureCache.globalTextures.mask ??
                  newTextureCache.globalTextures.white
              ),
          };
          setNormalMaps({
            recto: newNormalAndSilhouette.recto.normalMap,
            verso: newNormalAndSilhouette.verso?.normalMap,
          });
          setSilhouetteMaps({
            recto: newNormalAndSilhouette.recto.silhouetteMap,
            verso: newNormalAndSilhouette.verso?.silhouetteMap,
          });
        }
        setTextureCache(newTextureCache);
      }
      loadAndCropLayers();
    }, [
      creationState.packaging,
      useBluredHeightMap,
      activePaper,
      active360Background,
    ]);

    useEffect(() => {
      async function recompute() {
        if (textureCache) {
          normalMaps?.recto?.dispose();
          normalMaps?.verso?.dispose();
          silhouetteMaps?.recto?.dispose();
          silhouetteMaps?.verso?.dispose();
          const newNormalAndSilhouette = {
            recto: computeNormalMapDesignMap(
              gl,
              textureCache,
              textureCache.recto,
              useBluredHeightMap,
              creationState.layersView,
              varnishLevel,
              textureCache.globalTextures.mask ??
                textureCache.globalTextures.white
            ),
            verso:
              textureCache.verso &&
              computeNormalMapDesignMap(
                gl,
                textureCache,
                textureCache.verso,
                useBluredHeightMap,
                creationState.layersView,
                varnishLevel,
                textureCache.globalTextures.mask ??
                  textureCache.globalTextures.white
              ),
          };
          setNormalMaps({
            recto: newNormalAndSilhouette.recto.normalMap,
            verso: newNormalAndSilhouette?.verso?.normalMap,
          });
          setSilhouetteMaps({
            recto: newNormalAndSilhouette.recto.silhouetteMap,
            verso: newNormalAndSilhouette?.verso?.silhouetteMap,
          });

          Object.values(textureCache.recto).forEach((tex) => {
            if (tex instanceof Texture) {
              tex.dispose();
            } else {
              if (tex) {
                Object.values(tex).forEach((t) => t?.dispose());
              }
            }
          });
          if (textureCache.verso) {
            Object.values(textureCache.verso).forEach((tex) => {
              if (tex instanceof Texture) {
                tex.dispose();
              } else {
                if (tex) {
                  Object.values(tex).forEach((t) => t?.dispose());
                }
              }
            });
          }
        }
      }
      !angleMode && recompute();
    }, [creationState.layersView, varnishLevel]);

    const defaultBottleEnvMap = useMemo(() => {
      const map = new TextureLoader().load(
        `${process.env.PUBLIC_URL}/envMap/defaultEnvMapBluredMore.png`
      );
      map.mapping = CubeReflectionMapping;
      return map;
    }, []);

    const envMap: Texture | undefined = useMemo(
      () => textureCache?.globalTextures.envMap,
      [textureCache]
    );
    const envMapBlured: Texture | undefined = useMemo(
      () => textureCache?.globalTextures.envMapBlured,
      [textureCache]
    );

    // Skybox
    const { width, height } = creationState.packaging;

    const realDimension =
      width && height
        ? {
            width,
            height,
          }
        : undefined;

    const envMapGeometry = useMemo(
      () => new SphereBufferGeometry(100000, 32, 32),
      []
    );
    const envMapMaterial = useMemo(
      () => new MeshBasicMaterial({ side: BackSide, map: envMap }),
      [envMap]
    );

    return (
      <>
        {
          // Code to display skybox
          active360Background && envMap && (
            <mesh
              material={envMapMaterial}
              geometry={envMapGeometry}
              renderOrder={2000}
              position={[0, 0, 0]}
              rotation={[0, Math.PI, 0]}
              scale={[-1, 1, 1]}
            />
          )
        }
        {creationState.packaging.dieline.name === "flat" && showOnBottle ? (
          <Suspense fallback={"...Chargement"}>
            <group
              ref={ref as React.Ref<Group> | undefined}
              rotation={creationState.packaging.rotation}
            >
              {selectedBottle && (
                <Bottle
                  textureCache={textureCache}
                  normalMaps={normalMaps}
                  creationState={creationState}
                  layersView={creationState.layersView}
                  activePaper={activePaper}
                  updateCamera={updateCamera}
                  foilMapping={foilMapping}
                  selectedBottle={selectedBottle}
                  envMap={
                    active360Background
                      ? textureCache?.globalTextures.envMapBlured
                      : defaultBottleEnvMap
                  }
                  realDimension={realDimension}
                  labelPosition={labelPosition}
                />
              )}
            </group>
          </Suspense>
        ) : (
          <group
            ref={ref as React.Ref<Group> | undefined}
            rotation={creationState.packaging.rotation}
          >
            {enableAxesHelper && <axesHelper args={[AXES_HELPER_SIZE]} />}

            {angleMode && <Box dieline={creationState.packaging.dieline} />}
            {
              <PackagingFace3D
                onNewAnnotation={onNewAnnotation}
                node={foldingTree}
                fold={fold}
                creationState={creationState}
                origin={[0, 0, 0]}
                enableAxesHelper={enableAxesHelper}
                rotationFactor={rotationFactor}
                opacity={opacity}
                ledProps={ledProps}
                smartLabelIntersections={smartLabelIntersections}
                folded={folded}
                textureCache={textureCache}
                normalMaps={normalMaps}
                envMap={envMapBlured}
                varnishMaterialProps={varnishMaterialProps}
                displayedMap={displayedMap}
                cancelNewAnnotation={cancelNewAnnotation}
                activePaperName={activePaper}
                annotationMode={annotationMode}
                handleDeleteEvent={handleDeleteEvent}
                onSelect3DAnnotation={onSelect3DAnnotation}
                foilMapping={foilMapping}
                foilDiffuseColor={foilDiffuseColor}
                normalScale={normalScale}
                parallaxScale={parallaxScale}
                varnishLevel={varnishLevel}
                onFoldingFinished={() => resetCamera()}
                updateControlsTarget={updateControlsTarget}
                packagingSize={packagingSize}
                silhouetteMappingMode={silhouetteMappingMode}
                silhouetteMaps={silhouetteMaps}
                metalness={metalness}
                roughness={roughness}
                envMapIntensity={envMapIntensity}
                metalPaperColor={metalPaperColor}
                angleMode={angleMode}
                anglePreview3D={anglePreview3D}
                selectedFold={selectedFold}
                newRotation={newRotation}
                hovered={hovered}
              />
            }
          </group>
        )}
      </>
    );
  }
);
