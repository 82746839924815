export const blurShaderHorizontal = `

uniform sampler2D texture0;
uniform	vec4	vSizeTexture;
uniform	vec4	coeffs;
uniform	vec4	coeffs2;
varying vec2 vUv;

void main() {
vec2 vTex = vUv;// + 0.5 * vSizeTexture.zw;
const int iSize = 3;


vec4 cColor = coeffs.x * texture2D( texture0, vTex.xy );
cColor += coeffs.y * (texture2D( texture0, vTex.xy + vec2(1.0* vSizeTexture.z, 0.0)) + texture2D( texture0, vTex.xy - vec2(1.0* vSizeTexture.z, 0.0)));
cColor += coeffs.z * (texture2D( texture0, vTex.xy + vec2(2.0* vSizeTexture.z, 0.0)) + texture2D( texture0, vTex.xy - vec2(2.0* vSizeTexture.z, 0.0)));
cColor += coeffs.w * (texture2D( texture0, vTex.xy + vec2(3.0* vSizeTexture.z, 0.0)) + texture2D( texture0, vTex.xy - vec2(3.0* vSizeTexture.z, 0.0)));
cColor += coeffs2.x * (texture2D( texture0, vTex.xy + vec2(4.0* vSizeTexture.z, 0.0)) + texture2D( texture0, vTex.xy - vec2(4.0* vSizeTexture.z, 0.0)));
cColor += coeffs2.y * (texture2D( texture0, vTex.xy + vec2(5.0* vSizeTexture.z, 0.0)) + texture2D( texture0, vTex.xy - vec2(5.0* vSizeTexture.z, 0.0)));
cColor += coeffs2.z * (texture2D( texture0, vTex.xy + vec2(6.0* vSizeTexture.z, 0.0)) + texture2D( texture0, vTex.xy - vec2(6.0* vSizeTexture.z, 0.0)));
cColor += coeffs2.w * (texture2D( texture0, vTex.xy + vec2(7.0* vSizeTexture.z, 0.0)) + texture2D( texture0, vTex.xy - vec2(7.0* vSizeTexture.z, 0.0)));

gl_FragColor = saturate(cColor);
}

`;
export const blurShaderVertical = `

uniform sampler2D texture0;
uniform	vec4	vSizeTexture;
uniform	vec4	coeffs;
uniform	vec4	coeffs2;
varying vec2 vUv;

void main() {
vec2 vTex = vUv;// + 0.5 * vSizeTexture.zw;
const int iSize = 3;


vec4 cColor = coeffs.x * texture2D( texture0, vTex.xy );
cColor += coeffs.y * (texture2D( texture0, vTex.xy + vec2(0.0, 1.0* vSizeTexture.z)) + texture2D( texture0, vTex.xy - vec2(0.0, 1.0* vSizeTexture.z)));
cColor += coeffs.z * (texture2D( texture0, vTex.xy + vec2(0.0, 2.0* vSizeTexture.z)) + texture2D( texture0, vTex.xy - vec2(0.0, 2.0* vSizeTexture.z)));
cColor += coeffs.w * (texture2D( texture0, vTex.xy + vec2(0.0, 3.0* vSizeTexture.z)) + texture2D( texture0, vTex.xy - vec2(0.0, 3.0* vSizeTexture.z)));
cColor += coeffs2.x * (texture2D( texture0, vTex.xy + vec2(4.0* vSizeTexture.z, 0.0)) + texture2D( texture0, vTex.xy - vec2(4.0* vSizeTexture.z, 0.0)));
cColor += coeffs2.y * (texture2D( texture0, vTex.xy + vec2(5.0* vSizeTexture.z, 0.0)) + texture2D( texture0, vTex.xy - vec2(5.0* vSizeTexture.z, 0.0)));
cColor += coeffs2.z * (texture2D( texture0, vTex.xy + vec2(6.0* vSizeTexture.z, 0.0)) + texture2D( texture0, vTex.xy - vec2(6.0* vSizeTexture.z, 0.0)));
cColor += coeffs2.w * (texture2D( texture0, vTex.xy + vec2(7.0* vSizeTexture.z, 0.0)) + texture2D( texture0, vTex.xy - vec2(7.0* vSizeTexture.z, 0.0)));

gl_FragColor = saturate(cColor);
}








// uniform sampler2D texture0;
// uniform	vec4	vSizeTexture;
// varying vec2 vUv;

// void main() {
// vec2 vTex = vUv;// + 0.5 * vSizeTexture.zw;
// vec4 cColor = texture2D( texture0, vTex.xy );
// //gl_FragColor =  cColor;
// cColor += texture2D( texture0, vTex.xy + vec2( -0.5 * vSizeTexture.z, -1.5 * vSizeTexture.w ) );
// cColor += texture2D( texture0, vTex.xy + vec2( 1.5 * vSizeTexture.z, -0.5 * vSizeTexture.w ) );
// cColor += texture2D( texture0, vTex.xy + vec2( 0.5 * vSizeTexture.z, 1.5 * vSizeTexture.w ) );
// cColor += texture2D( texture0, vTex.xy + vec2( -1.5 * vSizeTexture.z, 0.5 * vSizeTexture.w ) );
// gl_FragColor = 0.2 * cColor;

`;
