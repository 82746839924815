import React from "react";
import { animated, SpringValue } from "@react-spring/three";
import { BufferGeometry, Color, Texture, Vector2 } from "three";
import { FaceGeometry } from "../../Domain";
import { foilFragmentShader } from "../Shaders/foilFragmentShader";
import { vertexShader } from "../Shaders/vertexShader";

type HoloFoilProps = {
  geometry: BufferGeometry;
  faceGeometry: FaceGeometry;
  holoFoil: Texture | undefined;
  animatedOpacity: SpringValue<number>;
  faceName: string;
  faceUVs: { repeat: Vector2; offset: Vector2 };
  verso?: boolean;
};

const HoloFoil = ({
  geometry,
  faceGeometry,
  animatedOpacity,
  faceName,
  faceUVs,
  verso,
}: HoloFoilProps): JSX.Element => {
  const holoUniforms = {
    diffuse: {
      value: new Color(
        0.6666666666666667,
        0.6666666666666667,
        0.6666666666666667
      ),
    },
    opacity: { value: animatedOpacity },
    map: { value: null },
    uvTransform: {
      value: [
        faceUVs?.repeat.x,
        -0,
        0,
        0,
        faceUVs?.repeat.y,
        0,
        faceUVs?.offset.x,
        faceUVs?.offset.y,
        1,
      ],
    },
    uv2Transform: { value: [1, 0, 0, 0, 1, 0, 0, 0, 1] },
    alphaMap: { value: null },
    envMap: { value: null },
    flipEnvMap: { value: -1 },
    reflectivity: { value: 1 },
    refractionRatio: { value: 0.98 },
    maxMipLevel: { value: 0 },
    aoMap: { value: null },
    aoMapIntensity: { value: 1 },
    lightMap: { value: null },
    lightMapIntensity: { value: 1 },
    emissiveMap: { value: null },
    bumpMap: { value: null },
    bumpScale: { value: 1 },
    normalMap: { value: null },
    normalScale: { value: { x: 1, y: 1 } },
    roughnessMap: { value: null },
    metalnessMap: { value: null },
    ambientLightColor: {
      value: [0.7278431372549019, 0.7278431372549019, 0.7278431372549019],
      needsUpdate: true,
    },
    directionalLights: {
      value: [
        {
          direction: {
            x: -0.6569329722189172,
            y: -0.17460088517151215,
            z: 0.7334532029440893,
          },
          color: new Color(0.5, 0.5, 0.5),
        },
        {
          direction: {
            x: 0.7478311872357977,
            y: 0.14336756698608208,
            z: -0.6482239243764369,
          },
          color: new Color(0, 0, 0),
        },
      ],
      needsUpdate: true,
    },
    emissive: {
      value: new Color(
        0.7490196078431373,
        0.5215686274509804,
        0.38823529411764707
      ),
    },
    roughness: { value: 0.25 },
    metalness: { value: 0.6 },
    envMapIntensity: { value: 1 },
    transparency: { value: 1 },
    clearcoat: { value: 0 },
    clearcoatRoughness: { value: 0 },
    sheen: { value: new Color(0, 0, 0) },
    clearcoatNormalScale: { value: { x: 1, y: 1 } },
    clearcoatNormalMap: { value: null },
    clippingPlanes: { value: null, needsUpdate: false },
  };
  return (
    <mesh
      name={faceName}
      geometry={geometry}
      position={[...faceGeometry.position, 3]}
      rotation={verso ? [0, Math.PI, 0] : [0, 0, 0]}
    >
      <animated.shaderMaterial
        attach="material"
        uniforms={holoUniforms}
        vertexShader={vertexShader}
        fragmentShader={foilFragmentShader}
        transparent
      />
      )
    </mesh>
  );
};
export default HoloFoil;
