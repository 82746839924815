import { AutoFold } from ".";
import { Dieline } from "./Dieline";
import { LayerEntity } from "./LayerEntity";
import { ProductDesign } from "./Product/ProductDesign";

export interface Product {
  // TODO: rename to staticName
  name?: string;
  dieline: Dieline;
  fold?: AutoFold;
  paper?: {
    layer: LayerEntity;
  };
  design?: ProductDesign;
  useMask: boolean;
  width?: number;
  height?: number;
  error?: string;

  // Rotation applied to the 3D object of the product in the scene
  // For instance, packagings are rotated along the x axis so that the front face does face the camera
  // (otherwise, the bottom face would face the camera as it is the reference object of the packaging space,
  // by convention)
  readonly rotation: [number, number, number];
}

export interface BlankProduct extends Product {
  design: undefined;
}

export interface CustomizedProduct extends Product {
  design: ProductDesign;
}

// TODO: rename to isCustomizedProduct()
export function isCustomizedPackaging(
  product: Product
): product is CustomizedProduct {
  return "design" in product && product.design !== undefined;
}

// TODO: rename to resolveProductImageURL()
export function resolvePackagingImageURL(
  packagingName: string | undefined,
  imagePathOrURL: string
): string {
  let imageURL: string;
  try {
    imageURL = new URL(imagePathOrURL).toString();
  } catch (_) {
    if (packagingName) {
      imageURL = `${process.env.PUBLIC_URL}/${packagingName}/${imagePathOrURL}`;
    } else {
      throw new Error(
        "Image path found on packaging without a name - Cannot resolve URL"
      );
    }
  }
  return imageURL;
}
