import React, { useCallback, useMemo } from "react";
import { BufferGeometry, Vector3 } from "three";
import { FaceGeometry, FoldingTree, FaceGeometryMap } from "..";
import { FoldingFace } from "../Domain/FoldingTree/auto-fold";

type LineSegmentsProps = {
  geometry: BufferGeometry;
  faceGeometry: FaceGeometry;
  node: FoldingTree;
  fold:
    | {
        Face: FoldingFace;
      }[]
    | undefined;
  faceGeometries: FaceGeometryMap;
  selectedFold?: number[];
  anglePreview3D?: boolean;
  size: number;
  hovered?: number;
};

const LineSegments = ({
  //faceGeometry,
  node,
  fold,
  //faceGeometries,
  selectedFold,
  anglePreview3D,
  hovered,
}: //size,
LineSegmentsProps): JSX.Element => {
  const list = fold?.filter((f) => f.Face["Face"] === Number(node.faceName));

  const parentFace = list?.[0].Face["Parent face"];

  const points = useMemo(() => {
    const array: Vector3[] = [];
    if (list && parentFace !== -1) {
      list[0].Face["Segment"]?.forEach((p) =>
        array.push(new Vector3(p.Point.X, -p.Point.Y, 0))
      );
      return array;
    } else return undefined;
  }, [list, parentFace]);

  const onUpdate = useCallback(
    (self: BufferGeometry) => {
      points && self.setFromPoints(points);
    },
    [points]
  );

  return (
    <group position={[0, 0, 0.1]}>
      {points && (
        <lineSegments key={node.faceName} name={node.faceName}>
          <bufferGeometry attach="geometry" onUpdate={onUpdate} />
          <lineBasicMaterial
            attach="material"
            color={
              selectedFold?.includes(parseInt(node.faceName)) ||
              (hovered ? hovered === parseInt(node.faceName) : undefined)
                ? 0x7172fc
                : 0xffe200
            }
          />
        </lineSegments>
      )}

      {!anglePreview3D &&
        selectedFold?.includes(parseInt(node.faceName)) &&
        points && (
          <>
            <mesh position={points[0]}>
              <circleBufferGeometry attach="geometry" args={[5, 32]} />
              <meshBasicMaterial attach="material" color={0x7172fc} />
            </mesh>
            <mesh position={points[1]}>
              <circleBufferGeometry attach="geometry" args={[5, 32]} />
              <meshBasicMaterial attach="material" color={0x7172fc} />
            </mesh>
          </>
        )}
    </group>
  );
};
export default LineSegments;
