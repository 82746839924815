import React, { useEffect, useMemo, useRef } from "react";
import { SpringValue } from "@react-spring/three";
import { useFrame, useThree } from "@react-three/fiber";
import {
  BufferGeometry,
  Color,
  Material,
  Matrix4,
  Mesh,
  ShaderMaterial,
  Texture,
  Vector2,
  Vector3,
  Vector4,
} from "three";
import { FaceGeometry, layerIsEnabled, LayerView } from "../../Domain";
import { BoxSilhouetteGeometry } from "../Shaders/boxSilhouetteGeometry";
import { silhouetteMappingShader } from "../Shaders/silhouetteMappingShader";
import { silhouetteVertexShader } from "../Shaders/silhouetteVertexShader";
import { varnishFragmentShader } from "../Shaders/varnishFragmentShader";
import { varnishVertexShader } from "../Shaders/varnishVertexShader";

export type VarnishMaterialProps = {
  metalness?: number;
  roughness?: number;
  reflectivity?: number;
  envMapIntensity?: number;
  normalScaleFactor?: number;
  vecXNormalScale?: number;
  vecYNormalScale?: number;
  displayedMap?: string;
};

type VarnishProps = {
  normalNoise?: Texture;
  geometry: BufferGeometry;
  faceGeometry: FaceGeometry;
  normalMap: Texture | undefined;
  silhouetteMap: Texture | undefined;
  envMap?: Texture;
  varnish: Texture | undefined;
  animatedOpacity: SpringValue<number>;
  faceName: string;
  normalScale: Vector2;
  parallaxScale: number;
  parallaxMinLayers: number;
  parallaxMaxLayers: number;
  layersView: LayerView[];
  faceUVs: { repeat: Vector2; offset: Vector2 };
  verso?: boolean;
  mask?: Texture;
  silhouetteMappingMode: boolean;
  varnishLevel: number;
  boxBufferGeometry: BoxSilhouetteGeometry;
  depth: number;
};

const Varnish = ({
  normalNoise,
  geometry,
  faceGeometry,
  normalMap,
  silhouetteMap,
  envMap,
  normalScale,
  parallaxScale,
  parallaxMinLayers,
  parallaxMaxLayers,
  layersView,
  varnish,
  animatedOpacity,
  faceName,
  faceUVs,
  metalness,
  roughness,
  reflectivity,
  envMapIntensity,
  normalScaleFactor,
  vecXNormalScale,
  vecYNormalScale,
  verso,
  mask,
  silhouetteMappingMode,
  boxBufferGeometry,
  depth,
}: VarnishProps & VarnishMaterialProps): JSX.Element => {
  const materialRef = useRef<ShaderMaterial>(null!);

  if (materialRef.current) {
    materialRef.current.uniformsNeedUpdate = true;
  }

  const objectRef = useRef<Mesh>();

  const { camera } = useThree();

  const boxMatrixWorld = useRef<Matrix4>(new Matrix4());

  const vCameraPosRelInv = useRef<Vector4>(new Vector4());

  useEffect(() => {
    if (objectRef.current) {
      objectRef.current.matrixWorldNeedsUpdate = true;
      boxMatrixWorld.current = objectRef.current.matrixWorld;
      vCameraPosRelInv.current = new Vector4(
        camera.position.x,
        camera.position.y,
        camera.position.z,
        1
      ).applyMatrix4(boxMatrixWorld.current.invert());
    }
  }, [objectRef.current, parallaxScale, camera.position]);

  useFrame(() => {
    vCameraPosRelInv.current.x = camera.position.x;
    vCameraPosRelInv.current.y = camera.position.y;
    vCameraPosRelInv.current.z = camera.position.z;
    vCameraPosRelInv.current.applyMatrix4(boxMatrixWorld.current.invert());
  });

  const varnishUniformsRecto = useMemo(() => {
    return {
      diffuse: {
        value: new Color(1, 1, 1),
      },
      opacity: { value: animatedOpacity },
      map: { value: varnish },
      uvTransform: {
        value: [
          faceUVs.repeat.x,
          -0,
          0,
          0,
          faceUVs.repeat.y,
          0,
          faceUVs.offset.x,
          faceUVs.offset.y,
          1,
        ],
      },
      uv2Transform: { value: [1, 0, 0, 0, 1, 0, 0, 0, 1] },
      alphaMap: { value: mask },
      envMap: { value: envMap },
      flipEnvMap: { value: -1 },
      reflectivity: { value: reflectivity ?? 1 },
      refractionRatio: { value: 0.98 },
      maxMipLevel: { value: 0 },
      aoMap: { value: null },
      aoMapIntensity: { value: 1 },
      lightMap: { value: null },
      lightMapIntensity: { value: 1 },
      emissiveMap: { value: null },
      normalMap: { value: normalMap },
      silhouetteMap: { value: silhouetteMap },
      normalScale: { value: normalScale },
      roughnessMap: { value: null },
      metalnessMap: { value: null },
      ambientLightColor: {
        value: [0.7278431372549019, 0.7278431372549019, 0.7278431372549019],
        needsUpdate: true,
      },
      emissive: {
        value: new Color(1, 1, 1),
      },
      roughness: { value: roughness ?? 0.1 },
      metalness: { value: metalness ?? 0.01 },
      envMapIntensity: { value: envMapIntensity ?? 3 },
      transparency: { value: 1 },
      clearcoat: { value: 0 },
      clearcoatRoughness: { value: 0 },
      clearcoatMap: { value: null },
      sheen: { value: new Color(0, 0, 0) },
      clearcoatNormalScale: { value: { x: 1, y: 1 } },
      clearcoatNormalMap: { value: null },
      clippingPlanes: { value: null, needsUpdate: false },
      parallaxScale: { value: parallaxScale },
      parallaxMinLayers: { value: parallaxMinLayers },
      parallaxMaxLayers: { value: parallaxMaxLayers },
      normalPaper: { value: normalNoise },
      normalScaleFactor: { value: normalScaleFactor ?? 0.02 },
      vecXNormalScale: { value: vecXNormalScale ?? 25.0 },
      vecYNormalScale: { value: vecYNormalScale ?? 50.0 },
      flip: { value: new Vector4(0, 1, 0, 1) },
      verso: { value: verso ? 0.0 : 0.0 },
      boxSize: {
        value: new Vector3(
          boxBufferGeometry.parameters.width,
          boxBufferGeometry.parameters.height,
          boxBufferGeometry.parameters.depth
        ),
      },
      boxPosition: {
        value: new Vector3(
          faceGeometry.position[0],
          faceGeometry.position[1],
          verso ? -depth / 2 : depth / 2
        ),
      },
      cameraPosRel: {
        value: vCameraPosRelInv.current,
        needsUpdate: true,
      },

      boxUv: {
        value: new Vector4(
          faceUVs.offset.x,
          faceUVs.offset.y,
          faceUVs.repeat.x,
          faceUVs.repeat.y
        ),
      },
      textureSize: {
        value: new Vector2(varnish?.image.height, varnish?.image.width),
      },
    };
  }, [
    animatedOpacity,
    varnish,
    faceUVs.repeat.x,
    faceUVs.repeat.y,
    faceUVs.offset.x,
    faceUVs.offset.y,
    mask,
    envMap,
    reflectivity,
    normalMap,
    silhouetteMap,
    normalScale,
    roughness,
    metalness,
    envMapIntensity,
    parallaxScale,
    parallaxMinLayers,
    parallaxMaxLayers,
    normalNoise,
    normalScaleFactor,
    vecXNormalScale,
    vecYNormalScale,
    verso,
    boxBufferGeometry.parameters.width,
    boxBufferGeometry.parameters.height,
    boxBufferGeometry.parameters.depth,
    faceGeometry.position,
    depth,
  ]);

  useEffect(() => {
    varnishUniformsRecto.cameraPosRel.value = vCameraPosRelInv.current;
  }, [vCameraPosRelInv.current]);

  const varnishMaterial = useMemo(() => {
    return new ShaderMaterial({
      uniforms: varnishUniformsRecto,
      vertexShader: silhouetteVertexShader,
      fragmentShader: silhouetteMappingShader,
      transparent: true,
      alphaTest: 0.01,
      depthWrite: false,
    });
  }, [varnishUniformsRecto]);

  return !silhouetteMappingMode ? (
    <mesh
      name={faceName}
      geometry={geometry}
      position={[...faceGeometry.position, verso ? -0.1 : 0]}
      visible={layerIsEnabled("varnish", layersView) ?? true}
      rotation={verso ? [0, Math.PI, 0] : [0, 0, 0]}
      renderOrder={verso ? 405 : 400}
    >
      <shaderMaterial
        ref={materialRef as React.Ref<ShaderMaterial> | undefined}
        onUpdate={(self: Material) => (self.needsUpdate = true)}
        needsUpdate={true}
        uniformsNeedUpdate={true}
        attach="material"
        uniforms={varnishUniformsRecto}
        vertexShader={varnishVertexShader}
        fragmentShader={varnishFragmentShader}
        transparent
        alphaTest={0.01}
      />
    </mesh>
  ) : (
    <mesh
      name={faceName}
      position={[...faceGeometry.position, verso ? -depth / 2 : depth / 2]}
      visible={layerIsEnabled("varnish", layersView)}
      rotation={verso ? [0, Math.PI, 0] : [0, 0, 0]}
      renderOrder={verso ? 405 : 400}
      ref={objectRef as React.Ref<Mesh> | undefined}
      material={varnishMaterial}
      geometry={boxBufferGeometry}
    ></mesh>
  );
};
export default Varnish;
