import React from "react";
import { BackSide, FrontSide, Mesh, Texture } from "three";
import { Annotations } from "../Domain/Annotations";

type AnnotationsProps = {
  annotation: Annotations;
  faceName: string;
  positionPointRef?: React.MutableRefObject<Mesh | undefined>;
  selectedAnnotation: Annotations | undefined;
  cancelNewAnnotation?: React.Dispatch<void>;
  annotationMode: "display" | "pendingPlacement" | "pendingValidation";
  handleDeleteEvent?: React.Dispatch<Annotations>;
  onSelect3DAnnotation?: React.Dispatch<Annotations>;
  packagingSize: number;
  close3DImg: Texture;
};
export function Annotation3D({
  annotation,
  faceName,
  positionPointRef,
  handleDeleteEvent,
  onSelect3DAnnotation,
  packagingSize,
  close3DImg,
}: AnnotationsProps) {
  return (
    <>
      {annotation.face === faceName && (
        <>
          {annotation.isSelected && (
            <>
              <mesh
                name={faceName}
                position={[
                  annotation.x + 0.025 * packagingSize,
                  annotation.y + 0.025 * packagingSize,
                  annotation.z + 1.2,
                ]}
                ref={positionPointRef as React.Ref<Mesh> | undefined}
                renderOrder={1000}
                onClick={() => {
                  handleDeleteEvent && handleDeleteEvent(annotation);
                }}
              >
                <planeBufferGeometry
                  attach="geometry"
                  args={[
                    0.025 * packagingSize * 0.5,
                    0.025 * packagingSize * 0.5,
                    0,
                  ]}
                />

                <meshBasicMaterial
                  attach="material"
                  transparent
                  map={close3DImg}
                  side={annotation.isVerso ? BackSide : FrontSide}
                  depthTest={false}
                />
              </mesh>
              <mesh
                name={faceName}
                position={[annotation.x, annotation.y, annotation.z + 1.2]}
                ref={positionPointRef as React.Ref<Mesh> | undefined}
                renderOrder={1000}
              >
                <boxBufferGeometry attach="geometry" args={[10, 10, 10]} />

                <ringBufferGeometry
                  attach="geometry"
                  args={[
                    0.025 * packagingSize * 0.95,
                    0.025 * packagingSize,
                    36,
                  ]}
                />

                <meshBasicMaterial
                  attach="material"
                  color="#5596FF"
                  transparent
                  side={annotation.isVerso ? BackSide : FrontSide}
                  depthTest={false}
                />
              </mesh>
            </>
          )}
          <mesh
            name={faceName}
            position={[annotation.x, annotation.y, annotation.z + 1.2]}
            ref={positionPointRef as React.Ref<Mesh> | undefined}
            renderOrder={1000}
            onClick={() => {
              annotation &&
                !annotation.isSelected &&
                onSelect3DAnnotation &&
                onSelect3DAnnotation(annotation);
            }}
          >
            <ringBufferGeometry
              attach="geometry"
              args={[
                0.025 * packagingSize * 0.6,
                0.025 * packagingSize * 0.8,
                32,
              ]}
            />
            <meshBasicMaterial
              attach="material"
              color="#0000ff"
              transparent
              side={annotation.isVerso ? BackSide : FrontSide}
              opacity={annotation.isSelected === false ? 0.25 : 1}
              depthTest={false}
            />
          </mesh>
        </>
      )}
    </>
  );
}
