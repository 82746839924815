import React, { Ref, useCallback, useEffect, useRef, useState } from "react";
import {
  Box3,
  Color,
  Group,
  LineSegments,
  Object3D,
  Scene,
  Vector2,
  Vector3,
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Coords3D, LayerView, Product, SmartLabel } from "../Domain";
import { Annotations } from "../Domain/Annotations";
import { bordeauxBottle, BottleObject } from "../Domain/Bottle";
import { DEFAULT_FOIL_MAPPING, FoilMapping } from "../Domain/Foil";
import { Controls } from "./Controls";
import { VarnishMaterialProps } from "./Layers/Varnish";
import { LedProps, Leds } from "./Leds";
import { Packaging3D } from "./Packaging3D";

export type PaperMaterial = {
  name: string;
  envMapIntensity: number;
  normalScaleFactor: number;
  vecNormalScale: number;
  roughness: number;
  metalness?: number;
  diffuse?: string;
};

// TODO: Rename to ProductView
export type CreationState = {
  packaging: Product;
  smartLabels: SmartLabel[];
  enableZoom?: boolean;
  cancelImport: boolean;
  layersView: LayerView[];
  annotations: Annotations[];
  selectedAnnotation?: Annotations;
};

export type CameraProps = {
  target?: Coords3D;
  position?: Coords3D;
  packagingSize?: number;
};

export type SceneProps = {
  creationState: CreationState;
  ledProps: Pick<
    LedProps,
    | "ledIntensity"
    | "sphereSize"
    | "distance"
    | "decay"
    | "sphereColor"
    | "lightColor"
  >;
  varnishMaterialProps: VarnishMaterialProps;
  splinterCell: boolean;
  opacity: number;
  enableAxesHelper: boolean;
  rotationFactor: number;
  enlightened: number;
  forwardedRef: Ref<Object3D>;
  folded: boolean;
  angleMode: boolean;
  anglePreview3D?: boolean;
  onNewAnnotation?: React.Dispatch<Annotations>;
  cancelNewAnnotation?: React.Dispatch<void>;
  useBluredHeightMap: boolean;
  displayedMap: string;
  activePaper: PaperMaterial;
  annotationMode: "display" | "pendingPlacement" | "pendingValidation";
  handleDeleteEvent?: React.Dispatch<Annotations>;
  onSelect3DAnnotation?: React.Dispatch<Annotations>;
  active360Background?: string;
  foilMapping: FoilMapping;
  foilDiffuseColor: string;
  showOnBottle: boolean;
  silhouetteMappingMode: boolean;
  selectedBottle?: BottleObject;
  labelPosition?: number;
  normalScale: number;
  parallaxScale: number;
  varnishLevel: number;
  metalness: number;
  roughness: number;
  metalPaperColor: string;
  envMapIntensity: number;
  selectedFold?: number[];
  setSelectedFold?: React.Dispatch<number[] | undefined>;
  newRotation?: {
    faceName: string;
    rotation: number[];
  }[];
  autoRotate?: boolean;
  resetCamera?: boolean;
  setAutoRotate?: React.Dispatch<boolean>;
  setResetCamera?: React.Dispatch<boolean>;
};
export function PreviewScene({
  creationState,
  ledProps,
  opacity,
  enableAxesHelper,
  rotationFactor,
  forwardedRef,
  folded,
  angleMode,
  anglePreview3D,
  varnishMaterialProps,
  onNewAnnotation,
  cancelNewAnnotation,
  useBluredHeightMap,
  displayedMap,
  activePaper,
  annotationMode,
  handleDeleteEvent,
  onSelect3DAnnotation,
  active360Background,
  foilMapping,
  foilDiffuseColor,
  showOnBottle,
  silhouetteMappingMode,
  selectedBottle,
  labelPosition,
  normalScale,
  parallaxScale,
  varnishLevel,
  metalness,
  roughness,
  metalPaperColor,
  envMapIntensity,
  selectedFold,
  setSelectedFold,
  newRotation,
  autoRotate,
  setAutoRotate,
  resetCamera,
  setResetCamera,
}: SceneProps): JSX.Element {
  const { packaging, enableZoom } = creationState;

  const [cameraState, setCameraState] = useState<CameraProps>({});

  const groupRef = useRef<Group>();

  const updateControlsTarget = useCallback(() => {
    if (groupRef.current) {
      // Compute bounding box using Box3
      // Inspired by setContent() method in three-gltf-viewer
      //   https://github.com/donmccurdy/three-gltf-viewer/blob/master/src/viewer.js

      const newGroup = groupRef.current.clone();

      if (anglePreview3D) {
        const elementToDelete: number[] = [];
        newGroup.traverse((t) => {
          if (t.type === "LineSegments") {
            elementToDelete.push(t.id);
          }
        });
        elementToDelete.forEach((toDdelete) => {
          const elementId = newGroup.getObjectById(toDdelete);
          if (elementId) {
            elementId.parent?.remove(elementId);
          }
        });
      }

      const box = new Box3().setFromObject(newGroup);

      // Compute camera target and position
      const center = box.getCenter(new Vector3());
      const target = center.toArray() as Coords3D;

      controls.current?.target.set(target[0], target[1], target[2]);
    }
  }, [anglePreview3D]);

  useEffect(() => {
    if (resetCamera) {
      updateControlsTarget();
      setResetCamera && setResetCamera(false);
    }
  }, [resetCamera]);

  const [segmentsList, setSegmentsList] =
    useState<{ segment: Vector3[]; face: string }[]>();

  const [hovered, setHovered] = useState<number | undefined>();

  useEffect(() => {
    const segments: { segment: Vector3[]; face: string }[] = [];
    if (groupRef.current) {
      groupRef.current.traverse((child) => {
        if (child.type === "LineSegments") {
          const line = child as LineSegments;

          const list = packaging.fold?.Folding?.filter(
            (f) => f.Face["Face"] === Number(line.name)
          );

          const Y = list?.[0].Face["Segment"]?.[0].Point.Y;
          const Y2 = list?.[0].Face["Segment"]?.[1].Point.Y;
          segments.push({
            segment: [
              new Vector3(
                list?.[0].Face["Segment"]?.[0].Point.X,
                Y ? -Y : 0,

                0
              ),
              new Vector3(
                list?.[0].Face["Segment"]?.[1].Point.X,
                Y2 ? -Y2 : 0,
                0
              ),
            ],
            face: line.name,
          });
        }
      });
      setSegmentsList(segments);
    } else setSegmentsList(undefined);
  }, [groupRef, groupRef.current, groupRef.current?.children]);
  const controls = useRef<OrbitControls>(null);

  //const RGBfoilDiffuseColor = hexToRgb(foilDiffuseColor);
  const packagingElement: JSX.Element = (
    <Packaging3D
      creationState={creationState}
      dieline={packaging.dieline}
      fold={packaging.fold?.Folding}
      enableAxesHelper={enableAxesHelper}
      opacity={opacity}
      rotationFactor={rotationFactor}
      ledProps={ledProps}
      folded={angleMode ? false : folded}
      updateCamera={setCameraState}
      onNewAnnotation={onNewAnnotation}
      cancelNewAnnotation={cancelNewAnnotation}
      varnishMaterialProps={varnishMaterialProps}
      useBluredHeightMap={useBluredHeightMap}
      displayedMap={displayedMap}
      activePaper={activePaper}
      annotationMode={annotationMode}
      handleDeleteEvent={handleDeleteEvent}
      onSelect3DAnnotation={onSelect3DAnnotation}
      active360Background={active360Background}
      foilMapping={foilMapping}
      foilDiffuseColor={foilDiffuseColor}
      showOnBottle={showOnBottle}
      silhouetteMappingMode={silhouetteMappingMode}
      selectedBottle={selectedBottle}
      labelPosition={labelPosition}
      normalScale={normalScale}
      parallaxScale={parallaxScale}
      varnishLevel={varnishLevel}
      ref={groupRef}
      updateControlsTarget={updateControlsTarget}
      metalness={metalness}
      roughness={roughness}
      envMapIntensity={envMapIntensity}
      metalPaperColor={metalPaperColor}
      angleMode={angleMode}
      anglePreview3D={anglePreview3D}
      selectedFold={selectedFold}
      newRotation={newRotation}
      hovered={hovered}
      autoRotate={autoRotate}
      setAutoRotate={setAutoRotate}
      controls={controls}
    />
  );

  // TODO: Calculate light position from packaging center
  //const initialLightPosition: Coords3D = [500, 500, 2000];
  //const initialLightPosition2: Coords3D = [9000, -500, -12000];

  function computeDistance(segment: Vector3[], point: Vector3) {
    const vDir = new Vector3(
      segment[1].x - segment[0].x,
      segment[1].y - segment[0].y,
      0
    );
    const vRelPos = new Vector3(
      point.x - segment[1].x,
      point.y - segment[1].y,
      0
    );

    if (vDir.dot(vRelPos) >= 0) {
      return vRelPos.length();
    }
    const vRelPos2 = new Vector3(
      point.x - segment[0].x,
      point.y - segment[0].y,
      0
    );

    if (vDir.dot(vRelPos2) <= 0) {
      return vRelPos2.length();
    }

    return (
      Math.abs(
        new Vector2(vDir.x, vDir.y).cross(new Vector2(vRelPos.x, vRelPos.y))
      ) / vDir.length()
    );
  }

  useEffect(() => {
    angleMode && setSelectedFold && setSelectedFold(selectedFold);
  }, [angleMode]);

  return (
    <>
      <Controls
        ref={controls}
        selectedAnnotation={creationState.selectedAnnotation}
        {...{ enableZoom, ...cameraState }}
        autoRotate={true}
        enablePan={false}
        enableZoom={true}
        angleMode={angleMode}
      />
      <scene
        ref={forwardedRef as React.Ref<Scene> | undefined}
        onClick={(e) => {
          if (setSelectedFold) {
            if (e.nativeEvent.ctrlKey || e.nativeEvent.metaKey) {
              const array = selectedFold && [...selectedFold];
              if (hovered !== undefined) {
                if (array?.includes(hovered)) {
                  array.splice(
                    array.findIndex((x) => x === hovered),
                    1
                  );
                  setSelectedFold(array);
                } else {
                  array?.push(hovered);
                  setSelectedFold(array);
                }
              }
            } else if (
              hovered !== undefined &&
              !(e.nativeEvent.ctrlKey || e.nativeEvent.metaKey)
            ) {
              setSelectedFold([hovered]);
            } else setSelectedFold(undefined);
          }
        }}
        onPointerMove={(e) => {
          const point = e.point.clone();
          if (point && segmentsList && segmentsList.length > 0) {
            const array: number[] = [];
            segmentsList?.forEach((seg) =>
              array.push(computeDistance(seg.segment, point))
            );
            const min = array.reduce((a, b) => Math.min(a, b));
            if (min < 30) {
              const index = array.findIndex((e) => e === min);
              const needHover = segmentsList[index].face;
              setHovered(parseInt(needHover));
            } else {
              setHovered(undefined);
            }
          }
        }}
      >
        <ambientLight color={new Color(0x404040)} intensity={5} />

        {angleMode ? segmentsList && packagingElement : packagingElement}
      </scene>
      {enableAxesHelper && <axesHelper args={[5]} />}
    </>
  );
}

const defaultProps: Omit<
  SceneProps,
  | "creationState"
  | "opacity"
  | "onNewAnnotation"
  | "setLoadedTextureCache"
  | "handleDeleteEvent"
> = {
  ledProps: Leds.defaultProps,
  splinterCell: false,
  enableAxesHelper: false,
  rotationFactor: 1,
  folded: false,
  angleMode: false,
  anglePreview3D: false,
  enlightened: 3.6,
  forwardedRef: null,
  varnishMaterialProps: {
    roughness: 0.1,
    metalness: 0.01,
    reflectivity: 1.4,
    envMapIntensity: 1.8,
    normalScaleFactor: 0.015,
    vecXNormalScale: 20.0,
    vecYNormalScale: 30.0,
  },
  useBluredHeightMap: true,
  displayedMap: "REGULAR_MAP",
  activePaper: {
    name: "WhiteMatte",
    envMapIntensity: 0,
    normalScaleFactor: 0.01,
    vecNormalScale: 4.0,
    roughness: 1,
  },
  annotationMode: "display",
  foilMapping: DEFAULT_FOIL_MAPPING,
  foilDiffuseColor: "#ffcc80",
  showOnBottle: false,
  silhouetteMappingMode: true,
  selectedBottle: bordeauxBottle,
  labelPosition: bordeauxBottle.defaultLabelPosition,
  normalScale: 1.5,
  parallaxScale: 0.0075,
  varnishLevel: 7.0,
  metalness: 0.15,
  roughness: 0.29,
  metalPaperColor: "#c6cfd7",
  envMapIntensity: 0.6,
};

PreviewScene.defaultProps = defaultProps;
