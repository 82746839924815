import { FaceGeometry, FaceGeometryMap } from "./Face";
import {
  applyRotationFunction,
  deriveRotationAxisFromFaceGeometries,
  FoldingTree,
  MinimalFoldingTree,
} from "./FoldingTree/FoldingTree";

export type Dieline = {
  name: string;
  pdfURL?: string;
  aiURL?: string;
  faceGeometries: FaceGeometryMap;
  // Dimensions of the 2D dieline
  dimensions: {
    width: number;
    height: number;
  };
  foldingTree: FoldingTree;
};

export type DielineJson = {
  name: string;
  pdfURL?: string;
  aiURL?: string;
  faceGeometries: {
    [faceName: string]: Omit<FaceGeometry, "position">;
  };
  dimensions: {
    width: number;
    height: number;
  };
  foldingTree: MinimalFoldingTree;
};

export function deriveRotationAxes(dielineJson: DielineJson): Dieline {
  const { faceGeometries, foldingTree } = dielineJson;
  const faceGeometriesWithPositions: FaceGeometryMap = Object.fromEntries(
    Object.entries(faceGeometries).map(([faceName, faceGeometry]) => [
      faceName,
      {
        ...faceGeometry,
        position: [faceGeometry.width / 2, -faceGeometry.height / 2],
      },
    ])
  );
  const deriveRotationAxis = (
    parentFaceName: string,
    childFaceName: string,
    rotationAngle?: number
  ) => {
    return deriveRotationAxisFromFaceGeometries(
      faceGeometriesWithPositions[parentFaceName],
      faceGeometriesWithPositions[childFaceName],
      rotationAngle
    );
  };
  return {
    ...dielineJson,
    faceGeometries: faceGeometriesWithPositions,
    foldingTree: applyRotationFunction(deriveRotationAxis, foldingTree),
  };
}
