import { Dieline } from "../Dieline";
import { FaceGeometry, FaceGeometryMap } from "../Face";
import { AutoFold, FoldingFace } from "./auto-fold";
import FoldingTree from "./FoldingTree";

export function autoFoldConverter(autoFold: AutoFold): Dieline | Error {
  const rootFace = autoFold.Folding.find(
    ({ Face }) => Face["Parent face"] === -1
  )?.Face;

  if (rootFace === undefined) {
    return new Error("Malformed autofold file: no root face detected");
  }

  const foldingTree = recursiveFindChildren(autoFold, rootFace);
  const faceGeometries: FaceGeometryMap = autoFold.Faces.reduce(
    (geometries, face, index) => {
      const geometry: FaceGeometry = {
        x: face.Face.Box.Min.X,
        y: face.Face.Box.Min.Y,
        width: face.Face.Box.Max.X - face.Face.Box.Min.X,
        height: face.Face.Box.Max.Y - face.Face.Box.Min.Y,
        position: [
          (face.Face.Box.Min.X + face.Face.Box.Max.X) / 2,
          -(face.Face.Box.Min.Y + face.Face.Box.Max.Y) / 2,
        ],
        vertices: face.Face.Mesh.Vertices,
        indices: face.Face.Mesh.Indices,
      };
      return { ...geometries, [index]: geometry };
    },
    {}
  );
  return {
    name: "custom",
    dimensions: {
      width: autoFold.Size.X,
      height: autoFold.Size.Y,
    },
    foldingTree,
    faceGeometries,
  };
}

export function recursiveFindChildren(
  autoFold: AutoFold,
  parentFace: FoldingFace
): FoldingTree {
  const childrenFaces: FoldingFace[] = autoFold.Folding.filter(
    ({ Face }) => Face["Parent face"] === parentFace.Face
  ).map(({ Face }) => Face);
  const matrix = parentFace["Matrix folded relative"];

  const children = childrenFaces.map((face) =>
    recursiveFindChildren(autoFold, face)
  );
  return {
    faceName: parentFace.Face.toString(),
    // Here we invert rotation matrices along the y axis, to compensate
    // for the y-axis being inverted in the svg coordinate system
    // This will be later fixed in the autofold service itself, but for now
    // we fix it here.
    rotation: [
      matrix.Xx,
      -matrix.Xy,
      matrix.Xz,
      matrix.Xw,
      -matrix.Yx,
      matrix.Yy,
      -matrix.Yz,
      matrix.Yw,
      matrix.Zx,
      -matrix.Zy,
      matrix.Zz,
      matrix.Zw,
      matrix.Ox,
      -matrix.Oy,
      matrix.Oz,
      matrix.Ow,
    ],
    isPackagingStrip: autoFold.StripIndices?.includes(parentFace.Face) ?? false,
    children,
  };
}
