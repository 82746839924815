export const heightMapCombineShader = `
varying vec2 vUv;
uniform sampler2D texture0; // varnish
uniform sampler2D texture1; // gold
uniform sampler2D texture2; // silver
uniform sampler2D texture3; // copper
uniform sampler2D texture4; // red
uniform sampler2D texture5; // green
uniform sampler2D texture6; // blue
uniform sampler2D texture7; // pink
uniform sampler2D texture8; // orange
uniform sampler2D texture9; // black
uniform vec4 vSizeTexture;
uniform float factorvarnish;
uniform float factorgoldFoil;
uniform float factorsilverFoil;
uniform float factorcopperFoil;
uniform float factorredFoil;
uniform float factorgreenFoil;
uniform float factorblueFoil;
uniform float factorpinkFoil;
uniform float factororangeFoil;
uniform float factorblackFoil;
uniform float level;

float quantize32(float v) {
    return floor(v*4.0) / 4.0;
}

void main() {
vec2	vUv = vUv; // + 0.5 * vSizeTexture.zw;
vec4	cVarnish = texture2D( texture0, vUv.xy );
float	fVarnish;

fVarnish = quantize32(0.33333 * ( cVarnish.r + cVarnish.g + cVarnish.b ));
fVarnish = step(0.1, cVarnish.a) * step( 0.0, fVarnish ) * max( 0.25, 1.0 - fVarnish ) * step( cVarnish.r + cVarnish.g + cVarnish.b ,2.9999);

vec4	cSilver = texture2D( texture2, vUv.xy );
float	fSilver = quantize32(0.33333 * ( cSilver.r + cSilver.g + cSilver.b ));
fSilver = step(0.1, cSilver.a) * step( 0.0, fSilver ) * 0.25;

vec4	cGold = texture2D( texture1, vUv.xy );
float	fGold = quantize32(0.33333 * ( cGold.r + cGold.g + cGold.b ));
fGold = step(0.1, cGold.a) * step( 0.0, fGold ) * 0.25;

vec4	cCopper = texture2D( texture3, vUv.xy );
float	fCopper = quantize32(0.33333 * ( cCopper.r + cCopper.g + cCopper.b ));
fCopper = step(0.1, cCopper.a) * step( 0.0, fCopper ) * 0.25;

vec4	cRed = texture2D( texture4, vUv.xy );
float	fRed = quantize32(0.33333 * ( cRed.r + cRed.g + cRed.b ));
fRed = step(0.1, cRed.a) * step( 0.0, fRed ) * 0.25;

vec4	cGreen = texture2D( texture5, vUv.xy );
float	fGreen = quantize32(0.33333 * ( cGreen.r + cGreen.g + cGreen.b ));
fGreen = step(0.1, cGreen.a) * step( 0.0, fGreen ) * 0.25;

vec4	cBlue = texture2D( texture6, vUv.xy );
float	fBlue = quantize32(0.33333 * ( cBlue.r + cBlue.g + cBlue.b ));
fBlue = step(0.1, cBlue.a) * step( 0.0, fBlue ) * 0.25;

vec4	cPink = texture2D( texture7, vUv.xy );
float	fPink = quantize32(0.33333 * ( cPink.r + cPink.g + cPink.b ));
fPink = step(0.1, cPink.a) * step( 0.0, fPink ) * 0.25;

vec4	cOrange = texture2D( texture8, vUv.xy );
float	fOrange = quantize32(0.33333 * ( cOrange.r + cOrange.g + cOrange.b ));
fOrange = step(0.1, cOrange.a) * step( 0.0, fOrange ) * 0.25;

vec4	cBlack = texture2D( texture9, vUv.xy );
float	fBlack = quantize32(0.33333 * ( cBlack.r + cBlack.g + cBlack.b ));
fBlack = step(0.1, cBlack.a) * step( 0.0, fBlack ) * 0.25;


float fLuminance = min(level, fVarnish * factorvarnish) + fSilver * factorsilverFoil + fGold * factorgoldFoil + fCopper * factorcopperFoil + fRed * factorredFoil + fGreen * factorgreenFoil + fBlue * factorblueFoil + fPink * factorpinkFoil + fOrange * factororangeFoil + fBlack * factorblackFoil;


 gl_FragColor = vec4( fLuminance, fLuminance, fLuminance, 1.0 ) ;
}
`;
