import { AutoFold } from "./auto-fold";

// Example of file generated by autofold service deployed on pdf-manager
export const autoFoldMediflexExample: AutoFold = {
  Size: {
    X: 1003.1099853515625,
    Y: 689.0,
  },
  Faces: [
    {
      Face: {
        Box: {
          Min: {
            X: 918.2861328125,
            Y: 160.5479736328125,
          },
          Max: {
            X: 955.239013671875,
            Y: 292.52099609375,
          },
        },
        Polygon: [
          {
            Point: {
              X: 934.8270263671875,
              Y: 161.10003662109375,
            },
          },
          {
            Point: {
              X: 955.239013671875,
              Y: 182.2020263671875,
            },
          },
          {
            Point: {
              X: 955.239013671875,
              Y: 270.865020751953125,
            },
          },
          {
            Point: {
              X: 934.8270263671875,
              Y: 291.97003173828125,
            },
          },
          {
            Point: {
              X: 918.2861328125,
              Y: 292.52099609375,
            },
          },
          {
            Point: {
              X: 918.28717041015625,
              Y: 160.5479736328125,
            },
          },
        ],
        Mesh: {
          Vertices: [
            {
              Vertex: {
                X: 934.8270263671875,
                Y: 161.10003662109375,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 955.239013671875,
                Y: 182.2020263671875,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 955.239013671875,
                Y: 270.865020751953125,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 934.8270263671875,
                Y: 291.97003173828125,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 918.2861328125,
                Y: 292.52099609375,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 918.28717041015625,
                Y: 160.5479736328125,
                Z: 0.0,
              },
            },
          ],
          Indices: [
            {
              Index: 0,
            },
            {
              Index: 0,
            },
            {
              Index: 1,
            },
            {
              Index: 0,
            },
            {
              Index: 1,
            },
            {
              Index: 2,
            },
            {
              Index: 0,
            },
            {
              Index: 2,
            },
            {
              Index: 3,
            },
            {
              Index: 0,
            },
            {
              Index: 3,
            },
            {
              Index: 4,
            },
            {
              Index: 0,
            },
            {
              Index: 4,
            },
            {
              Index: 5,
            },
          ],
        },
      },
    },
    {
      Face: {
        Box: {
          Min: {
            X: 85.66591644287109375,
            Y: 425.826995849609375,
          },
          Max: {
            X: 123.9378814697265625,
            Y: 555.1199951171875,
          },
        },
        Polygon: [
          {
            Point: {
              X: 123.9368743896484375,
              Y: 537.9840087890625,
            },
          },
          {
            Point: {
              X: 123.9368743896484375,
              Y: 555.1199951171875,
            },
          },
          {
            Point: {
              X: 85.66591644287109375,
              Y: 531.385986328125,
            },
          },
          {
            Point: {
              X: 85.66591644287109375,
              Y: 449.56298828125,
            },
          },
          {
            Point: {
              X: 123.9378814697265625,
              Y: 425.826995849609375,
            },
          },
          {
            Point: {
              X: 123.9378814697265625,
              Y: 442.9639892578125,
            },
          },
        ],
        Mesh: {
          Vertices: [
            {
              Vertex: {
                X: 123.9368743896484375,
                Y: 537.9840087890625,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 123.9368743896484375,
                Y: 555.1199951171875,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 85.66591644287109375,
                Y: 531.385986328125,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 85.66591644287109375,
                Y: 449.56298828125,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 123.9378814697265625,
                Y: 425.826995849609375,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 123.9378814697265625,
                Y: 442.9639892578125,
                Z: 0.0,
              },
            },
          ],
          Indices: [
            {
              Index: 0,
            },
            {
              Index: 1,
            },
            {
              Index: 2,
            },
            {
              Index: 5,
            },
            {
              Index: 0,
            },
            {
              Index: 2,
            },
            {
              Index: 5,
            },
            {
              Index: 2,
            },
            {
              Index: 3,
            },
            {
              Index: 5,
            },
            {
              Index: 3,
            },
            {
              Index: 4,
            },
          ],
        },
      },
    },
    {
      Face: {
        Box: {
          Min: {
            X: 258.435760498046875,
            Y: 159.88800048828125,
          },
          Max: {
            X: 786.31622314453125,
            Y: 293.8389892578125,
          },
        },
        Polygon: [
          {
            Point: {
              X: 786.31622314453125,
              Y: 292.51995849609375,
            },
          },
          {
            Point: {
              X: 258.435760498046875,
              Y: 293.8389892578125,
            },
          },
          {
            Point: {
              X: 258.435760498046875,
              Y: 159.88800048828125,
            },
          },
          {
            Point: {
              X: 786.31622314453125,
              Y: 160.5479736328125,
            },
          },
        ],
        Mesh: {
          Vertices: [
            {
              Vertex: {
                X: 786.31622314453125,
                Y: 292.51995849609375,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 258.435760498046875,
                Y: 293.8389892578125,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 258.435760498046875,
                Y: 159.88800048828125,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 786.31622314453125,
                Y: 160.5479736328125,
                Z: 0.0,
              },
            },
          ],
          Indices: [
            {
              Index: 0,
            },
            {
              Index: 0,
            },
            {
              Index: 1,
            },
            {
              Index: 0,
            },
            {
              Index: 1,
            },
            {
              Index: 2,
            },
            {
              Index: 0,
            },
            {
              Index: 2,
            },
            {
              Index: 3,
            },
          ],
        },
      },
    },
    {
      Face: {
        Box: {
          Min: {
            X: 784.99713134765625,
            Y: 28.5780029296875,
          },
          Max: {
            X: 853.6221923828125,
            Y: 160.5479736328125,
          },
        },
        Polygon: [
          {
            Point: {
              X: 784.99713134765625,
              Y: 28.5780029296875,
            },
          },
          {
            Point: {
              X: 853.6221923828125,
              Y: 52.80596923828125,
            },
          },
          {
            Point: {
              X: 853.6221923828125,
              Y: 147.9449462890625,
            },
          },
          {
            Point: {
              X: 853.6221923828125,
              Y: 147.9439697265625,
            },
          },
          {
            Point: {
              X: 792.915283203125,
              Y: 152.6309814453125,
            },
          },
          {
            Point: {
              X: 786.31622314453125,
              Y: 160.5479736328125,
            },
          },
        ],
        Mesh: {
          Vertices: [
            {
              Vertex: {
                X: 784.99713134765625,
                Y: 28.5780029296875,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 853.6221923828125,
                Y: 52.80596923828125,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 853.6221923828125,
                Y: 147.9449462890625,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 853.6221923828125,
                Y: 147.9439697265625,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 792.915283203125,
                Y: 152.6309814453125,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 786.31622314453125,
                Y: 160.5479736328125,
                Z: 0.0,
              },
            },
          ],
          Indices: [
            {
              Index: 5,
            },
            {
              Index: 0,
            },
            {
              Index: 1,
            },
            {
              Index: 1,
            },
            {
              Index: 2,
            },
            {
              Index: 3,
            },
            {
              Index: 1,
            },
            {
              Index: 3,
            },
            {
              Index: 4,
            },
            {
              Index: 1,
            },
            {
              Index: 4,
            },
            {
              Index: 5,
            },
          ],
        },
      },
    },
    {
      Face: {
        Box: {
          Min: {
            X: 786.31622314453125,
            Y: 160.5479736328125,
          },
          Max: {
            X: 918.28717041015625,
            Y: 292.52099609375,
          },
        },
        Polygon: [
          {
            Point: {
              X: 918.28717041015625,
              Y: 160.5479736328125,
            },
          },
          {
            Point: {
              X: 918.2861328125,
              Y: 292.52099609375,
            },
          },
          {
            Point: {
              X: 786.31622314453125,
              Y: 292.51995849609375,
            },
          },
          {
            Point: {
              X: 786.31622314453125,
              Y: 160.5479736328125,
            },
          },
        ],
        Mesh: {
          Vertices: [
            {
              Vertex: {
                X: 918.28717041015625,
                Y: 160.5479736328125,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 918.2861328125,
                Y: 292.52099609375,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 786.31622314453125,
                Y: 292.51995849609375,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 786.31622314453125,
                Y: 160.5479736328125,
                Z: 0.0,
              },
            },
          ],
          Indices: [
            {
              Index: 0,
            },
            {
              Index: 0,
            },
            {
              Index: 1,
            },
            {
              Index: 0,
            },
            {
              Index: 1,
            },
            {
              Index: 2,
            },
            {
              Index: 0,
            },
            {
              Index: 2,
            },
            {
              Index: 3,
            },
          ],
        },
      },
    },
    {
      Face: {
        Box: {
          Min: {
            X: 258.435760498046875,
            Y: 28.5780029296875,
          },
          Max: {
            X: 786.31622314453125,
            Y: 160.5479736328125,
          },
        },
        Polygon: [
          {
            Point: {
              X: 258.435760498046875,
              Y: 159.88800048828125,
            },
          },
          {
            Point: {
              X: 258.435760498046875,
              Y: 28.5780029296875,
            },
          },
          {
            Point: {
              X: 784.99713134765625,
              Y: 28.5780029296875,
            },
          },
          {
            Point: {
              X: 786.31622314453125,
              Y: 160.5479736328125,
            },
          },
        ],
        Mesh: {
          Vertices: [
            {
              Vertex: {
                X: 258.435760498046875,
                Y: 159.88800048828125,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 258.435760498046875,
                Y: 28.5780029296875,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 784.99713134765625,
                Y: 28.5780029296875,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 786.31622314453125,
                Y: 160.5479736328125,
                Z: 0.0,
              },
            },
          ],
          Indices: [
            {
              Index: 0,
            },
            {
              Index: 0,
            },
            {
              Index: 1,
            },
            {
              Index: 0,
            },
            {
              Index: 1,
            },
            {
              Index: 2,
            },
            {
              Index: 0,
            },
            {
              Index: 2,
            },
            {
              Index: 3,
            },
          ],
        },
      },
    },
    {
      Face: {
        Box: {
          Min: {
            X: 784.99713134765625,
            Y: 292.51995849609375,
          },
          Max: {
            X: 853.62115478515625,
            Y: 423.1710205078125,
          },
        },
        Polygon: [
          {
            Point: {
              X: 792.9141845703125,
              Y: 300.438018798828125,
            },
          },
          {
            Point: {
              X: 853.62115478515625,
              Y: 305.123016357421875,
            },
          },
          {
            Point: {
              X: 853.62115478515625,
              Y: 398.943023681640625,
            },
          },
          {
            Point: {
              X: 784.99713134765625,
              Y: 423.1710205078125,
            },
          },
          {
            Point: {
              X: 786.31622314453125,
              Y: 292.51995849609375,
            },
          },
        ],
        Mesh: {
          Vertices: [
            {
              Vertex: {
                X: 792.9141845703125,
                Y: 300.438018798828125,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 853.62115478515625,
                Y: 305.123016357421875,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 853.62115478515625,
                Y: 398.943023681640625,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 784.99713134765625,
                Y: 423.1710205078125,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 786.31622314453125,
                Y: 292.51995849609375,
                Z: 0.0,
              },
            },
          ],
          Indices: [
            {
              Index: 0,
            },
            {
              Index: 1,
            },
            {
              Index: 2,
            },
            {
              Index: 4,
            },
            {
              Index: 0,
            },
            {
              Index: 2,
            },
            {
              Index: 4,
            },
            {
              Index: 2,
            },
            {
              Index: 3,
            },
          ],
        },
      },
    },
    {
      Face: {
        Box: {
          Min: {
            X: 258.43475341796875,
            Y: 292.51995849609375,
          },
          Max: {
            X: 786.31622314453125,
            Y: 424.489013671875,
          },
        },
        Polygon: [
          {
            Point: {
              X: 784.99713134765625,
              Y: 423.1710205078125,
            },
          },
          {
            Point: {
              X: 258.43475341796875,
              Y: 424.489013671875,
            },
          },
          {
            Point: {
              X: 258.435760498046875,
              Y: 293.8389892578125,
            },
          },
          {
            Point: {
              X: 786.31622314453125,
              Y: 292.51995849609375,
            },
          },
        ],
        Mesh: {
          Vertices: [
            {
              Vertex: {
                X: 784.99713134765625,
                Y: 423.1710205078125,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 258.43475341796875,
                Y: 424.489013671875,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 258.435760498046875,
                Y: 293.8389892578125,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 786.31622314453125,
                Y: 292.51995849609375,
                Z: 0.0,
              },
            },
          ],
          Indices: [
            {
              Index: 0,
            },
            {
              Index: 0,
            },
            {
              Index: 1,
            },
            {
              Index: 0,
            },
            {
              Index: 1,
            },
            {
              Index: 2,
            },
            {
              Index: 0,
            },
            {
              Index: 2,
            },
            {
              Index: 3,
            },
          ],
        },
      },
    },
    {
      Face: {
        Box: {
          Min: {
            X: 187.282806396484375,
            Y: 28.5780029296875,
          },
          Max: {
            X: 258.435760498046875,
            Y: 159.88800048828125,
          },
        },
        Polygon: [
          {
            Point: {
              X: 258.435760498046875,
              Y: 159.88800048828125,
            },
          },
          {
            Point: {
              X: 245.2397613525390625,
              Y: 159.22900390625,
            },
          },
          {
            Point: {
              X: 239.9607696533203125,
              Y: 153.948974609375,
            },
          },
          {
            Point: {
              X: 187.282806396484375,
              Y: 135.8499755859375,
            },
          },
          {
            Point: {
              X: 187.282806396484375,
              Y: 41.1829833984375,
            },
          },
          {
            Point: {
              X: 250.5177459716796875,
              Y: 36.4959716796875,
            },
          },
          {
            Point: {
              X: 258.435760498046875,
              Y: 28.5780029296875,
            },
          },
        ],
        Mesh: {
          Vertices: [
            {
              Vertex: {
                X: 258.435760498046875,
                Y: 159.88800048828125,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 245.2397613525390625,
                Y: 159.22900390625,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 239.9607696533203125,
                Y: 153.948974609375,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 187.282806396484375,
                Y: 135.8499755859375,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 187.282806396484375,
                Y: 41.1829833984375,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 250.5177459716796875,
                Y: 36.4959716796875,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 258.435760498046875,
                Y: 28.5780029296875,
                Z: 0.0,
              },
            },
          ],
          Indices: [
            {
              Index: 6,
            },
            {
              Index: 0,
            },
            {
              Index: 1,
            },
            {
              Index: 6,
            },
            {
              Index: 1,
            },
            {
              Index: 2,
            },
            {
              Index: 6,
            },
            {
              Index: 2,
            },
            {
              Index: 3,
            },
            {
              Index: 3,
            },
            {
              Index: 4,
            },
            {
              Index: 5,
            },
            {
              Index: 3,
            },
            {
              Index: 5,
            },
            {
              Index: 6,
            },
          ],
        },
      },
    },
    {
      Face: {
        Box: {
          Min: {
            X: 257.775726318359375,
            Y: 556.45947265625,
          },
          Max: {
            X: 784.99627685546875,
            Y: 593.41302490234375,
          },
        },
        Polygon: [
          {
            Point: {
              X: 775.09521484375,
              Y: 593.41302490234375,
            },
          },
          {
            Point: {
              X: 268.336669921875,
              Y: 593.4119873046875,
            },
          },
          {
            Point: {
              X: 268.336669921875,
              Y: 593.41302490234375,
            },
          },
          {
            Point: {
              X: 257.775726318359375,
              Y: 556.45947265625,
            },
          },
          {
            Point: {
              X: 784.99627685546875,
              Y: 556.4610595703125,
            },
          },
        ],
        Mesh: {
          Vertices: [
            {
              Vertex: {
                X: 775.09521484375,
                Y: 593.41302490234375,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 268.336669921875,
                Y: 593.4119873046875,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 268.336669921875,
                Y: 593.41302490234375,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 257.775726318359375,
                Y: 556.45947265625,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 784.99627685546875,
                Y: 556.4610595703125,
                Z: 0.0,
              },
            },
          ],
          Indices: [
            {
              Index: 4,
            },
            {
              Index: 0,
            },
            {
              Index: 1,
            },
            {
              Index: 1,
            },
            {
              Index: 2,
            },
            {
              Index: 3,
            },
            {
              Index: 1,
            },
            {
              Index: 3,
            },
            {
              Index: 4,
            },
          ],
        },
      },
    },
    {
      Face: {
        Box: {
          Min: {
            X: 257.775726318359375,
            Y: 423.1710205078125,
          },
          Max: {
            X: 784.99713134765625,
            Y: 556.4610595703125,
          },
        },
        Polygon: [
          {
            Point: {
              X: 784.99713134765625,
              Y: 423.1710205078125,
            },
          },
          {
            Point: {
              X: 784.99627685546875,
              Y: 556.4610595703125,
            },
          },
          {
            Point: {
              X: 257.775726318359375,
              Y: 556.45947265625,
            },
          },
          {
            Point: {
              X: 258.43475341796875,
              Y: 424.489013671875,
            },
          },
        ],
        Mesh: {
          Vertices: [
            {
              Vertex: {
                X: 784.99713134765625,
                Y: 423.1710205078125,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 784.99627685546875,
                Y: 556.4610595703125,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 257.775726318359375,
                Y: 556.45947265625,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 258.43475341796875,
                Y: 424.489013671875,
                Z: 0.0,
              },
            },
          ],
          Indices: [
            {
              Index: 0,
            },
            {
              Index: 0,
            },
            {
              Index: 1,
            },
            {
              Index: 0,
            },
            {
              Index: 1,
            },
            {
              Index: 2,
            },
            {
              Index: 0,
            },
            {
              Index: 2,
            },
            {
              Index: 3,
            },
          ],
        },
      },
    },
    {
      Face: {
        Box: {
          Min: {
            X: 123.9368743896484375,
            Y: 424.489013671875,
          },
          Max: {
            X: 258.43475341796875,
            Y: 556.45947265625,
          },
        },
        Polygon: [
          {
            Point: {
              X: 257.775726318359375,
              Y: 556.45947265625,
            },
          },
          {
            Point: {
              X: 123.9368743896484375,
              Y: 555.1199951171875,
            },
          },
          {
            Point: {
              X: 123.9368743896484375,
              Y: 537.9840087890625,
            },
          },
          {
            Point: {
              X: 123.9378814697265625,
              Y: 442.9639892578125,
            },
          },
          {
            Point: {
              X: 123.9378814697265625,
              Y: 425.826995849609375,
            },
          },
          {
            Point: {
              X: 258.43475341796875,
              Y: 424.489013671875,
            },
          },
        ],
        Mesh: {
          Vertices: [
            {
              Vertex: {
                X: 257.775726318359375,
                Y: 556.45947265625,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 123.9368743896484375,
                Y: 555.1199951171875,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 123.9368743896484375,
                Y: 537.9840087890625,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 123.9378814697265625,
                Y: 442.9639892578125,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 123.9378814697265625,
                Y: 425.826995849609375,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 258.43475341796875,
                Y: 424.489013671875,
                Z: 0.0,
              },
            },
          ],
          Indices: [
            {
              Index: 5,
            },
            {
              Index: 0,
            },
            {
              Index: 1,
            },
            {
              Index: 5,
            },
            {
              Index: 1,
            },
            {
              Index: 2,
            },
            {
              Index: 5,
            },
            {
              Index: 2,
            },
            {
              Index: 3,
            },
            {
              Index: 5,
            },
            {
              Index: 3,
            },
            {
              Index: 4,
            },
          ],
        },
      },
    },
    {
      Face: {
        Box: {
          Min: {
            X: 187.28179931640625,
            Y: 293.8389892578125,
          },
          Max: {
            X: 258.435760498046875,
            Y: 424.489013671875,
          },
        },
        Polygon: [
          {
            Point: {
              X: 251.8377532958984375,
              Y: 417.8909912109375,
            },
          },
          {
            Point: {
              X: 187.282806396484375,
              Y: 410.938995361328125,
            },
          },
          {
            Point: {
              X: 187.28179931640625,
              Y: 410.93798828125,
            },
          },
          {
            Point: {
              X: 187.282806396484375,
              Y: 317.21697998046875,
            },
          },
          {
            Point: {
              X: 239.9607696533203125,
              Y: 299.116973876953125,
            },
          },
          {
            Point: {
              X: 245.2397613525390625,
              Y: 293.8389892578125,
            },
          },
          {
            Point: {
              X: 258.435760498046875,
              Y: 293.8389892578125,
            },
          },
          {
            Point: {
              X: 258.43475341796875,
              Y: 424.489013671875,
            },
          },
        ],
        Mesh: {
          Vertices: [
            {
              Vertex: {
                X: 251.8377532958984375,
                Y: 417.8909912109375,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 187.282806396484375,
                Y: 410.938995361328125,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 187.28179931640625,
                Y: 410.93798828125,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 187.282806396484375,
                Y: 317.21697998046875,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 239.9607696533203125,
                Y: 299.116973876953125,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 245.2397613525390625,
                Y: 293.8389892578125,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 258.435760498046875,
                Y: 293.8389892578125,
                Z: 0.0,
              },
            },
            {
              Vertex: {
                X: 258.43475341796875,
                Y: 424.489013671875,
                Z: 0.0,
              },
            },
          ],
          Indices: [
            {
              Index: 0,
            },
            {
              Index: 1,
            },
            {
              Index: 2,
            },
            {
              Index: 0,
            },
            {
              Index: 2,
            },
            {
              Index: 3,
            },
            {
              Index: 7,
            },
            {
              Index: 0,
            },
            {
              Index: 3,
            },
            {
              Index: 7,
            },
            {
              Index: 3,
            },
            {
              Index: 4,
            },
            {
              Index: 7,
            },
            {
              Index: 4,
            },
            {
              Index: 5,
            },
            {
              Index: 7,
            },
            {
              Index: 5,
            },
            {
              Index: 6,
            },
          ],
        },
      },
    },
  ],
  Folding: [
    {
      Face: {
        Face: 2,
        "Parent face": -1,
        "Matrix flat": {
          Xx: 1.0,
          Xy: 0.0,
          Xz: 0.0,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 1.0,
          Yz: 0.0,
          Yw: 0.0,
          Zx: 0.0,
          Zy: 0.0,
          Zz: 1.0,
          Zw: 0.0,
          Ox: 0.0,
          Oy: 0.0,
          Oz: 0.0,
          Ow: 1.0,
        },
        "Matrix folded": {
          Xx: 1.0,
          Xy: 0.0,
          Xz: 0.0,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 1.0,
          Yz: 0.0,
          Yw: 0.0,
          Zx: 0.0,
          Zy: 0.0,
          Zz: 1.0,
          Zw: 0.0,
          Ox: 0.0,
          Oy: 0.0,
          Oz: 0.0,
          Ow: 1.0,
        },
        "Matrix folded relative": {
          Xx: 1.0,
          Xy: 0.0,
          Xz: 0.0,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 1.0,
          Yz: 0.0,
          Yw: 0.0,
          Zx: 0.0,
          Zy: 0.0,
          Zz: 1.0,
          Zw: 0.0,
          Ox: 0.0,
          Oy: 0.0,
          Oz: 0.0,
          Ow: 1.0,
        },
      },
    },
    {
      Face: {
        Face: 4,
        "Parent face": 2,
        "Matrix flat": {
          Xx: 1.0,
          Xy: 0.0,
          Xz: 0.0,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 1.0,
          Yz: 0.0,
          Yw: 0.0,
          Zx: 0.0,
          Zy: 0.0,
          Zz: 1.0,
          Zw: 0.0,
          Ox: 0.0,
          Oy: 0.0,
          Oz: 0.0,
          Ow: 1.0,
        },
        "Matrix folded": {
          Xx: 5.9604644775390625e-8,
          Xy: 0.0,
          Xz: -0.99999994039535522461,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 1.0,
          Yz: 0.0,
          Yw: 0.0,
          Zx: 0.99999994039535522461,
          Zy: 0.0,
          Zz: 5.9604644775390625e-8,
          Zw: 0.0,
          Ox: 786.31622314453125,
          Oy: 0.0,
          Oz: 786.31622314453125,
          Ow: 1.0,
        },
        "Matrix folded relative": {
          Xx: 5.9604644775390625e-8,
          Xy: 0.0,
          Xz: -0.99999994039535522461,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 1.0,
          Yz: 0.0,
          Yw: 0.0,
          Zx: 0.99999994039535522461,
          Zy: 0.0,
          Zz: 5.9604644775390625e-8,
          Zw: 0.0,
          Ox: 786.31622314453125,
          Oy: 0.0,
          Oz: 786.31622314453125,
          Ow: 1.0,
        },
      },
    },
    {
      Face: {
        Face: 5,
        "Parent face": 2,
        "Matrix flat": {
          Xx: 1.0,
          Xy: 0.0,
          Xz: 0.0,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 1.0,
          Yz: 0.0,
          Yw: 0.0,
          Zx: 0.0,
          Zy: 0.0,
          Zz: 1.0,
          Zw: 0.0,
          Ox: 0.0,
          Oy: 0.0,
          Oz: 0.0,
          Ow: 1.0,
        },
        "Matrix folded": {
          Xx: 1.0,
          Xy: 0.0,
          Xz: 0.0,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 5.9604644775390625e-8,
          Yz: 0.99999994039535522461,
          Yw: 0.0,
          Zx: 0.0,
          Zy: -0.99999994039535522461,
          Zz: 5.9604644775390625e-8,
          Zw: 0.0,
          Ox: 0.0,
          Oy: 159.8879852294921875,
          Oz: -159.8879852294921875,
          Ow: 1.0,
        },
        "Matrix folded relative": {
          Xx: 1.0,
          Xy: 0.0,
          Xz: 0.0,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 5.9604644775390625e-8,
          Yz: 0.99999994039535522461,
          Yw: 0.0,
          Zx: 0.0,
          Zy: -0.99999994039535522461,
          Zz: 5.9604644775390625e-8,
          Zw: 0.0,
          Ox: 0.0,
          Oy: 159.8879852294921875,
          Oz: -159.8879852294921875,
          Ow: 1.0,
        },
      },
    },
    {
      Face: {
        Face: 7,
        "Parent face": 2,
        "Matrix flat": {
          Xx: 1.0,
          Xy: 0.0,
          Xz: 0.0,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 1.0,
          Yz: 0.0,
          Yw: 0.0,
          Zx: 0.0,
          Zy: 0.0,
          Zz: 1.0,
          Zw: 0.0,
          Ox: 0.0,
          Oy: 0.0,
          Oz: 0.0,
          Ow: 1.0,
        },
        "Matrix folded": {
          Xx: 1.0,
          Xy: 0.0,
          Xz: 0.0,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 5.9604644775390625e-8,
          Yz: -0.99999994039535522461,
          Yw: 0.0,
          Zx: 0.0,
          Zy: 0.99999994039535522461,
          Zz: 5.9604644775390625e-8,
          Zw: 0.0,
          Ox: 0.0,
          Oy: 292.519927978515625,
          Oz: 292.519927978515625,
          Ow: 1.0,
        },
        "Matrix folded relative": {
          Xx: 1.0,
          Xy: 0.0,
          Xz: 0.0,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 5.9604644775390625e-8,
          Yz: -0.99999994039535522461,
          Yw: 0.0,
          Zx: 0.0,
          Zy: 0.99999994039535522461,
          Zz: 5.9604644775390625e-8,
          Zw: 0.0,
          Ox: 0.0,
          Oy: 292.519927978515625,
          Oz: 292.519927978515625,
          Ow: 1.0,
        },
      },
    },
    {
      Face: {
        Face: 10,
        "Parent face": 7,
        "Matrix flat": {
          Xx: 1.0,
          Xy: 0.0,
          Xz: 0.0,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 1.0,
          Yz: 0.0,
          Yw: 0.0,
          Zx: 0.0,
          Zy: 0.0,
          Zz: 1.0,
          Zw: 0.0,
          Ox: 0.0,
          Oy: 0.0,
          Oz: 0.0,
          Ow: 1.0,
        },
        "Matrix folded": {
          Xx: 1.0,
          Xy: 0.0,
          Xz: 0.0,
          Xw: 0.0,
          Yx: 0.0,
          Yy: -0.99999988079071044922,
          Yz: -1.192092824453538924e-7,
          Yw: 0.0,
          Zx: 0.0,
          Zy: 1.192092824453538924e-7,
          Zz: -0.99999988079071044922,
          Zw: 0.0,
          Ox: 0.0,
          Oy: 715.69091796875,
          Oz: -130.6510009765625,
          Ow: 1.0,
        },
        "Matrix folded relative": {
          Xx: 1.0,
          Xy: 0.0,
          Xz: 0.0,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 5.9604644775390625e-8,
          Yz: -0.99999994039535522461,
          Yw: 0.0,
          Zx: 0.0,
          Zy: 0.99999994039535522461,
          Zz: 5.9604644775390625e-8,
          Zw: 0.0,
          Ox: 0.0,
          Oy: 423.170989990234375,
          Oz: 423.170989990234375,
          Ow: 1.0,
        },
      },
    },
    {
      Face: {
        Face: 11,
        "Parent face": 10,
        "Matrix flat": {
          Xx: 1.0,
          Xy: 0.0,
          Xz: 0.0,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 1.0,
          Yz: 0.0,
          Yw: 0.0,
          Zx: 0.0,
          Zy: 0.0,
          Zz: 1.0,
          Zw: 0.0,
          Ox: 0.0,
          Oy: 0.0,
          Oz: 0.0,
          Ow: 1.0,
        },
        "Matrix folded": {
          Xx: 5.9604644775390625e-8,
          Xy: 1.192092753399265348e-7,
          Xz: -0.99999982118606567383,
          Xw: 0.0,
          Yx: 0.0,
          Yy: -0.99999988079071044922,
          Yz: -1.192092824453538924e-7,
          Yw: 0.0,
          Zx: -0.99999994039535522461,
          Zy: 7.1054269340845282316e-15,
          Zz: -5.9604637669963267399e-8,
          Zw: 0.0,
          Ox: 257.7757568359375,
          Oy: 715.69085693359375,
          Oz: 127.124725341796875,
          Ow: 1.0,
        },
        "Matrix folded relative": {
          Xx: 5.9604644775390625e-8,
          Xy: 0.0,
          Xz: 0.99999994039535522461,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 1.0,
          Yz: 0.0,
          Yw: 0.0,
          Zx: -0.99999994039535522461,
          Zy: 0.0,
          Zz: 5.9604644775390625e-8,
          Zw: 0.0,
          Ox: 257.7757568359375,
          Oy: 0.0,
          Oz: -257.7757568359375,
          Ow: 1.0,
        },
      },
    },
    {
      Face: {
        Face: 0,
        "Parent face": 4,
        "Matrix flat": {
          Xx: 1.0,
          Xy: 0.0,
          Xz: 0.0,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 1.0,
          Yz: 0.0,
          Yw: 0.0,
          Zx: 0.0,
          Zy: 0.0,
          Zz: 1.0,
          Zw: 0.0,
          Ox: 0.0,
          Oy: 0.0,
          Oz: 0.0,
          Ow: 1.0,
        },
        "Matrix folded": {
          Xx: -0.99999988079071044922,
          Xy: 0.0,
          Xz: -1.192092824453538924e-7,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 1.0,
          Yz: 0.0,
          Yw: 0.0,
          Zx: 1.192092824453538924e-7,
          Zy: 0.0,
          Zz: -0.99999988079071044922,
          Zw: 0.0,
          Ox: 1704.602294921875,
          Oy: 0.0,
          Oz: -131.96978759765625,
          Ow: 1.0,
        },
        "Matrix folded relative": {
          Xx: 5.9604644775390625e-8,
          Xy: 0.0,
          Xz: -0.99999994039535522461,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 1.0,
          Yz: 0.0,
          Yw: 0.0,
          Zx: 0.99999994039535522461,
          Zy: 0.0,
          Zz: 5.9604644775390625e-8,
          Zw: 0.0,
          Ox: 918.2861328125,
          Oy: 0.0,
          Oz: 918.2861328125,
          Ow: 1.0,
        },
      },
    },
    {
      Face: {
        Face: 1,
        "Parent face": 11,
        "Matrix flat": {
          Xx: 1.0,
          Xy: 0.0,
          Xz: 0.0,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 1.0,
          Yz: 0.0,
          Yw: 0.0,
          Zx: 0.0,
          Zy: 0.0,
          Zz: 1.0,
          Zw: 0.0,
          Ox: 0.0,
          Oy: 0.0,
          Oz: 0.0,
          Ow: 1.0,
        },
        "Matrix folded": {
          Xx: -0.99999988079071044922,
          Xy: 1.4210853021136109209e-14,
          Xz: -1.192092682344991772e-7,
          Xw: 0.0,
          Yx: 0.0,
          Yy: -0.99999988079071044922,
          Yz: -1.192092824453538924e-7,
          Yw: 0.0,
          Zx: -1.192092824453538924e-7,
          Zy: -1.192092682344991772e-7,
          Zz: 0.99999976158142089844,
          Zw: 0.0,
          Ox: 381.71368408203125,
          Oy: 715.69085693359375,
          Oz: 3.18682098388671875,
          Ow: 1.0,
        },
        "Matrix folded relative": {
          Xx: 5.9604644775390625e-8,
          Xy: 0.0,
          Xz: 0.99999994039535522461,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 1.0,
          Yz: 0.0,
          Yw: 0.0,
          Zx: -0.99999994039535522461,
          Zy: 0.0,
          Zz: 5.9604644775390625e-8,
          Zw: 0.0,
          Ox: 123.93793487548828125,
          Oy: 0.0,
          Oz: -123.93793487548828125,
          Ow: 1.0,
        },
      },
    },
    {
      Face: {
        Face: 3,
        "Parent face": 5,
        "Matrix flat": {
          Xx: 1.0,
          Xy: 0.0,
          Xz: 0.0,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 1.0,
          Yz: 0.0,
          Yw: 0.0,
          Zx: 0.0,
          Zy: 0.0,
          Zz: 1.0,
          Zw: 0.0,
          Ox: 0.0,
          Oy: 0.0,
          Oz: 0.0,
          Ow: 1.0,
        },
        "Matrix folded": {
          Xx: 5.9604644775390625e-8,
          Xy: 0.99999988079071044922,
          Xz: -5.9604641222676946199e-8,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 5.9604644775390625e-8,
          Yz: 0.99999994039535522461,
          Yw: 0.0,
          Zx: 0.99999994039535522461,
          Zy: -5.9604641222676946199e-8,
          Zz: 3.5527136788005009294e-15,
          Zw: 0.0,
          Ox: 786.31622314453125,
          Oy: -626.42816162109375,
          Oz: -159.887939453125,
          Ow: 1.0,
        },
        "Matrix folded relative": {
          Xx: 5.9604644775390625e-8,
          Xy: 0.0,
          Xz: -0.99999994039535522461,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 1.0,
          Yz: 0.0,
          Yw: 0.0,
          Zx: 0.99999994039535522461,
          Zy: 0.0,
          Zz: 5.9604644775390625e-8,
          Zw: 0.0,
          Ox: 786.31622314453125,
          Oy: 0.0,
          Oz: 786.31622314453125,
          Ow: 1.0,
        },
      },
    },
    {
      Face: {
        Face: 6,
        "Parent face": 7,
        "Matrix flat": {
          Xx: 1.0,
          Xy: 0.0,
          Xz: 0.0,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 1.0,
          Yz: 0.0,
          Yw: 0.0,
          Zx: 0.0,
          Zy: 0.0,
          Zz: 1.0,
          Zw: 0.0,
          Ox: 0.0,
          Oy: 0.0,
          Oz: 0.0,
          Ow: 1.0,
        },
        "Matrix folded": {
          Xx: 5.9604644775390625e-8,
          Xy: -0.99999988079071044922,
          Xz: -5.9604641222676946199e-8,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 5.9604644775390625e-8,
          Yz: -0.99999994039535522461,
          Yw: 0.0,
          Zx: 0.99999994039535522461,
          Zy: 5.9604641222676946199e-8,
          Zz: 3.5527136788005009294e-15,
          Zw: 0.0,
          Ox: 784.99713134765625,
          Oy: 1077.5169677734375,
          Oz: 292.519989013671875,
          Ow: 1.0,
        },
        "Matrix folded relative": {
          Xx: 5.9604644775390625e-8,
          Xy: 0.0,
          Xz: -0.99999994039535522461,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 1.0,
          Yz: 0.0,
          Yw: 0.0,
          Zx: 0.99999994039535522461,
          Zy: 0.0,
          Zz: 5.9604644775390625e-8,
          Zw: 0.0,
          Ox: 784.99713134765625,
          Oy: 0.0,
          Oz: 784.99713134765625,
          Ow: 1.0,
        },
      },
    },
    {
      Face: {
        Face: 8,
        "Parent face": 5,
        "Matrix flat": {
          Xx: 1.0,
          Xy: 0.0,
          Xz: 0.0,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 1.0,
          Yz: 0.0,
          Yw: 0.0,
          Zx: 0.0,
          Zy: 0.0,
          Zz: 1.0,
          Zw: 0.0,
          Ox: 0.0,
          Oy: 0.0,
          Oz: 0.0,
          Ow: 1.0,
        },
        "Matrix folded": {
          Xx: 5.9604644775390625e-8,
          Xy: -0.99999988079071044922,
          Xz: 5.9604641222676946199e-8,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 5.9604644775390625e-8,
          Yz: 0.99999994039535522461,
          Yw: 0.0,
          Zx: -0.99999994039535522461,
          Zy: -5.9604641222676946199e-8,
          Zz: 3.5527136788005009294e-15,
          Zw: 0.0,
          Ox: 258.435791015625,
          Oy: 418.32373046875,
          Oz: -159.88800048828125,
          Ow: 1.0,
        },
        "Matrix folded relative": {
          Xx: 5.9604644775390625e-8,
          Xy: 0.0,
          Xz: 0.99999994039535522461,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 1.0,
          Yz: 0.0,
          Yw: 0.0,
          Zx: -0.99999994039535522461,
          Zy: 0.0,
          Zz: 5.9604644775390625e-8,
          Zw: 0.0,
          Ox: 258.435791015625,
          Oy: 0.0,
          Oz: -258.435791015625,
          Ow: 1.0,
        },
      },
    },
    {
      Face: {
        Face: 9,
        "Parent face": 10,
        "Matrix flat": {
          Xx: 1.0,
          Xy: 0.0,
          Xz: 0.0,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 1.0,
          Yz: 0.0,
          Yw: 0.0,
          Zx: 0.0,
          Zy: 0.0,
          Zz: 1.0,
          Zw: 0.0,
          Ox: 0.0,
          Oy: 0.0,
          Oz: 0.0,
          Ow: 1.0,
        },
        "Matrix folded": {
          Xx: 1.0,
          Xy: 0.0,
          Xz: 0.0,
          Xw: 0.0,
          Yx: 0.0,
          Yy: -1.788139059044624446e-7,
          Yz: 0.99999982118606567383,
          Yw: 0.0,
          Zx: 0.0,
          Zy: -0.99999982118606567383,
          Zz: -1.788139059044624446e-7,
          Zw: 0.0,
          Ox: 0.0,
          Oy: 159.23162841796875,
          Oz: -687.11041259765625,
          Ow: 1.0,
        },
        "Matrix folded relative": {
          Xx: 1.0,
          Xy: 0.0,
          Xz: 0.0,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 5.9604644775390625e-8,
          Yz: -0.99999994039535522461,
          Yw: 0.0,
          Zx: 0.0,
          Zy: 0.99999994039535522461,
          Zz: 5.9604644775390625e-8,
          Zw: 0.0,
          Ox: 0.0,
          Oy: 556.45941162109375,
          Oz: 556.45941162109375,
          Ow: 1.0,
        },
      },
    },
    {
      Face: {
        Face: 12,
        "Parent face": 7,
        "Matrix flat": {
          Xx: 1.0,
          Xy: 0.0,
          Xz: 0.0,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 1.0,
          Yz: 0.0,
          Yw: 0.0,
          Zx: 0.0,
          Zy: 0.0,
          Zz: 1.0,
          Zw: 0.0,
          Ox: 0.0,
          Oy: 0.0,
          Oz: 0.0,
          Ow: 1.0,
        },
        "Matrix folded": {
          Xx: 5.9604644775390625e-8,
          Xy: 0.99999988079071044922,
          Xz: 5.9604641222676946199e-8,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 5.9604644775390625e-8,
          Yz: -0.99999994039535522461,
          Yw: 0.0,
          Zx: -0.99999994039535522461,
          Zy: 5.9604641222676946199e-8,
          Zz: 3.5527136788005009294e-15,
          Zw: 0.0,
          Ox: 258.434783935546875,
          Oy: 34.085174560546875,
          Oz: 292.5198974609375,
          Ow: 1.0,
        },
        "Matrix folded relative": {
          Xx: 5.9604644775390625e-8,
          Xy: 0.0,
          Xz: 0.99999994039535522461,
          Xw: 0.0,
          Yx: 0.0,
          Yy: 1.0,
          Yz: 0.0,
          Yw: 0.0,
          Zx: -0.99999994039535522461,
          Zy: 0.0,
          Zz: 5.9604644775390625e-8,
          Zw: 0.0,
          Ox: 258.434783935546875,
          Oy: 0.0,
          Oz: -258.434783935546875,
          Ow: 1.0,
        },
      },
    },
  ],
};
