import React, { forwardRef, useMemo } from "react";
import { Group, Texture, TextureLoader, WebGLRenderTarget } from "three";
import {
  Coords3D,
  FoldingTree,
  isRotationWithAxis,
  SmartLabelIntersection,
} from "../Domain";
import { Annotations } from "../Domain/Annotations";
import { FoilMapping } from "../Domain/Foil";
import { Annotation3D } from "./Annotation3D";
import DesignFace from "./designFace";
import { VarnishMaterialProps } from "./Layers/Varnish";
import { LedDisplayProps } from "./Leds";
import { PackagingTextureCache } from "./PackagingTextureCache";
import { CreationState, PaperMaterial } from "./PreviewScene";
import { RotationBetweenFaces } from "./RotationBetweenFaces";
import { SmartLabelIntersection3D } from "./SmartLabelIntersection3D";
import { FoldingFace } from "../Domain/FoldingTree/auto-fold";
import { AutoFold } from "..";

type PackagingFace3dProps = {
  node: FoldingTree;
  creationState: CreationState;
  origin: Coords3D;
  enableAxesHelper: boolean;
  rotationFactor: number;
  opacity: number;
  ledProps: LedDisplayProps;
  smartLabelIntersections: SmartLabelIntersection[];
  folded: boolean;
  textureCache?: PackagingTextureCache;
  onNewAnnotation?: React.Dispatch<Annotations>;
  cancelNewAnnotation?: React.Dispatch<void>;
  envMap?: Texture;
  varnishMaterialProps: VarnishMaterialProps;
  displayedMap: string;
  activePaperName: PaperMaterial;
  annotationMode: "display" | "pendingPlacement" | "pendingValidation";
  handleDeleteEvent?: React.Dispatch<Annotations>;
  onSelect3DAnnotation?: React.Dispatch<Annotations>;
  foilMapping: FoilMapping;
  foilDiffuseColor: string;
  normalScale: number;
  parallaxScale: number;
  normalMaps?: { recto?: WebGLRenderTarget; verso?: WebGLRenderTarget };
  silhouetteMaps?: { recto?: WebGLRenderTarget; verso?: WebGLRenderTarget };
  varnishLevel: number;
  onFoldingFinished: React.Dispatch<void>;
  updateControlsTarget: () => void;
  packagingSize: number;
  silhouetteMappingMode: boolean;
  metalness: number;
  roughness: number;
  envMapIntensity: number;
  metalPaperColor: string;
  angleMode: boolean;
  anglePreview3D?: boolean;
  fold:
    | {
        Face: FoldingFace;
      }[]
    | undefined;
  selectedFold?: number[];
  newRotation?: { faceName: string; rotation: number[] }[];
  setNewRotation?: React.Dispatch<{ faceName: string; rotation: number[] }[]>;
  angle?: number;

  setAutoFold?: React.Dispatch<AutoFold | undefined>;
  hovered?: number;
};
export const PackagingFace3D = forwardRef<Group, PackagingFace3dProps>(
  (
    {
      node,
      fold,
      creationState,
      origin,
      enableAxesHelper,
      rotationFactor,
      opacity,
      ledProps,
      smartLabelIntersections,
      folded,
      textureCache,
      onNewAnnotation,
      cancelNewAnnotation,
      envMap,
      varnishMaterialProps,
      displayedMap,
      activePaperName,
      annotationMode,
      handleDeleteEvent,
      onSelect3DAnnotation,
      foilMapping,
      foilDiffuseColor,
      normalScale,
      parallaxScale,
      normalMaps,
      silhouetteMaps,
      varnishLevel,
      onFoldingFinished,
      updateControlsTarget,
      packagingSize,
      silhouetteMappingMode,
      metalness,
      roughness,
      metalPaperColor,
      envMapIntensity,
      angleMode,
      anglePreview3D,
      selectedFold,
      newRotation,
      hovered,
    }: PackagingFace3dProps,
    ref
  ) => {
    const { packaging } = creationState;
    const faceGeometry = packaging.dieline.faceGeometries[node.faceName];

    const absolutePosition: Coords3D = [faceGeometry.x, -faceGeometry.y, 0];
    const relativePosition: Coords3D = isRotationWithAxis(node.rotation)
      ? [
          absolutePosition[0] - origin[0],
          absolutePosition[1] - origin[1],
          absolutePosition[2] - origin[2],
        ]
      : [0, 0, 0];

    const loader = useMemo(() => new TextureLoader(), []);

    const close3DImg = useMemo(
      () => loader.load(`${process.env.PUBLIC_URL}/deleteannotation3D.png`),
      [loader]
    );

    // Use global layer textures
    return (
      <>
        <group position={relativePosition} ref={ref}>
          {enableAxesHelper && <axesHelper args={[faceGeometry.width / 5]} />}
          {
            <DesignFace
              node={node}
              creationState={creationState}
              opacity={opacity}
              textureCache={textureCache}
              onNewAnnotation={onNewAnnotation}
              envMap={envMap}
              varnishMaterialProps={varnishMaterialProps}
              displayedMap={displayedMap}
              activePaperName={activePaperName}
              packaging={packaging}
              faceGeometry={faceGeometry}
              faceGeometries={packaging.dieline.faceGeometries}
              verso={false}
              foilMapping={foilMapping}
              foilDiffuseColor={foilDiffuseColor}
              normalScaleV={normalScale}
              parallaxScale={parallaxScale}
              normalMap={normalMaps?.recto}
              silhouetteMap={silhouetteMaps?.recto}
              varnishLevel={varnishLevel}
              silhouetteMappingMode={silhouetteMappingMode}
              metalness={metalness}
              roughness={roughness}
              envMapIntensity={envMapIntensity}
              metalPaperColor={metalPaperColor}
              packagingSize={packagingSize}
              angleMode={angleMode}
              anglePreview3D={anglePreview3D}
              fold={fold}
              selectedFold={selectedFold}
              hovered={hovered}
            />
          }
          {creationState.packaging.design?.verso && (
            <DesignFace
              node={node}
              creationState={creationState}
              opacity={opacity}
              textureCache={textureCache}
              onNewAnnotation={onNewAnnotation}
              envMap={envMap}
              varnishMaterialProps={varnishMaterialProps}
              displayedMap={displayedMap}
              activePaperName={activePaperName}
              packaging={packaging}
              faceGeometry={faceGeometry}
              faceGeometries={packaging.dieline.faceGeometries}
              verso
              foilMapping={foilMapping}
              foilDiffuseColor={foilDiffuseColor}
              normalScaleV={normalScale}
              parallaxScale={parallaxScale}
              normalMap={normalMaps?.verso}
              silhouetteMap={silhouetteMaps?.verso}
              varnishLevel={varnishLevel}
              silhouetteMappingMode={silhouetteMappingMode}
              metalness={metalness}
              roughness={roughness}
              envMapIntensity={envMapIntensity}
              metalPaperColor={metalPaperColor}
              packagingSize={packagingSize}
              angleMode={angleMode}
              anglePreview3D={anglePreview3D}
              fold={fold}
              selectedFold={selectedFold}
              hovered={hovered}
            />
          )}
          {smartLabelIntersections
            .filter((intersection) => intersection.faceName === node.faceName)
            .map((intersection) => (
              <SmartLabelIntersection3D
                key={intersection.smartLabel.key}
                intersection={intersection}
                enableAxesHelper={enableAxesHelper}
                absolutePosition={absolutePosition}
                ledProps={ledProps}
              />
            ))}
          {node.children
            ?.filter((node) => !folded || !node.isPackagingStrip)
            .map((node) => {
              return (
                <>
                  <RotationBetweenFaces
                    key={node.faceName}
                    folded={folded}
                    node={node}
                    absolutePosition={absolutePosition}
                    enableAxesHelper={enableAxesHelper}
                    rotationFactor={rotationFactor}
                    onFoldingFinished={onFoldingFinished}
                    updateControlsTarget={updateControlsTarget}
                    newRotation={newRotation}
                  >
                    <PackagingFace3D
                      node={node}
                      fold={fold}
                      creationState={creationState}
                      origin={
                        isRotationWithAxis(node.rotation)
                          ? node.rotation.origin
                          : [0, 0, 0]
                      }
                      enableAxesHelper={enableAxesHelper}
                      rotationFactor={rotationFactor}
                      opacity={opacity}
                      ledProps={ledProps}
                      smartLabelIntersections={smartLabelIntersections}
                      folded={folded}
                      textureCache={textureCache}
                      onNewAnnotation={onNewAnnotation}
                      cancelNewAnnotation={cancelNewAnnotation}
                      envMap={envMap}
                      varnishMaterialProps={varnishMaterialProps}
                      displayedMap={displayedMap}
                      activePaperName={activePaperName}
                      annotationMode={annotationMode}
                      handleDeleteEvent={handleDeleteEvent}
                      onSelect3DAnnotation={onSelect3DAnnotation}
                      foilMapping={foilMapping}
                      foilDiffuseColor={foilDiffuseColor}
                      normalScale={normalScale}
                      parallaxScale={parallaxScale}
                      varnishLevel={varnishLevel}
                      normalMaps={normalMaps}
                      onFoldingFinished={onFoldingFinished}
                      updateControlsTarget={updateControlsTarget}
                      packagingSize={packagingSize}
                      silhouetteMappingMode={silhouetteMappingMode}
                      silhouetteMaps={silhouetteMaps}
                      metalness={metalness}
                      roughness={roughness}
                      envMapIntensity={envMapIntensity}
                      metalPaperColor={metalPaperColor}
                      angleMode={angleMode}
                      anglePreview3D={anglePreview3D}
                      selectedFold={selectedFold}
                      newRotation={newRotation}
                      hovered={hovered}
                    />
                  </RotationBetweenFaces>
                </>
              );
            })}

          {creationState.annotations.map((annotation: Annotations) => (
            <Annotation3D
              selectedAnnotation={creationState.selectedAnnotation}
              annotation={annotation}
              faceName={node.faceName}
              cancelNewAnnotation={cancelNewAnnotation}
              annotationMode={annotationMode}
              handleDeleteEvent={handleDeleteEvent}
              onSelect3DAnnotation={onSelect3DAnnotation}
              packagingSize={packagingSize}
              close3DImg={close3DImg}
            />
          ))}
        </group>
      </>
    );
  }
);
