import React, { useMemo } from "react";
import { BufferGeometry, Mesh, ShaderMaterial, Texture } from "three";
import { layerIsEnabled, LayerView } from "../Domain";
import { FoilMaterial } from "../Domain/Foil";
import { BOTTLE_RENDER_ORDER, mergeVertices } from "./BottleLayer";
import { bottleVertexShader } from "./Shaders/bottleVertexShader";
import { foilFragmentShader } from "./Shaders/foilFragmentShader";

export function BottleFoilLayer({
  layerName,
  objectRef,
  bottleOuter,
  layersView,
  foilMaterial,
  basicfoilUniform,
  map,
}: {
  layerName: string;
  objectRef?: React.MutableRefObject<Mesh | undefined>;
  bottleOuter: Mesh;
  layersView: LayerView[];
  foilMaterial: FoilMaterial | undefined;
  basicfoilUniform: any;
  map: Texture | undefined;
}): JSX.Element {
  const newGeometry = useMemo(() => {
    const geometry = bottleOuter.geometry as BufferGeometry;

    const newGeometry = mergeVertices(geometry);

    newGeometry.computeTangents();
    return newGeometry;
  }, [bottleOuter]);

  const foilUniform = useMemo(
    () => ({
      ...basicfoilUniform,
      diffuse: { value: foilMaterial?.diffuse },
      map: { value: map },
      reflectivity: { value: foilMaterial?.reflectivity },
      emissive: {
        value: foilMaterial?.emissive,
      },
      roughness: { value: foilMaterial?.roughness },
      metalness: { value: foilMaterial?.metalness },
      envMapIntensity: { value: foilMaterial?.envMapIntensity },
      normalScaleFactor: { value: foilMaterial?.normalScaleFactor },
      vecXNormalScale: { value: foilMaterial?.vecXNormalScale },
      vecYNormalScale: { value: foilMaterial?.vecYNormalScale },
    }),
    [foilMaterial, basicfoilUniform, map]
  );

  const shaderMaterial = useMemo(
    () =>
      new ShaderMaterial({
        uniforms: foilUniform,
        vertexShader: bottleVertexShader,
        fragmentShader: "#define BOTTLE_SHADER \n" + foilFragmentShader,
        transparent: true,
      }),
    [foilUniform]
  );

  return (
    <>
      <mesh
        ref={objectRef as React.Ref<Mesh> | undefined}
        geometry={newGeometry}
        position={[0, 0, 0]}
        scale={[250, 250, 250]}
        material={shaderMaterial}
        rotation={[-Math.PI / 2, 0, 0]}
        visible={layerIsEnabled(layerName, layersView)}
        renderOrder={BOTTLE_RENDER_ORDER.indexOf("foil")}
      />
    </>
  );
}
