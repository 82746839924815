import React, { useMemo } from "react";
import {
  BufferAttribute,
  BufferGeometry,
  Mesh,
  MeshPhongMaterial,
  ShaderMaterial,
  Vector3,
} from "three";
//import { BufferGeometryUtils } from "three/examples/jsm/utils/BufferGeometryUtils";
import { layerIsEnabled, LayerView } from "../Domain";

export const BOTTLE_RENDER_ORDER = [
  "bottle",
  "paper",
  "color",
  "foil",
  "varnish",
] as const;

type LayerName = typeof BOTTLE_RENDER_ORDER[number];

export function BottleLayer({
  material,
  layerName,
  objectRef,
  bottleOuter,
  layersView,
}: {
  material: MeshPhongMaterial | ShaderMaterial;
  layerName: LayerName;
  objectRef?: React.MutableRefObject<Mesh | undefined>;
  bottleOuter: Mesh;
  layersView: LayerView[];
}): JSX.Element {
  const newGeometry = useMemo(() => {
    const geometry = bottleOuter.geometry as BufferGeometry;
    const newGeometry = mergeVertices(geometry);

    newGeometry.computeTangents();
    return newGeometry;
  }, [bottleOuter]);

  return (
    <>
      <mesh
        ref={objectRef as React.Ref<Mesh> | undefined}
        geometry={newGeometry}
        position={new Vector3(0, 0, 0)}
        scale={[250, 250, 250]}
        material={material}
        rotation={[-Math.PI / 2, 0, 0]}
        visible={
          layerName === "bottle" || layerIsEnabled(layerName, layersView)
        }
        renderOrder={BOTTLE_RENDER_ORDER.indexOf(layerName)}
      />
    </>
  );
}

export function mergeVertices(
  geometry: BufferGeometry,
  tolerance = 1e-4
): BufferGeometry {
  tolerance = Math.max(tolerance, Number.EPSILON);

  // Generate an index buffer if the geometry doesn't have one, or optimize it
  // if it's already available.
  const hashToIndex = {};
  const indices = geometry.getIndex();
  const positions = geometry.getAttribute("position");
  const vertexCount = indices ? indices.count : positions.count;

  // next value for triangle indices
  let nextIndex = 0;

  // attributes and new attribute arrays
  const attributeNames = Object.keys(geometry.attributes);
  const attrArrays = {};
  const morphAttrsArrays = {};
  const newIndices = [];
  const getters = ["getX", "getY", "getZ", "getW"];

  // initialize the arrays
  for (let i = 0, l = attributeNames.length; i < l; i++) {
    const name = attributeNames[i];

    attrArrays[name] = [];

    const morphAttr = geometry.morphAttributes[name];
    if (morphAttr) {
      morphAttrsArrays[name] = new Array(morphAttr.length)
        .fill([])
        .map(() => []);
    }
  }

  // convert the error tolerance to an amount of decimal places to truncate to
  const decimalShift = Math.log10(1 / tolerance);
  const shiftMultiplier = Math.pow(10, decimalShift);
  for (let i = 0; i < vertexCount; i++) {
    const index = indices ? indices.getX(i) : i;

    // Generate a hash for the vertex attributes at the current index 'i'
    let hash = "";
    for (let j = 0, l = attributeNames.length; j < l; j++) {
      const name = attributeNames[j];
      const attribute = geometry.getAttribute(name) as BufferAttribute;
      const itemSize = attribute.itemSize;

      for (let k = 0; k < itemSize; k++) {
        // double tilde truncates the decimal value
        hash += `${~~(attribute[getters[k]](index) * shiftMultiplier)},`;
      }
    }

    // Add another reference to the vertex if it's already
    // used by another index
    if (hash in hashToIndex) {
      newIndices.push(hashToIndex[hash]);
    } else {
      // copy data to the new index in the attribute arrays
      for (let j = 0, l = attributeNames.length; j < l; j++) {
        const name = attributeNames[j];
        const attribute = geometry.getAttribute(name) as BufferAttribute;
        const morphAttr = geometry.morphAttributes[name];
        const itemSize = attribute.itemSize;
        const newarray = attrArrays[name];
        const newMorphArrays = morphAttrsArrays[name];

        for (let k = 0; k < itemSize; k++) {
          const getterFunc = getters[k];
          newarray.push(attribute[getterFunc](index));

          if (morphAttr) {
            for (let m = 0, ml = morphAttr.length; m < ml; m++) {
              newMorphArrays[m].push(morphAttr[m][getterFunc](index));
            }
          }
        }
      }

      hashToIndex[hash] = nextIndex;
      newIndices.push(nextIndex);
      nextIndex++;
    }
  }

  // Generate typed arrays from new attribute arrays and update
  // the attributeBuffers
  const result = geometry.clone();
  for (let i = 0, l = attributeNames.length; i < l; i++) {
    const name = attributeNames[i];
    const oldAttribute = geometry.getAttribute(name);

    const buffer = new Float32Array(attrArrays[name]);
    const attribute: BufferAttribute = new BufferAttribute(
      buffer,
      oldAttribute.itemSize,
      oldAttribute.normalized
    );

    result.setAttribute(name, attribute);

    // Update the attribute arrays
    if (name in morphAttrsArrays) {
      for (let j = 0; j < morphAttrsArrays[name].length; j++) {
        const oldMorphAttribute = geometry.morphAttributes[name][j];

        const buffer = new Float32Array(morphAttrsArrays[name][j]);
        const morphAttribute = new BufferAttribute(
          buffer,
          oldMorphAttribute.itemSize,
          oldMorphAttribute.normalized
        );
        result.morphAttributes[name][j] = morphAttribute;
      }
    }
  }

  // indices

  result.setIndex(newIndices);

  return result;
}
