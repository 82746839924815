import React, { ReactNode, useRef } from "react";
import { animated, useSpring } from "@react-spring/three";
import { Euler, Group, Matrix4 } from "three";
import {
  Coords3D,
  FoldingTree,
  isRotationWithAxis,
  RotationMatrix,
} from "../Domain";

export function RotationBetweenFaces({
  children,
  folded,
  node,
  absolutePosition,
  enableAxesHelper,
  rotationFactor,
  onFoldingFinished,
  updateControlsTarget,
  newRotation,
}: {
  children?: ReactNode;
  folded: boolean;
  node: FoldingTree;
  absolutePosition: Coords3D;
  enableAxesHelper: boolean;
  rotationFactor: number;
  onFoldingFinished: React.Dispatch<void>;
  updateControlsTarget: () => void;
  newRotation?: { faceName: string; rotation: number[] }[];
}): JSX.Element {
  const absoluteRotationPosition: Coords3D = (isRotationWithAxis(node.rotation)
    ? node.rotation.origin
    : undefined) ?? [0, 0, 0];
  const rotationAngle = isRotationWithAxis(node.rotation)
    ? node.rotation.prop.map((x) => x * rotationFactor)
    : [0, 0, 0];

  const relativeRotationPosition: Coords3D = [
    absoluteRotationPosition[0] - absolutePosition[0],
    absoluteRotationPosition[1] - absolutePosition[1],
    absoluteRotationPosition[2] - absolutePosition[2],
  ];
  const { rotation } = useSpring<{ rotation: [number, number, number] }>({
    rotation: folded ? rotationAngle : [0, 0, 0],
    config: { precision: 0.0001, mass: 1, tension: 100 },
  });
  const groupRef = useRef<Group>(null!);

  const faceRotation = newRotation?.find((r) => r.faceName === node.faceName);

  const { rotationMatrix } = useSpring<{ rotationMatrix: RotationMatrix }>({
    rotationMatrix: folded
      ? faceRotation
        ? faceRotation?.rotation
        : node.rotation
      : // Identity matrix
        [
          1.0,
          0.0,
          0.0,
          0.0,
          0.0,
          1.0,
          0.0,
          0.0,
          0.0,
          0.0,
          1.0,
          0.0,
          0.0,
          0.0,
          0.0,
          1.0,
        ],
    onRest: () => onFoldingFinished(),
    onChange: () => updateControlsTarget(),
    config: { precision: 0.0001, mass: 1, tension: 100 },
  });

  return isRotationWithAxis(node.rotation) ? (
    <animated.group
      ref={groupRef}
      key={node.faceName}
      name="rotationPoint"
      position={relativeRotationPosition}
      rotation={(rotation as unknown) as Euler}
    >
      {enableAxesHelper && <axesHelper args={[1000]} />}
      {children}
    </animated.group>
  ) : (
    <animated.group
      ref={groupRef}
      key={node.faceName}
      matrix={
        (rotationMatrix.to((...elements) => {
          const matrix = new Matrix4();
          matrix.set(...elements);
          matrix.transpose();
          return matrix;
        }) as unknown) as Matrix4
      }
      matrixAutoUpdate={false}
    >
      {enableAxesHelper && <axesHelper args={[1000]} />}
      {children}
    </animated.group>
  );
}
