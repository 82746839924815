import { deriveRotationAxes, Dieline } from "../Dieline";
import { LayerEntity } from "../LayerEntity";
import { Product } from "../Product";
import { ProductDesign } from "./ProductDesign";

export class Flat implements Product {
  public readonly rotation = [0, 0, 0] as [number, number, number];
  useMask = true;

  name?: string;
  dieline: Dieline;
  paper?: { layer: LayerEntity };
  design?: ProductDesign;
  width?: number;
  height?: number;

  constructor(props: {
    name?: string;
    paper?: { layer: LayerEntity };

    sceneBackgroundColor?: string;
    design?: ProductDesign;
    width?: number;
    height?: number;
  }) {
    this.name = props.name;
    this.paper = props.paper;
    this.design = props.design;
    this.width = props.width;
    this.height = props.height;

    if (this.design) {
      // Pluck project dimensions from color layer
      const width = this.design.recto.color.layer.width;
      const height = this.design.recto.color.layer.height;
      if (!width || !height) {
        throw new Error(
          "Missing width or height in packaging design, cannot generate flat dieline"
        );
      }
      this.dieline = generateFlatDieline(width, height);
    } else {
      this.dieline = generateFlatDieline(472, 596);
    }
  }
}

function generateFlatDieline(width: number, height: number): Dieline {
  return deriveRotationAxes({
    name: "flat",
    dimensions: {
      width,
      height,
    },
    faceGeometries: {
      front: { x: 0, y: 0, height, width },
    },
    foldingTree: { faceName: "front", children: [] },
  });
}
