import {
  Mesh,
  NoBlending,
  OrthographicCamera,
  PlaneBufferGeometry,
  RGBAFormat,
  Scene,
  ShaderMaterial,
  Texture,
  WebGLRenderer,
  WebGLRenderTarget,
} from "three";

export function initRenderTargetTextureSilhouette(
  gl: WebGLRenderer,
  texture: Texture | undefined,
  fragmentShader: string,
  plane?: PlaneBufferGeometry,
  mask?: Texture
): WebGLRenderTarget | undefined {
  const [rtTexture, quadScene] = texture
    ? initRenderTarget(texture, fragmentShader, plane, mask)
    : [undefined, undefined];

  if (rtTexture && texture && quadScene) {
    const image = texture.image as HTMLImageElement;

    rtTexture.texture.offset = texture.offset;
    rtTexture.texture.repeat = texture.repeat;

    const cameraRTT = new OrthographicCamera(
      -image.width / 2,
      image.width / 2,
      image.height / 2,
      -image.height / 2,
      -10000,
      10000
    );
    gl.setRenderTarget(rtTexture);
    gl.clear();
    gl.render(quadScene, cameraRTT);

    gl.setRenderTarget(null);
  }

  return rtTexture;
}

const rtVertexShader = `
      varying vec2 vUv;

			void main() {

				vUv = uv;
				gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

      }`;

function initRenderTarget(
  textures: Texture,
  fragmentShader: string,
  plane?: PlaneBufferGeometry,
  mask?: Texture
): [WebGLRenderTarget | undefined, Scene | undefined] {
  const image = textures.image as HTMLImageElement | undefined;
  if (!image) {
    return [undefined, undefined];
  }

  const rtTexture = new WebGLRenderTarget(image.width, image.height, {
    format: RGBAFormat,
  });

  const uniforms = {
    vSizeTexture: {
      value: [image.width, image.height, 1 / image.width, 1 / image.height],
    },
    samplerColor: {
      value: textures,
    },
    mask: {
      value: mask,
    },
    fHeight: {
      value: 10.0,
    },
  };

  const material = new ShaderMaterial({
    vertexShader: rtVertexShader,
    fragmentShader,
    uniforms,
    blending: NoBlending,
  });
  const quad = new Mesh(plane, material);
  const scene = new Scene();
  scene.add(quad);

  return [rtTexture, scene];
}
