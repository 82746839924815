import { FaceGeometry } from "../Face";
import { Coords3D } from "../index";

// Data used to fold a packaging in 3D
export default FoldingTree;
export type FoldingTree = {
  faceName: string;
  rotation: Rotation;
  children: FoldingTree[];
  isPackagingStrip?: boolean;
};
// In a minimal folding tree:
// - rotation data is an optional angle in radians
// - the children array may be omitted
export type MinimalFoldingTree = {
  faceName: string;
  rotation?: number;
  children?: MinimalFoldingTree[];
};

export type Rotation = RotationAxis | RotationMatrix;

export type RotationAxis = {
  // Arbitrary rotation point on the axis
  origin: Coords3D;
  // Euler angle representing a rotation transformation (see three.js documentation)
  prop: Coords3D;
};

export type RotationMatrix = [
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number
];

// The zero rotation has no impact on any 3D object
const ZERO_ROTATION: RotationAxis = {
  origin: [0, 0, 0],
  prop: [0, 0, 0],
};

const ADJACENCY_GAP_TOLERANCE = 8;

export function deriveRotationAxisFromFaceGeometries(
  parentFace: FaceGeometry,
  childFace: FaceGeometry,
  angle?: number
): RotationAxis {
  const dx = childFace.x - parentFace.x;
  const dy = childFace.y - parentFace.y;

  // Child face is to the right
  if (dx >= 0 && Math.abs(dy) <= ADJACENCY_GAP_TOLERANCE) {
    return {
      origin: [parentFace.x + parentFace.width, -parentFace.y, 0],
      prop: [0, angle ?? Math.PI / 2, 0],
    };
  }

  // Child face is below
  if (dy >= 0 && Math.abs(dx) <= ADJACENCY_GAP_TOLERANCE) {
    return {
      origin: [childFace.x, -childFace.y, 0],
      prop: [angle ?? Math.PI / 2, 0, 0],
    };
  }

  // Child face is above
  if (dy <= 0 && Math.abs(dx) <= ADJACENCY_GAP_TOLERANCE) {
    return {
      origin: [parentFace.x, -(childFace.y + childFace.height), 0],
      prop: [angle ?? -Math.PI / 2, 0, 0],
    };
  }

  // Child face is to the left
  if (dx <= 0 && Math.abs(dy) <= ADJACENCY_GAP_TOLERANCE) {
    return {
      origin: [parentFace.x, -childFace.y, 0],
      prop: [0, angle ?? -Math.PI / 2, 0],
    };
  }

  // Should not happen
  throw new Error("faces are not adjacent");
}

// Iterates on a minimal folding tree, applies a function to each rotation angle
// and returns a full folding tree
export function applyRotationFunction(
  f: (parentName: string, childName: string, rotation?: number) => RotationAxis,
  foldingTree: MinimalFoldingTree
): FoldingTree {
  const recursiveMap = (
    parentName: string,
    node: MinimalFoldingTree
  ): FoldingTree => {
    const { faceName, rotation, children } = node;
    const newRotation = f(parentName, faceName, rotation);
    return {
      faceName,
      rotation: newRotation,
      children:
        children?.map((childNode) => recursiveMap(faceName, childNode)) ?? [],
    };
  };
  const { faceName: rootFaceName, children: nodes } = foldingTree;
  return {
    faceName: rootFaceName,
    children: nodes?.map((node) => recursiveMap(rootFaceName, node)) ?? [],
    rotation: ZERO_ROTATION,
  };
}

export function isRotationWithAxis(
  rotation: Rotation
): rotation is RotationAxis {
  return "origin" in rotation && "prop" in rotation;
}
